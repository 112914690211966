/*
 *
 * SideBar constants
 *
 */
const dir = 'sidebar';
const constant = type => `prodigalVoice/${dir}/${type}`;
export default constant;

const TWO_DIGIT_COUNT_OFFSET = [-8, 8];
const SINGLE_DIGIT_COUNT_OFFSET = [0, 8];

export { dir, TWO_DIGIT_COUNT_OFFSET, SINGLE_DIGIT_COUNT_OFFSET };
