/* eslint-disable no-underscore-dangle */
/**
 *
 * Component: ScorecardListTable
 * Date: 8/6/2020
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Table } from 'components/common';
import CustomCell from 'components/CustomCell';
import { removeAllDOMNodes } from 'utils/commonFunctions';
import { REMOVE_NODES_ON_SCROLL_DELAY } from 'utils/commonConstants';

import style from './style.css';

function ScorecardListTable({
  dataSource,
  columns,
  setSelectAll,
  setSelectedRows,
  onClick,
  pagination = false,
  loadingList,
  keyIndex,
  selectedColumnTree,
  searchId,
  nameOnRowTooltip,
}) {
  /**
   * rowSelection object for  Table.
   * */
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectAll(false);
      // const selectAllBool = dataSource.every(dataArg => {
      //   if (selectedRows.find(selectedRow => dataArg._id === selectedRow._id)) {
      //     return true;
      //   }
      //   return false;
      // });
      // setSelectAll(selectAllBool);
    },
    onSelectAll: e => {
      setSelectAll(e);
    },
  };

  const customCellScorecard = ({ ...rest }) => (
    <CustomCell
      toolTipTitle={`Click to go to the ${nameOnRowTooltip}`}
      {...rest}
    />
  );
  /**
   * Removes all the DOM nodes by selecting the overlay class of tooltips
   * Debounce reducing the number of Removals we do on DOM
   */
  const debouncedRemoveDOMNodes = _.debounce(
    () => removeAllDOMNodes('customCellTooltip'),
    REMOVE_NODES_ON_SCROLL_DELAY,
  );
  useEffect(() => {
    const removeTooltips = () => {
      debouncedRemoveDOMNodes();
    };
    const tableContent = document.getElementsByClassName('ant-table-content');

    if (tableContent?.length > 0) {
      [...(tableContent || [])].forEach(eachContent => {
        eachContent.addEventListener('scroll', removeTooltips);
      });
    }
    return () =>
      [...(tableContent || [])].forEach(eachContent => {
        eachContent.removeEventListener('scroll', removeTooltips);
      });
  }, []);

  return (
    <div
      className={style.container}
      key={searchId || JSON.stringify(selectedColumnTree || [])}
    >
      <Table
        size="small"
        key={searchId || JSON.stringify(selectedColumnTree || [])}
        dataSource={columns?.length ? dataSource : []}
        columns={columns}
        rowKey={keyIndex}
        scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
        pagination={columns?.length ? pagination : {}}
        rowSelection={rowSelection}
        onRow={record => ({
          onClick: () => {
            onClick(record);
          }, // double click row
        })}
        loading={loadingList}
        components={{
          body: {
            cell: customCellScorecard,
          },
        }}
      />
    </div>
  );
}

ScorecardListTable.propTypes = {
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  setSelectAll: PropTypes.func,
  setSelectedRows: PropTypes.func,
  onClick: PropTypes.func,
  pagination: PropTypes.oneOfType(PropTypes.bool, PropTypes.object),
  loadingList: PropTypes.bool,
  keyIndex: PropTypes.string,
  selectedColumnTree: PropTypes.array,
  searchId: PropTypes.string,
  nameOnRowTooltip: PropTypes.string,
};

export default ScorecardListTable;
