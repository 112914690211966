/* cspell: ignore sider, managetags, scorecardbuilder, userlist, smartreports, userpermission */
/*
 *
 * Sider reducer
 *
 */
import produce from 'immer';

import Auth from 'auth0-react';

import constant from './constants';

const { isScopePresent } = new Auth();
const hasViewGlobalConfig = isScopePresent('globalconfig_admin');
const hasViewManageTags = isScopePresent('managetags_admin');
const hasViewScorecardBuilder = isScopePresent('scorecardbuilder_admin');
const hasViewUserList = isScopePresent('userlist_admin');
const hasViewVisuals = isScopePresent('view_visuals');
const hasViewSmartReports = isScopePresent('view_smartreports');
const hasViewUserPermissionAdmin = isScopePresent('userpermission_admin');
const hasUserRolesAdmin = isScopePresent('view_user_roles_admin');

const initialCollapsed = !!(
  localStorage.getItem('collapsed') &&
  localStorage.getItem('collapsed') === 'true'
);

export const initialState = {
  defaultCollapsed: initialCollapsed,
  collapsed: initialCollapsed,
  siderMenu: [
    {
      key: 'search',
      path: '/search-results',
      icon: 'calls',
      title: 'Calls',
      toolTip: 'Past call records',
    },
  ],
  visuals: null,
};

/* eslint-disable default-case, no-param-reassign */
const siderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constant('COLLAPSE'): {
        const { defaultCollapsed, collapsed } = action.payload;
        draft.defaultCollapsed = defaultCollapsed;
        draft.collapsed = collapsed;
        break;
      }
      case constant('RENDER_SIDEBAR'): {
        let { siderMenu } = state;
        if (hasViewSmartReports)
          siderMenu = [
            ...siderMenu.filter(m => m.key !== 'smart-reports'),
            {
              title: 'Smart Reports',
              icon: 'smartReports',
              key: 'smart-reports',
              path: '/smart-reports',
              toolTip: 'Your saved searches',
            },
          ];
        const scorecard = {
          subMenu: [
            {
              key: 'reviewScorecard',
              path: '/review-scorecard',
              icon: 'table',
              title: 'Review Scorecard',
            },
            {
              key: 'aggregatedScorecard',
              path: '/aggregated-scorecards',
              icon: 'aggregate',
              title: 'Aggregate Scorecard',
            },
          ],
          title: 'Scorecard',
          icon: 'table',
          key: 'scorecard',
          hidden: false,
          toolTip: 'Deep dive into scorecards',
        };
        const accounts = {
          key: 'accounts',
          path: '/accounts/search',
          icon: 'idCard',
          title: 'Accounts',
        };
        const visuals = {
          title: 'Visuals',
          icon: 'visuals',
          key: 'visuals',
          path: '/visuals',
          toolTip: 'Visualize Data',
        };

        const agents = {
          title: 'Agents',
          key: 'agents',
          icon: 'agent',
          path: '/agent-visualizations',
          toolTip: 'Visualize Data',
        };
        let variableMenu = {
          accounts,
          scorecard,
        };
        if (hasViewVisuals) {
          variableMenu = { ...variableMenu, visuals, agents };
        }

        if (action.payload) {
          action.payload.forEach(navItem => {
            if (variableMenu[navItem])
              siderMenu = [
                ...siderMenu.filter(m => m.key !== navItem),
                variableMenu[navItem],
              ];
          });
        }
        siderMenu = [
          ...siderMenu.filter(m => m.key !== 'inbox'),
          {
            title: 'Inbox',
            icon: 'mail',
            key: 'inbox',
            path: '/inbox',
            counter: true,
            toolTip: 'Messages left for you',
          },
        ];

        if (hasUserRolesAdmin || hasViewUserList) {
          const RBACSubMenu = [];
          if (hasUserRolesAdmin) {
            RBACSubMenu.push({
              key: 'user-roles',
              path: '/user-roles',
              icon: 'user',
              title: 'User Roles',
            });
          }

          if (hasViewUserList || hasUserRolesAdmin) {
            RBACSubMenu.push({
              key: 'user-management',
              path: '/user-management',
              icon: 'user',
              title: 'User Management',
            });
          }
          const RBACMenu = {
            subMenu: RBACSubMenu,
            title: 'Admin',
            icon: 'admin',
            key: 'rbac',
            new: true,
            toolTip: 'Administrator configuration',
          };

          siderMenu = [...siderMenu.filter(m => m.key !== 'rbac'), RBACMenu];
        }

        if (
          hasViewGlobalConfig ||
          hasViewManageTags ||
          hasViewScorecardBuilder ||
          hasViewUserPermissionAdmin
        ) {
          siderMenu = siderMenu.filter(m => !m.admin);
          const analyticsSubMenu = [];
          if (hasViewGlobalConfig) {
            analyticsSubMenu.push({
              key: 'configs',
              path: '/configs',
              icon: 'form',
              title: 'Configs',
            });
          }
          if (hasViewManageTags) {
            analyticsSubMenu.push({
              key: 'utils/tags',
              path: '/manage-tags',
              icon: 'utils/tags',
              title: 'Manage Tags',
            });
          }
          if (hasViewScorecardBuilder) {
            analyticsSubMenu.push({
              key: 'scorecard-template',
              path: '/scorecard-templates',
              title: 'Scorecard Templates',
            });
          }
          if (hasViewUserPermissionAdmin) {
            analyticsSubMenu.push({
              key: 'user-permission',
              path: '/user-permission',
              icon: 'user',
              title: 'User Permissions',
            });
          }

          const analytics = {
            subMenu: analyticsSubMenu,
            title: 'Prodigal Admin',
            icon: 'admin',
            key: 'analytics',
            admin: true,
            toolTip: 'Prodigal administrator configuration',
          };
          siderMenu = [
            ...siderMenu.filter(m => m.key !== 'analytics'),
            analytics,
          ];
        }

        // Note: Slight hack to fix ordering issues. Scorecard menu item depends on filters api which causes a weird race condition.
        // This checks if scorecard menu item already exists
        const scorecardMenu = siderMenu.find(m => m.key === 'scorecard');
        if (scorecardMenu) {
          draft.siderMenu = [
            ...siderMenu.filter(m => m.key !== 'scorecard'),
            scorecardMenu,
          ];
        } else {
          draft.siderMenu = siderMenu;
        }
        break;
      }
    }
  });

export default siderReducer;
