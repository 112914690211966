// cspell: ignore stime
/**
 *
 * Component: Transcript
 * Date: 24/11/2022
 *
 */

import React, { useMemo } from 'react';
import _ from 'lodash';
import { useQueryClient } from 'react-query';

import Auth from 'auth0-react';
import NextBestUtterance from 'containers/NextBestUtterance';
import { formatTimeAddPadding } from 'utils/commonFunctions';
import { Text } from 'components/common';

import styles from './style.css';

const Transcript = ({
  call,
  eachTranscript,
  audioCurrentTime,
  handlePerWordClick,
  handleAgentAndTimeClick,
  memoizeFlag,
  searchInput,
  currentSearchIndex,
  index,
}) => {
  const isAgentSpeaker = call?.speakers?.agent === eachTranscript?.speaker;

  const { isScopePresent } = new Auth();
  const queryClient = useQueryClient();
  const hasViewBestUtterance = isScopePresent('view_best_utterance');

  /**
   * Provides the css styles to the div element of the word.
   * @param {Number} currentPlayingTime : Current Playing time of the audio passed down as prop.
   * @param {Number} spanStartTime Start time of the word spoken in the audio timeline
   * @param {Number} spanEndTime : End time at which the word spoken end in the audio timeline
   * @returns String, with style sting.
   */
  const getTranscriptWordStyles = (
    currentPlayingTime,
    spanStartTime,
    spanEndTime,
  ) => {
    if (currentPlayingTime >= spanStartTime && currentPlayingTime < spanEndTime)
      return styles.playing;

    return '';
  };

  const buildTranscript = (transcript = '', lastTranscriptIndex) => {
    const eachWordInTranscript = transcript.split(' ');
    return eachWordInTranscript.map((eachSpan, currIndex) => (
      <>
        <span
          role="button"
          tabIndex="0"
          className={`${getTranscriptWordStyles(
            audioCurrentTime,
            eachTranscript?.spanTimingDetails?.[lastTranscriptIndex + currIndex]
              ?.start,
            eachTranscript?.spanTimingDetails?.[lastTranscriptIndex + currIndex]
              ?.end,
          )} ${styles.utteranceText}`}
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            handlePerWordClick(
              Number(
                eachTranscript?.spanTimingDetails[
                  lastTranscriptIndex + currIndex
                ]?.start,
              ),
              eachSpan,
            );
          }}
        >
          {eachSpan}
          {currIndex !== eachWordInTranscript.length - 1 ? ' ' : ''}
        </span>
      </>
    ));
  };

  /**
   * checks whether to highlight current word, is the particular index focused
   * @param  partIndex current index of part
   * @returns Boolean value to decide whether to make text orange or yellow, orange being focused search
   */
  const isWordCurrentIndex = partIndex => {
    if (currentSearchIndex && partIndex && index) {
      return (
        currentSearchIndex?.transcriptIndex === index &&
        currentSearchIndex?.spanIndex === partIndex
      );
    }
    return false;
  };

  const getHighlightedTranscript = useMemo(() => {
    const { text = '' } = eachTranscript;
    const searchStrings = searchInput || [];
    const regex = searchStrings.length
      ? new RegExp(
          `(${searchStrings
            .map(regexString => _.escapeRegExp(regexString))
            .join('|')})`,
          'gi',
        )
      : null;
    const parts = regex ? text.split(regex) || [] : [text];
    let lastSpanIndex = 0;

    /**
     * Regex Test decides whether text is to highlighted, also isWordCurrentIndex decides
     * if the occurrence is on focus in search
     */

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={styles.utterance}
        onClick={() => {
          queryClient.resetQueries(['nextBestUtterance']);
        }}
      >
        <div className={styles.utteranceDiv}>
          <Text
            type="subtitle"
            className={
              // eslint-disable-next-line no-nested-ternary
              call?.speakers ? (isAgentSpeaker ? 'orange' : 'blue') : 'black'
            }
            style={{ cursor: 'pointer' }}
            text={
              // eslint-disable-next-line no-nested-ternary
              call?.speakers
                ? isAgentSpeaker
                  ? 'Agent '
                  : 'Customer '
                : 'Segment '
            }
            onClick={() => {
              handleAgentAndTimeClick(eachTranscript?.stime, eachTranscript);
            }}
          />
          <Text
            type="subtitleSmall"
            style={{ cursor: 'pointer', padding: '0px 4px' }}
            text={`${formatTimeAddPadding(
              eachTranscript?.stime,
            )} - ${formatTimeAddPadding(eachTranscript?.etime)}`}
            onClick={() => {
              handleAgentAndTimeClick(eachTranscript?.stime, eachTranscript);
            }}
          />
          {hasViewBestUtterance ? (
            <NextBestUtterance
              call={call}
              isAgentSpeaker={isAgentSpeaker}
              index={index}
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          {parts.map((part, i) => {
            const transcriptJsx = buildTranscript(part, lastSpanIndex);

            lastSpanIndex += part.split(' ').length - 1;

            return regex && regex.test(part) ? (
              <span
                className={
                  isWordCurrentIndex(i)
                    ? styles.textCurrentHighlighted
                    : styles.textHighlighted
                }
                // eslint-disable-next-line react/no-array-index-key
                key={`${eachTranscript?.stime}-${i}`}
                data-testid="search-transcript-highlight"
              >
                {transcriptJsx}
              </span>
            ) : (
              // eslint-disable-next-line react/no-array-index-key
              <span key={i}>{transcriptJsx}</span>
            );
          })}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eachTranscript,
    searchInput,
    memoizeFlag(eachTranscript),
    currentSearchIndex,
  ]);

  return getHighlightedTranscript;
};

Transcript.propTypes = {};

export default Transcript;
