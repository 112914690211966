// cspell: ignore DDTHH, Chrono, joda
import React from 'react';
import {
  LocalDateTime,
  ZoneId,
  ZonedDateTime,
  DateTimeFormatter,
  LocalTime,
  LocalDate,
  ZoneOffset,
  ChronoUnit,
} from '@js-joda/core';
import _ from 'lodash';
import '@js-joda/timezone';
import { Locale } from '@js-joda/locale_en-us';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { sanitize } from 'dompurify';
import jose from 'node-jose';

import message from 'utils/message';
import * as scorecardList from 'utils/common/scorecardList';
import * as scorecard from 'utils/common/scorecard';
import * as callsCommonFunctions from 'utils/common/calls';
import { getPageName } from 'utils/common/pageName';

import { mixpanelKeys } from './mixpanelKeys';
import mixpanel from './mixpanel';
import {
  NON_PLURAL_WORDS_FOR_TOOLTIPS,
  regionUrlMap,
  regionWSUrlMap,
  CUSTOM_TAG,
  lambdaUrlMap,
} from './commonConstants';
/**
 * Returns the number as comma separated.
 * @param {Number} num : number to operate on
 * @returns {String}
 */
const numberWithCommas = num => {
  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}B`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return num;
};
/**
 * Formats the number, in a presentable form.
 * formatting includes, decimal place filtering, etc.
 * @param {Number} value : to format out.
 */
const numFormatting = value => {
  let val = '';
  if (value) {
    let x = value;
    x = x.toString();
    let afterPoint = '';
    if (x.indexOf('.') > 0) afterPoint = x.substring(x.indexOf('.'), x.length);
    x = Math.floor(x);
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '') lastThree = `,${lastThree}`;
    val =
      otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      lastThree +
      afterPoint;
  } else {
    val = '0';
  }
  return val;
};
/**
 * Converts seconds to proper time format for display.
 * @param {Number} time : total time to format  in seconds
 * @param {Boolean} showHours : for show time in hours or not.
 * @returns {String}
 */
const formatTimeOverTalkSilence = (time, showHours = false) => {
  const hours = parseInt(time / 3600, 10);
  const minutes = parseInt(time / 60, 10);
  const seconds = parseInt(time - minutes * 60, 10);

  if (showHours) {
    return `${hours.toString().padStart(2, 0)}h ${minutes
      .toString()
      .padStart(2, 0)}m ${seconds.toString().padStart(2, 0)}s`;
  }

  return `${minutes.toString().padStart(2, 0)}m ${seconds
    .toString()
    .padStart(2, 0)}s`;
};
/**
 * Parses string in seconds to a proper time in hours, minutes and seconds.
 * @param {String} seconds
 */
const formatDuration = seconds => {
  const secondsParsed = parseInt(seconds, 10); // don't forget the second param
  const hours = Math.floor(secondsParsed / 3600);
  const minutes = Math.floor((secondsParsed - hours * 3600) / 60);
  const res = secondsParsed - hours * 3600 - minutes * 60;
  return `${(hours > 0 ? `${hours.toString().padStart(2, 0)}h ` : '') +
    (minutes > 0 || hours > 0
      ? `${minutes.toString().padStart(2, 0)}m `
      : '')}${res.toString().padStart(2, 0)}s`;
};

const formatTimeAddPadding = (time, showHours = false) => {
  const hours = parseInt(time / 3600, 10);
  const minutes = parseInt(time / 60, 10);
  const seconds = parseInt(time - minutes * 60, 10);
  if (showHours) {
    return `${hours.toString().padStart(2, 0)}:${minutes -
      hours * (60).toString().padStart(2, 0)}:${seconds
      .toString()
      .padStart(2, 0)}`;
  }
  return `${minutes.toString().padStart(2, 0)}:${seconds
    .toString()
    .padStart(2, 0)}`;
};

const MixpanelSchema = [
  'name',
  'description',
  'callId',
  'pageNumber',
  'card',
  'eventType',
  'speaker',
  'smartReportId',
  'uuidAdhocSearch',
  'api',
  'addTagsId',
  'removeTagsId',
  'error',
  'word',
  'searchText',
  'ipAddress',
  'user_name',
  'email',
  'tagId',
  'agentId',
  'violationId',
  'dialerServiceId',
  'agentDispositionId',
];

/**
 * Checks if objs exists in the above schema.
 * @param {Object} obj
 * @returns {Boolean} Whether obj if a verified MixpanelSchema.
 */
const mixpanelVerifyKeys = obj => {
  const mapObject = {};
  const keys = Object.keys(obj);
  keys.forEach(f => {
    if (MixpanelSchema.includes(f)) {
      mapObject[f] = obj[f];
    }
  });
  return mapObject;
};
const isArray = a => !!a && a.constructor === Array;

const isObject = a => !!a && a.constructor === Object;

/**
 * Capitalizes the given key of the string.
 * @param {String} str : to Operate on
 * @param {String} key: character to capitalize.
 * @returns {String} in formatted order.
 */
const capitalizeByKey = (str, key) => {
  let capitalizedStr = str.split(key);
  capitalizedStr = capitalizedStr.map(
    s => s.charAt(0).toUpperCase() + s.substr(1),
  );
  return capitalizedStr.join(' ');
};

/**
 * Operates on String to Capitalize from spaces and full stops.
 * @param {String} str
 * @returns {String} string in proper order.
 */
/* eslint-disable no-param-reassign */
const stringCapitalize = str => {
  if (!str) {
    return '';
  }
  str = capitalizeByKey(str, ' ');
  str = capitalizeByKey(str, '.');
  return str;
};

/**
 * Parses out String in parsed order.
 * @param {String} str : Describing the path string.
 * @returns {String}
 */
const parsePathString = str => {
  // eslint-disable-next-line prefer-destructuring
  let pathStr = str.replace(process.env.PUBLIC_URL, '/').split('/')[1]; // Remove all slashes
  if (str === 'search-results') {
    return '';
  }
  pathStr = pathStr.split('-'); // Get all words
  pathStr = pathStr.map(w => w.charAt(0).toUpperCase() + w.substr(1)).join(' ');
  return pathStr;
};

/**
 * Parses the provided String into JSON, after relative decoding.
 * @param {String} token: String to Parse as JSON.
 * @returns {Object}: after parsing else false.
 */
const parseJwt = token => {
  if (!token) {
    return false;
  }
  let parsedToken;
  try {
    parsedToken = jwtDecode(token);
  } catch (error) {
    mixpanel('Parse JWT Error', { error });
    window.location.assign('/logout');
    return false;
  }
  return parsedToken;
};
const StringConstants = {
  defaultTimezone: 'America/New_York',
};
/**
 * Encodes the String into a div dangerously.
 * @param {String} html : Text to be entered into HTML element.
 * @returns {JSX}
 */
function renderAsHtml(html) {
  const htmlText = {
    __html: sanitize(html),
  };
  return <div dangerouslySetInnerHTML={htmlText} />;
}

/**
 * Checks whether tag id exist inside the List or not.
 * @param {Array} tagList : List to look Object at.
 * @param {Object} tagDict : Object to look for.
 * @returns {Boolean}
 */
const isTagPresent = (tagList, tagDict) => {
  let isTagPresentBool = false;
  tagList.forEach(tag => {
    if (tagDict._id === tag._id) {
      isTagPresentBool = true;
    }
  });
  return isTagPresentBool;
};

/**
 * Checks whether tag name exist inside the List or not.
 * @param {Array} tagList : List to look Object at.
 * @param {Object} tagDict : Object to look for.
 * @returns {Boolean}
 */
const isTagIdPresent = (tagList, tagDict) => {
  let isTagPresentBool = false;
  tagList.forEach(tag => {
    if (tagDict.name === tag.substring(tag.indexOf('/') + 1, tag.length)) {
      isTagPresentBool = true;
    }
  });
  return isTagPresentBool;
};
const formatter = format =>
  DateTimeFormatter.ofPattern(format).withLocale(Locale.US);

const dateTime = (date, format, zone, parseInGMT = false) => {
  if (!date && zone && !format) {
    return ZonedDateTime.now(ZoneId.of(zone));
  }

  if (format) {
    if (zone) {
      // Used due to inconsistency between how time is stored for calls and scorecards
      if (parseInGMT)
        return ZonedDateTime.of(LocalDateTime.parse(date), ZoneId.of('Etc/GMT'))
          .withZoneSameInstant(ZoneId.of(zone))
          .format(formatter(format));
      return ZonedDateTime.parse(new Date(date).toISOString())
        .withZoneSameInstant(ZoneId.of(zone))
        .format(formatter(format));
    }
    return LocalDateTime.parse(new Date(date).toISOString()).format(
      DateTimeFormatter.ofPattern(format),
    );
  }
  if (zone) {
    return ZonedDateTime.parse(
      new Date(date).toISOString(),
    ).withZoneSameInstant(ZoneId.of(zone));
  }
  return LocalDateTime.parse(new Date(date).toISOString());
};

const dateTimeMinus = (localDateTime, days) =>
  localDateTime.minus(days, ChronoUnit.DAYS);
const dateTimeMinusFormatter = (localDateTime, format, days) =>
  localDateTime.minus(days, ChronoUnit.DAYS).format(formatter(format));

const dateTimeFormat = (localDateTime, format) =>
  localDateTime.format(formatter(format));

const clearCache = () => {
  // eslint-disable-next-line no-unused-expressions
  window.caches &&
    caches &&
    // eslint-disable-next-line func-names
    caches.keys().then(function(cacheNames) {
      return Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
    });
};

const getLog10Value = value => Math.log10(value);

const getValueFromLog10 = value => Math.round(10 ** value);

const downloadFile = (url, fileName) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.setAttribute('download', fileName);
  link.click();
  document.body.removeChild(link);
  message.success('File download started...');
};

const sortTags = tags => {
  if (Array.isArray(tags) && tags.length > 0) {
    // Clean and check tags for displayPriority field
    const newTags = tags.map(tag => ({
      ...tag,
      displayPriority: tag.displayPriority || 10,
    }));

    /*  Sort tags by display priority with 1 being highest priority and
        use dictionary sort to resolve same value clash
    */
    return _.sortBy(newTags, [
      'displayPriority',
      function lowerCaseSort(tag) {
        return tag.name.toLowerCase();
      },
    ]);
  }
  return [];
};

const convertUTCtoLocal = time =>
  moment
    .utc(time, 'YYYY-MM-DDTHH:mm:SS') // Server sends time in UTC
    .local() // Parse to local
    .format('DD MMM YYYY @ h:mm a'); // Don't use browser date as its inconsistent

const changeIndexOfArray = (array, fromIndex, toIndex) => {
  const tmp = array[fromIndex];
  const newArray = _.cloneDeep(array);
  newArray[fromIndex] = newArray[toIndex];
  newArray[toIndex] = tmp;
  return newArray;
};

/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
const hashName = s =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

const getDateFormat = date => moment(date).format(' DD MMM YYYY [@] h:mm a');

const getDateTimeFromString = dateTimeString => {
  const time = new Date(dateTimeString).getTime();
  return time;
};

const IsJsonString = str => {
  if (!str) {
    return false;
  }
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
const isCallPresent = (selectedCallIdsList, callId) => {
  let callPresent = false;
  selectedCallIdsList.forEach(c => {
    if (callId && callId === c) {
      callPresent = true;
    }
  });
  return callPresent;
};

const modifyInitialFilter = fetchedFilters => {
  if (!fetchedFilters) {
    return {};
  }
  const filters = _.cloneDeep(fetchedFilters);
  const slider = ['callScore', 'duration', 'db_assign_amount_info', 'balance'];
  const singleSelect = ['notesPresence', 'speaker'];
  // TODO: This list is separately used again in search page to set filter
  // condition - Combine to use single source
  const multiSelect = [
    'callCenterIds',
    'callDirection',
    'agent',
    'violation',
    'agentGroup',
    'dialerService',
    'agentDisposition',
    'dialerCampaign',
    'dbr_status_list',
    'dbr_client_misc3_list',
    'dbr_client_misc4_list',
    'state',
    'agentAssignments',
    'clientNames',
    'clientNumbers',
    'dbrStatus',
    'actionCodes',
    'contactCode',
    'secondaryClient',
    'callType',
    'ticket_channel',
    'ticket_form',
    'contact_type',
    'langCode',
    'dncReason',
    'dncType',
    'department',
  ];
  const date = [
    'relativeDate',
    'dbr_assign_date_info',
    'dbr_last_charge_date_info',
  ];
  const input = [];
  const multiple = ['tag'];
  const upload = ['callId'];
  const getType = filter => {
    if (slider.includes(filter.key)) {
      return 'slider';
    }
    if (singleSelect.includes(filter.key)) {
      return 'singleSelect';
    }
    if (multiSelect.includes(filter.key)) {
      return 'multiSelect';
    }
    if (date.includes(filter.key)) {
      return 'date';
    }
    if (input.includes(filter.key)) {
      return 'input';
    }
    if (multiple.includes(filter.key)) {
      return 'multiple';
    }
    if (upload.includes(filter.key)) {
      return 'upload';
    }
    if (filter.type) return filter.type;
    mixpanel('Bad Filter Config', { filter });
    return 'multiSelect';
  };
  filters.displayFilters = filters.displayFilters.map(f => {
    let newFilter = f;
    if (f.key) {
      newFilter = { ...f, type: getType(f), field: f.key };
    }
    if (['db_assign_amount_info'].includes(f.field)) {
      newFilter = { ...f, subtype: 'amount' };
    }
    return newFilter;
  });
  filters.displayFilters.unshift({
    isDisabled: false,
    key: 'relativeDate',
    name: 'Call Date',
    field: 'relativeDate',
    customField: 'date',
    type: 'date',
    subtype: 'customDate',
  });
  filters.advancedFilters = filters.advancedFilters.map(f => {
    if (f.key) return { ...f, type: 'input', field: f.key };
    return f;
  });
  filters.balance = {
    ...filters.balanceBounds,
    min: Math.round(filters.balanceBounds.minBalance),
    max: Math.round(filters.balanceBounds.maxBalance),
  };
  filters.db_assign_amount_info = {
    ...filters.db_assign_amount_info,
    min: Math.round(filters.db_assign_amount_info.minAssignAmount),
    max: Math.round(filters.db_assign_amount_info.maxAssignAmount),
  };
  filters.dbr_assign_date_info = {
    ...filters.dbr_assign_date_info,
    min: Math.round(filters.dbr_assign_date_info.minAssignDate),
    max: Math.round(filters.dbr_assign_date_info.maxAssignDate),
  };
  filters.dbr_last_charge_date_info = {
    ...filters.dbr_last_charge_date_info,
    min: Math.round(filters.dbr_last_charge_date_info.minLastChargeDate),
    max: Math.round(filters.dbr_last_charge_date_info.maxLastChargeDate),
  };
  filters.duration = {
    ...filters.duration,
    min: Math.round(filters.durationBounds.minDuration),
    max: Math.round(filters.durationBounds.maxDuration),
  };
  filters.callScore = {
    ...filters.callScore,
    min: Math.round(filters.scoreBounds.minScore),
    max: Math.round(filters.scoreBounds.maxScore),
  };
  filters.callDirection = filters.callDirectionList;
  filters.callCenterIds = filters.callCenterIdsList;
  filters.agent = filters.agentList;
  filters.agentAssignments = filters.agentAssignmentsList;
  filters.violation = filters.violationList;
  filters.agentGroup = filters.agentGroupList;
  filters.tag = filters.tagList;
  filters.dialerCampaign = filters.campaignList;
  filters.dialerService = filters.serviceList;
  filters.agentDisposition = filters.resultList;
  filters.state = filters.stateList;
  filters.speaker = [
    { _id: 'Agent', name: 'Agent' },
    { _id: 'Borrower', name: 'Customer' },
  ];
  return filters;
};

const timeSince = date => {
  const newDate = new Date(date).getTime();
  const seconds = Math.floor((new Date() - newDate) / 1000);
  let interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return moment(date).format('MMM Do h:mm a');
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `${interval} hrs`;
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return `${interval} mins`;
  }
  return `${Math.floor(seconds)} secs`;
};

const formatAccountNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`;
  if (cleaned) {
    return cleaned.toUpperCase();
  }
  return null;
};

const formatPhoneNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let value = [match[2], match[3], match[4]].join('');
    value = value.toString();
    return value.substr(value.length - 10);
  }
  return null;
};

const insertByIndexInArray = (arr, i, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, i),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(i),
];

const handleStatsFetchLoaded = data => {
  const {
    termCodeRecords,
    tagsRecords,
    timeline,
    agentRecords,
    agentGroup,
    campaign,
    service,
    callDuration,
    callMetrics,
  } = data;
  return {
    agentGroup:
      agentGroup && agentGroup.records.length > 1
        ? {
            ...agentGroup,
            records: _.orderBy(agentGroup.records, ['value'], ['desc']),
          }
        : agentGroup,
    campaign:
      campaign && campaign.records.length > 1
        ? {
            ...campaign,
            records: _.orderBy(campaign.records, ['value'], ['desc']),
          }
        : campaign,
    service:
      service && service.records.length > 1
        ? {
            ...service,
            records: _.orderBy(service.records, ['value'], ['desc']),
          }
        : service,
    tagsRecords:
      tagsRecords && tagsRecords.records.length > 1
        ? {
            ...tagsRecords,
            records: _.orderBy(tagsRecords.records, ['value'], ['desc']),
          }
        : tagsRecords,
    agentRecords:
      agentRecords && agentRecords.records.length > 1
        ? {
            ...agentRecords,
            records: _.orderBy(agentRecords.records, ['value'], ['desc']),
          }
        : agentRecords,
    timeline:
      timeline && timeline.records.length > 1
        ? {
            ...timeline,
            records: _.orderBy(
              timeline.records,
              ['year', 'month', 'day'],
              ['asc', 'asc', 'asc'],
            ),
          }
        : timeline,
    callDuration,
    callMetrics,
    termCodeRecords:
      termCodeRecords && termCodeRecords.records.length > 1
        ? {
            ...termCodeRecords,
            records: _.orderBy(termCodeRecords.records, ['value'], ['desc']),
          }
        : termCodeRecords,
  };
};
const validateEmail = email => {
  const exp = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9]{2,61}(?:[a-zA-Z0-9-.][a-zA-Z0-9]{2,61})*$/;
  return email.match(exp);
};

const isAlphaNumeric = str => /^[a-z0-9]+$/gi.test(str);

function trimNull(a) {
  const c = a.indexOf('\0');
  if (c > -1) {
    return a.substr(0, c);
  }
  return a;
}

const baseURLByRegion = type => {
  const accessToken = localStorage.getItem('access_token');
  const parse = parseJwt(accessToken);
  const region = parse
    ? parse[`${process.env.REACT_APP_AUTH0_AUDIENCE}/region`]
    : '';
  let baseUrl = '';
  switch (type) {
    case 'api':
      baseUrl = regionUrlMap[region];
      break;
    case 'lambda':
      baseUrl = lambdaUrlMap[region];
      break;
    default:
      baseUrl = regionWSUrlMap[region];
      break;
  }
  if (!baseUrl && window.location.pathname !== '/callback')
    mixpanel('Empty BaseURL', {});
  return baseUrl;
};

const getBaseUrl = () => baseURLByRegion('api');

function splitIntoChunks(array, chunk) {
  const tempArray = [];
  for (let i = 0; i < array?.length; i += chunk) {
    tempArray.push(array.slice(i, i + chunk));
  }
  return tempArray;
}
const isStringJson = string => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

/** used in Events tab to group events in parent child form */

const groupCallEvents = callEvents => {
  const eventsByCheck = _.cloneDeep(callEvents);
  const events = [];
  const eventsFound = [];
  eventsByCheck.map(eachEvent1 => {
    const childEvents = [];
    eventsByCheck.map(eachEvent2 => {
      if (eachEvent1.eventType === eachEvent2.eventType) {
        if (!eventsFound.includes(eachEvent2.eventType)) {
          childEvents.push(eachEvent2);
        }
      }
      return null;
    });
    if (childEvents.length > 0 && !eventsFound.includes(eachEvent1.eventType)) {
      eventsFound.push(eachEvent1.eventType);
      if (childEvents.length > 1) {
        events.push({
          ...eachEvent1,
          children: childEvents,
        });
      } else {
        events.push(eachEvent1);
      }
    }
    return null;
  });
  return events;
};

const mixpanelDomainCheck = domain =>
  domain &&
  domain !== 'prodigaltech.com' &&
  window.location.host.includes('prodigaltech.com');

/**
 * Builds Tooltip message that shows up on the UI above "+n" Tag button
 * @param {*} addTagList list of tags that are added
 * @param {*} removeTagList list of tags that are removed
 * @returns tooltip message with names of first 2 tags and rest as "+n" updated as per conditions
 */

const getTooltipMessage = (addTagList, removeTagList) => {
  let toolTipMessage = '';
  const totalUpdates = addTagList.length + removeTagList.length;
  /**
   * Conditions below check various cases, When tag is added and removed and builds message as per that
   * 1st case handles the case when tag is removed and one is added
   * rest of the cases are to handle messages cases
   * Addition is shown in higher priority compared to removal of tags
   * as Depicted in conditions below
   */

  if (addTagList.length === 1 && removeTagList.length === 1) {
    toolTipMessage = `${addTagList[0].name},${removeTagList[0].name} updated`;
  } else if (addTagList.length === 1) {
    toolTipMessage = `${addTagList[0].name} added`;
  } else if (removeTagList.length === 1) {
    toolTipMessage = `${removeTagList[0].name} removed`;
  } else if (addTagList.length > 1) {
    toolTipMessage = `${addTagList[0].name}, ${addTagList[1].name} ${
      totalUpdates > 2 ? `+ ${totalUpdates - 2}` : ''
    } updated`;
  } else if (removeTagList.length > 1) {
    toolTipMessage = `${removeTagList[0].name}, ${removeTagList[1].name} ${
      totalUpdates > 2 ? `+ ${totalUpdates - 2}` : ''
    } updated`;
  }

  return toolTipMessage;
};

/**
 * Decides whether we want to show a string as a singular or plural in Tooltips
 * i.e., whether to add a "s" suffix or not
 * @param {*} string
 * @returns string to be shown on Tooltip
 */
const buildFilterTooltipString = (string = '') => {
  if (!string) return string;
  /**
   * Boolean indicates whether string is present in the list of non plurals
   */
  const isStringSingular = string
    ?.split(' ')
    ?.some(eachString =>
      NON_PLURAL_WORDS_FOR_TOOLTIPS.includes(eachString.toLowerCase()),
    );

  if (isStringSingular) {
    return string?.toLowerCase() || '';
  }
  return `${string}s`.toLowerCase();
};

const removeAllDOMNodes = className => {
  const allElements = document.getElementsByClassName(className);

  if (!allElements?.length) return;
  const allElementsArray = new Array(...allElements);
  /**
   * Removes all the visible DOM nodes preset
   * Emulates the same behavior as happens on DestroyOnHide in Tooltip
   */

  allElementsArray.forEach(eachElement => {
    if (eachElement) {
      eachElement.remove();
    }
  });
};

const tagAppliedMixpanelEvent = metrics => {
  const {
    itemIds,
    deletedTag,
    allTags,
    addTagList,
    removeTagList,
    selectedTags,
    newCustomTagCount,
  } = metrics;
  let tagAppliedEvent = {};
  /**
   * common Attributes
   */

  const filterCustomTags = tags =>
    tags?.filter(eachTag => eachTag?._id.indexOf(CUSTOM_TAG) !== -1).length ||
    0;

  if (itemIds) {
    tagAppliedEvent = {
      [mixpanelKeys.call]: itemIds?.length,
      [mixpanelKeys.addLength]: addTagList?.length || 0,
      [mixpanelKeys.addCustomTagsLength]:
        filterCustomTags(addTagList) + (newCustomTagCount || 0),
    };
  }
  /**
   * Add Tag specific calculations
   */
  if (removeTagList && selectedTags) {
    tagAppliedEvent = {
      ...tagAppliedEvent,
      [mixpanelKeys.removeLength]: removeTagList?.length,
      [mixpanelKeys.removeCustomTagsLength]: filterCustomTags(removeTagList),
    };
    // handles case for multiple call, when we don't need the new length
    if (itemIds.length < 2) {
      tagAppliedEvent = {
        ...tagAppliedEvent,
        [mixpanelKeys.newLength]: selectedTags?.length || 0,
      };
    }
  }
  /**
   * Single delete Case done via popover
   */
  if (itemIds && deletedTag && allTags) {
    tagAppliedEvent = {
      ...tagAppliedEvent,
      [mixpanelKeys.deletedTagName]: deletedTag?._id,
      [mixpanelKeys.newLength]: allTags.length - 1,
      [mixpanelKeys.removeLength]: 1,
      [mixpanelKeys.removeCustomTagsLength]: deletedTag?._id?.includes(
        'custom tag/',
      )
        ? 1
        : 0,
    };
  }
  mixpanel('Tag Applied', tagAppliedEvent);
};

/**
 * @returns The height value after removal of Tags is possible to fit in Add tags and "+n" Button
 */

const getSubtractableTagBoxHeight = () => {
  let subtractableHeight = 100;
  if (window.innerHeight < 1200) {
    subtractableHeight = 90;
  }
  if (window.innerHeight < 1000) {
    subtractableHeight = 80;
  }
  if (window.innerHeight < 800) {
    subtractableHeight = 70;
  }
  if (window.innerHeight < 600) {
    subtractableHeight = 60;
  }
  if (window.innerHeight < 420) {
    subtractableHeight = 30;
  }

  return subtractableHeight;
};

/**
 * Parses regex out from simple string to convert regex based string for matching words in calls
 * @param {String} query : search query
 * @returns String
 */
const simplifyRegex = query => {
  // Discussed at - https://app.asana.com/0/1115495471169550/1179306063291851/f
  const regex = /(?:\s+|^|\|)\w+(\*)|(?<= )\*(?= )|(?<= )\*(\d+)(?= )/gm;
  const defaultNum = 5;

  const matches = [...query.matchAll(regex)];

  if (matches.length) {
    let newStr = '';
    let lastChar = 0;

    matches.forEach(match => {
      const [ast, d] = [match[1], match[2]];
      if (ast) {
        // example match: " this* " and group captured (ast) is "*"
        newStr = [
          ...newStr,
          `${query.slice(lastChar, match.index + (match[0].length - 1))}\\S*`,
        ];
        lastChar = match.index + match[0].length;
      } else {
        // example match: "*10" and group captured (d) is "10"
        let num = defaultNum;
        if (d) num = d;
        newStr = [
          ...newStr,
          `${query.slice(
            lastChar,
            match.index + (match[0].length - (d?.length || 0)) - 1,
          )}(\\S+\\s){0,${num}}`,
        ];
        lastChar = match.index + match[0].length + 1;
      }
    });
    // If left over substring at the end
    if (lastChar < query.length)
      newStr = [...newStr, `${query.slice(lastChar, query.length)}`];

    return newStr.join('');
  }
  return query;
};

const getUTCSpecificTime = (timeString = '00:00') =>
  ZonedDateTime.of(
    LocalDate.now(ZoneOffset.UTC),
    LocalTime.parse(timeString),
    ZoneId.of('Etc/UTC'),
  );
const canExportToday = () => {
  const currentRegion = localStorage.getItem('region') || 'US';
  const currentUTCTime = ZonedDateTime.now(ZoneId.of('Etc/UTC'));
  // https://www.notion.so/prodigaltech/Scheduled-Auto-Export-of-Smart-Reports-BE-e827840bb63d40828892c39475c30270#5bfce484c9ad40a0b63f07a29c8f72da
  // 3PM ET/7PM UTC for US and Canada
  const USExportTime = getUTCSpecificTime('19:00');
  // 5:30AM ET/ 9:30AM UTC for UK
  const UKExportTime = getUTCSpecificTime('09:30');

  let isExportPossible = false;
  let exportDate = moment().add(1, 'days');
  if (currentRegion === 'US' || currentRegion === 'CANADA') {
    if (currentUTCTime.isBefore(USExportTime)) {
      isExportPossible = true;
      exportDate = moment();
    }
  } else if (currentRegion === 'UK') {
    if (currentUTCTime.isBefore(UKExportTime)) {
      isExportPossible = true;
      exportDate = moment();
    }
  } else {
    mixpanel('Failed to parse Region', {
      [mixpanelKeys.error]: currentRegion,
    });
  }
  return { isExportPossible, exportDate };
};

const getMomentDate = (from, unit, amountOfTime = 0) => {
  if (from === 'startOf') {
    return moment()
      .add(amountOfTime, unit)
      .startOf(unit);
  }
  if (from === 'endOf') {
    return moment()
      .add(amountOfTime, unit)
      .endOf(unit);
  }
  return moment();
};

const validateRawRegex = rawRegexQuery => new RegExp(rawRegexQuery);

/**
 *Build Request Parameters for Edit and Add Users used in pages/userManagement
 * @param {*} values
 * @returns concatenated values of first name and last name as name along with other values passed.
 */
const buildCRUDRequestParam = (values = {}) => {
  const processedFormValues = {};
  // eslint-disable-next-line no-unused-expressions
  Object.keys(values)?.forEach(eachValue => {
    if (values[eachValue]) {
      processedFormValues[eachValue] =
        typeof values[eachValue] === 'string'
          ? values[eachValue]?.trim()
          : values[eachValue];
    }
  });

  if (values?.firstName || values?.lastName) {
    processedFormValues.name = `${values?.firstName || ''} ${values?.lastName ||
      ''}`;
  }

  return processedFormValues;
};

/**
 * Builds the list the list of allowed Roles the User can see on Add and Edit Modals
 * @param {*} configRoles list of all possible Roles with fixed keys for roles and names on tenant basis
 * @param {*} hasEditUsers Edit users Permission
 * @param {*} hasUserListAdmin Permission to see all users including Prodigal Admins
 * @returns list of roles that current user can operate upon.
 */
const getRolesByScope = (configRoles, hasEditUsers, hasUserListAdmin) => {
  const rolesByScope = [];
  if (configRoles) {
    if (hasUserListAdmin || hasEditUsers) {
      configRoles.forEach(eachRole => {
        if (eachRole?._id) rolesByScope.push({ id: eachRole._id, ...eachRole });
      });
    }
  }
  return rolesByScope;
};

/*
 * Get the first element present in the DOM with provided className.
 * @param {String} className : Class name to fetch from the document.
 * @returns HTMLElement
 */
const getElementByClassName = className =>
  document.getElementsByClassName(className)?.[0];

/**
 * encrypts the signed JSON Web Token (JWT) to an aes-gcm based Javascript Object Signing and Encryption (JOSE) token.
 * @param {String} signedJwt
 * @returns Decrypted string
 */
const joseEncrypt = async signedJwt => {
  if (!process.env.REACT_APP_JOSE_ENCRYPTION_KEY) return null;
  try {
    const jweKey = {
      kty: 'oct',
      use: 'enc',
      alg: 'A256GCM',
      k: process.env.REACT_APP_JOSE_ENCRYPTION_KEY,
    };
    const resultKey = await jose.JWK.asKey(jweKey);
    const encryptedToken = jose.JWE.createEncrypt(
      { format: 'compact' },
      resultKey,
    )
      .update(signedJwt)
      .final();
    return encryptedToken;
  } catch (err) {
    return null;
  }
};

export {
  trimNull,
  numberWithCommas,
  numFormatting,
  formatTimeOverTalkSilence,
  formatTimeAddPadding,
  formatDuration,
  mixpanelVerifyKeys,
  isArray,
  isObject,
  stringCapitalize,
  parsePathString,
  parseJwt,
  StringConstants,
  renderAsHtml,
  isTagPresent,
  isTagIdPresent,
  dateTime,
  dateTimeMinus,
  formatter,
  clearCache,
  getLog10Value,
  getValueFromLog10,
  dateTimeMinusFormatter,
  dateTimeFormat,
  downloadFile,
  sortTags,
  convertUTCtoLocal,
  changeIndexOfArray,
  hashName,
  getDateFormat,
  getDateTimeFromString,
  IsJsonString,
  isCallPresent,
  modifyInitialFilter,
  timeSince,
  formatAccountNumber,
  formatPhoneNumber,
  insertByIndexInArray,
  handleStatsFetchLoaded,
  validateEmail,
  scorecardList,
  scorecard,
  callsCommonFunctions,
  getBaseUrl,
  baseURLByRegion,
  getPageName,
  splitIntoChunks,
  groupCallEvents,
  isStringJson,
  mixpanelDomainCheck,
  tagAppliedMixpanelEvent,
  buildFilterTooltipString,
  removeAllDOMNodes,
  getTooltipMessage,
  getSubtractableTagBoxHeight,
  simplifyRegex,
  buildCRUDRequestParam,
  canExportToday,
  getMomentDate,
  validateRawRegex,
  getRolesByScope,
  isAlphaNumeric,
  getElementByClassName,
  joseEncrypt,
};
