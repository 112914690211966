/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/**
 *
 * Table
 *
 */

import PropTypes from 'prop-types';
import React from 'react';
import { Table as CustomTable } from 'antd';

import Checkbox from '../Checkbox';
import Icon from '../Icon';
import Text from '../Text';
import './style.less';
import styles from './style.css';

const Table = ({
  tableType = 'default',
  maxRowsCount = 0,
  // __, ___ helps in understanding number of params to the function
  onHeaderCheckboxClick = __ => {},
  onEditEnabled = __ => {},
  onRowCheckboxClick = (__, ___) => {},
  headerCheckboxCondition = __ => true,
  headerCheckboxEditCondition = __ => true,
  rowCheckboxCondition = (__, ___) => true,
  rowCheckDisabledCondition = __ => true,
  ...rest
}) => {
  const { dataSource = [] } = rest;

  /**
   * Provides the JSX for Cell as Heading for each Columns
   * @param {String} type : Role name as string
   * @returns JSX for the Cell of the Header
   */
  const handleTableHeadRendering = type => {
    let permissionEnabled = false;
    let isPartial = false;

    const permissionsSize = dataSource.reduce(
      (enabledPermissions, permission) => {
        if (permission[type]) enabledPermissions += 1;
        return enabledPermissions;
      },
      0,
    );
    const totalPermissions = maxRowsCount;
    if (permissionsSize === totalPermissions) {
      isPartial = false;
      permissionEnabled = true;
    } else if (permissionsSize > 0) {
      isPartial = true;
      permissionEnabled = false;
    } else {
      isPartial = false;
      permissionEnabled = false;
    }

    return (
      <span className={styles.headerStyle}>
        <div className={styles.rowCell}>
          {headerCheckboxCondition(type) ? (
            <Checkbox
              className="checkBoxContainer"
              checked={permissionEnabled}
              indeterminate={isPartial}
              onChange={() => onHeaderCheckboxClick(type)}
            />
          ) : null}
          <span className={styles.headerTitle}>
            <Text type="table-title" text={type} />
            {headerCheckboxEditCondition(type) ? (
              <Icon
                size={14}
                onClick={() => {
                  onEditEnabled(type);
                }}
                useAntd={false}
                type="editIcon"
              />
            ) : (
              <div className={styles.spacer} />
            )}
          </span>
        </div>
      </span>
    );
  };

  /**
   *Provides JSX to fill the cell with UI elements (Checkbox and Text).
   Checkbox is conditionally rendered based on checkbox rendering condition passed as prop
   * @param {Object} data: Row Information
   * @param {String} type: Role type as string
   * @returns {JSX} fills JSX for each cell of the row
   */
  const handleTableRowRending = (data, type) => {
    let permissionEnabled = false;
    permissionEnabled = data[type] === true;

    let titleJsx;
    if (data[type] === null) titleJsx = 'N/A';
    else if (permissionEnabled) titleJsx = 'Enabled';
    else titleJsx = 'Disabled';

    return (
      <span className={`${data?.isHeader ? styles.headerStyle : ''}`}>
        <div className={styles.rowCell}>
          {rowCheckboxCondition(data, type) ? (
            <Checkbox
              className="checkBoxContainer"
              checked={permissionEnabled}
              disabled={data?.isHeader && rowCheckDisabledCondition(type)}
              onChange={() => onRowCheckboxClick(type, data)}
            />
          ) : null}
          <Text
            text={titleJsx}
            type="sub-title"
            className={
              data[type] !== null && !permissionEnabled
                ? 'lighterText'
                : 'bolderText'
            }
          />
        </div>
      </span>
    );
  };

  /**
   * Modifies the columns passed down as prop to the Table component.
   * Based on the title of the Column, add title and render prop for modified cells for each row
   * @param {Object} columns : columns object passed as prop, to define table structure.
   * @returns Transformed columns
   */
  const populateColumns = columns =>
    columns.map(column => {
      const { title } = column;
      if (['Permissions'].includes(title))
        return {
          ...column,
          title: () => handleTableHeadRendering(title),
        };
      return {
        ...column,
        title: () => handleTableHeadRendering(title),
        render: (__, data) => handleTableRowRending(data, title),
      };
    });

  switch (tableType) {
    case 'checkbox':
      // eslint-disable-next-line no-case-declarations
      const { columns, ...tableProps } = rest;
      return (
        <CustomTable
          {...tableProps}
          columns={populateColumns(columns)}
          pagination={
            rest.pagination
              ? { ...rest.pagination, showSizeChanger: false }
              : false
          }
        />
      );
    default:
      return (
        <CustomTable
          {...rest}
          pagination={
            rest.pagination
              ? { ...rest.pagination, showSizeChanger: false }
              : false
          }
        />
      );
  }
};

Table.propTypes = {
  tableType: PropTypes.string,
  maxRowsCount: PropTypes.number,
  onHeaderCheckboxClick: PropTypes.func,
  onEditEnabled: PropTypes.func,
  onRowCheckboxClick: PropTypes.func,
  headerCheckboxCondition: PropTypes.func,
  headerCheckboxEditCondition: PropTypes.func,
  rowCheckboxCondition: PropTypes.func,
  rowCheckDisabledCondition: PropTypes.func,
};

export default Table;
