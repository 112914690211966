/**
 *
 * Modal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal as AntdModal } from 'antd';

import Icon from '../Icon';
import Button from '../Button';
import './style.less';
// import styled from 'styled-components';

function Modal({
  acceptButtonText,
  cancelButtonText,
  title,
  closable,
  visible,
  onAccept,
  onCancel,
  footer,
  loading,
  wrapClassName = 'vertical-center-modal',
  closeIcon = <Icon type="close" style={{ objectFit: 'contain' }} />,
  ...rest
}) {
  return (
    <AntdModal
      wrapClassName={wrapClassName}
      width="fit-content"
      {...rest}
      title={title}
      closeIcon={closeIcon}
      footer={null}
      closable={closable !== undefined ? closable : true}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
    >
      {rest.children}
      {footer && (
        <div className="modal-btn-container">
          <Button text={cancelButtonText || 'Cancel'} onClick={onCancel} />
          <Button
            loading={loading}
            type="primary"
            text={acceptButtonText || 'Apply'}
            onClick={onAccept}
          />
        </div>
      )}
    </AntdModal>
  );
}

Modal.propTypes = {
  acceptButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  title: PropTypes.string,
  closable: PropTypes.bool,
  visible: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  footer: PropTypes.bool,
  loading: PropTypes.bool,
  wrapClassName: PropTypes.string,
  closeIcon: PropTypes.any,
};

export default Modal;
export { AntdModal };
