/**
 *
 * Dropdown
 *
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Badge, Dropdown as AntdDropdown } from 'antd';

import Icon from '../Icon';
import Button from '../Button';
import Popover from '../PopOver';
import './style.less';
// import styled from 'styled-components';

function Dropdown({
  button,
  icon,
  placement,
  placeholder,
  rowItems,
  selectedItem,
  onRowItemClicked,
  useAntd = false,
  badge,
  ...rest
}) {
  if (useAntd) {
    return <AntdDropdown {...rest} />;
  }
  let selectedRowItem = {};
  const content = (
    <div>
      {rowItems?.map((rowItem, index) => {
        let rowClassName = '';
        let isSelected = false;
        if (selectedItem) {
          rowClassName = 'item-selected';
          isSelected = true;
          if (selectedItem.id && selectedItem.id === rowItem.id) {
            selectedRowItem = rowItem;
          } else {
            selectedRowItem = rowItem;
          }
        }
        return (
          <div
            key={rowItem.id}
            className={`dropdown-row-item ${
              index < rowItems.length - 1 ? 'dropdown-row-item-border' : ''
            } ${rowClassName}`}
            style={{
              borderBottom: '',
            }}
            role="button"
            aria-hidden="true"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              onRowItemClicked(index, rowItem);
            }}
          >
            <Badge dot={rowItem.badge}>
              {isSelected && (
                <Icon
                  className="row-item-prefix row-item-selected"
                  type="tick"
                />
              )}
              {rowItem.icon && (
                <Icon className="row-item-prefix" type={rowItem.icon} />
              )}
              <span className="row-item-name">
                {rowItem.name ? rowItem.name : rowItem}
              </span>
              {icon && (
                <Icon
                  type={icon}
                  style={{
                    marginLeft: 5,
                    transform:
                      rowItem.type && rowItem.type === -1
                        ? 'rotate(180deg)'
                        : null,
                  }}
                />
              )}
            </Badge>
          </div>
        );
      })}
    </div>
  );
  const classNames = ['dropdown-content'];
  if (rest.className) classNames.push(rest.className);

  const buttonText = selectedRowItem.name ? (
    <Fragment>
      {selectedRowItem.name}
      <Icon
        type={icon || 'arrowUp'}
        style={{
          transform:
            selectedRowItem.type && selectedRowItem.type === -1
              ? 'rotate(180deg)'
              : null,
        }}
      />
    </Fragment>
  ) : (
    placeholder
  );
  return (
    <Popover
      {...rest}
      position={placement || 'bottomRight'}
      content={content}
      title={null}
      overlayClassName={classNames.join(' ')}
      overlayStyle={{ top: '36px !important' }}
    >
      {button || <Button text={buttonText} />}
    </Popover>
  );
}

Dropdown.propTypes = {
  button: PropTypes.node,
  icon: PropTypes.string,
  placement: PropTypes.string,
  rowItems: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  selectedItem: PropTypes.object,
  onRowItemClicked: PropTypes.func,
  useAntd: PropTypes.bool,
  badge: PropTypes.bool,
};

export default Dropdown;
