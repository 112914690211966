// cspell: ignore immer
/* eslint-disable react/no-danger */
/**
 *
 * Component: MultiSelectTree
 * Date: 15/6/2020
 *
 */

import React, {
  useEffect,
  useReducer,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import Search from 'antd/lib/input/Search';
import dompurify from 'dompurify';
import _ from 'lodash';

import { Checkbox, Icon, Text, Button, Tooltip } from 'components/common';
import { TEXT_TOOLTIP } from 'utils/commonConstants';
import { getElementByClassName } from 'utils/commonFunctions';
import FilterLayout from 'components/FilterLayout';

import { TOOLTIP_CLASS_NAME } from './constants';
import styles from './style.css';

function MultiSelectTree({
  filter = {},
  selectedValues,
  filterValues,
  tagRules,
  handleClear,
  onFilterApplied,
  moreFilter,
  hideTitle,
  multiSelectFilter,
  setShowMoreFilter,
  buttonName,
  single,
  filterKeyId,
}) {
  const multiSelectFilterRef = useRef();
  const multiSelectListContainerRef = useRef(null);
  const initialState = {
    displayedSelectedValues: [],
    searchText: '',
    displayedFilterValues: [],
    selectedValuesState: selectedValues,
    showFilter: false,
    totalColumns: 0,
    title: '',
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'filterDisplayedValues': {
        const {
          displayedSelectedValues,
          searchText,
          displayedFilterValues,
          selectedValuesState,
          totalColumns,
        } = action.payload;
        return {
          ...state,
          displayedSelectedValues,
          searchText,
          displayedFilterValues,
          selectedValuesState,
          totalColumns,
        };
      }
      case 'selectFilterValue': {
        const { title, selectedValuesState } = action.payload;
        return {
          ...state,
          title,
          selectedValuesState,
        };
      }
      case 'setCondition': {
        return {
          ...state,
          conditionState: action.payload,
        };
      }
      case 'setRuleTypeState': {
        return {
          ...state,
          ruleTypeState: action.payload,
        };
      }
      case 'setCheckTypeState': {
        return {
          ...state,
          checkTypeState: action.payload,
        };
      }
      case 'setShowFilter': {
        return {
          ...state,
          showFilter: action.payload,
        };
      }
      case 'handleClose': {
        return {
          ...state,
          searchText: '',
          displayedFilterValues: [],
          selectedValuesState: [],
          displayedSelectedValues: [],
          showFilter: false,
          conditionState: state.conditionState || 'in',
        };
      }
      case 'updateSelectedValues': {
        return { ...state, selectedValuesState: action.payload };
      }
      case 'cancelChanges': {
        return {
          ...state,
          selectedValuesState: selectedValues,
        };
      }
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    displayedFilterValues,
    selectedValuesState,
    showFilter,
    searchText,
    totalColumns,
    title,
  } = state;

  useEffect(() => {
    filterDisplayedValues('', selectedValues);
  }, [selectedValues, filterDisplayedValues, filterValues]);

  /**
   * This function is supposed to order selected values and remaining values in the list in ascending order one after other
   * @param -> {Array} filterArgs and {Array} selectedStateArgs
   * @return -> filtered, Grouped and merger Array.
   *
   */
  const functionForValuesOrder = useCallback(
    (filterArgs, selectedStateArgs) => {
      // TODO: use immer patch
      let newSelectedValues = filterArgs.filter(f =>
        selectedStateArgs.includes(f._id),
      );
      const selectedValuesId = selectedStateArgs;
      const displayFilter = _.orderBy(
        filterArgs.filter(f => {
          if (selectedValuesId.includes(f._id)) {
            return false;
          }
          return true;
        }),
        ['group', 'name'],
        ['asc', 'asc'],
      );

      newSelectedValues = _.orderBy(
        newSelectedValues,
        ['group', 'name'],
        ['asc', 'asc'],
      );

      return [...newSelectedValues, ...displayFilter];
    },
    [],
  );

  /**
   * this function is used to handle display selected values of drop down, which will change count shown and list of options showed
   *
   * @param -> {String} searchTextArgs and {Array} valuesArgs
   * @return -> Reducer action to update displayedSelectedValues, searchText, displayedFilterValues state
   *
   */
  const filterDisplayedValues = useCallback(
    (searchTextArgs, selectedValuesStateArgs) => {
      let newDisplayedFilterValues = [];
      // this function is to set searched text bold in options
      const searchOption = searchTextArgs
        .trimLeft()
        .replace(RegExp(`[!?+@#$%^&*_.]`, 'g'), match => `\\${match}`);
      const testString = (s, b) => {
        if (s) {
          const match = RegExp(`(?:\\b|_)(${b})`, 'i').exec(s); // Test word boundary or starts with '_'
          if (match) return true;
        }
        return false;
      };

      const boldString = (s, b) => {
        // match[0] is full match whereas [1] is only the string without leading '_'
        const match = RegExp(`(?:\\b|_)(${b})`, 'i').exec(s)[1];
        return s.replace(RegExp(`${match}`, 'i'), `<b>$&</b>`); // where '$&' returns matched substring
      };

      if (searchTextArgs) {
        filterValues.forEach(option => {
          const matchInTagName = testString(option.title, searchOption);
          if (matchInTagName) {
            newDisplayedFilterValues.push({
              ...option,
              formattedName: (
                <span
                  dangerouslySetInnerHTML={{
                    __html: dompurify.sanitize(
                      boldString(option.title, searchOption),
                    ),
                  }}
                />
              ),
            });
          }
        });
      } else {
        newDisplayedFilterValues = _.cloneDeep(filterValues);
      }

      let newTotalColumns = 0;
      filterValues.forEach(f => {
        newTotalColumns += 1;
        if (f.children)
          f.children.forEach(() => {
            newTotalColumns += 1;
          });
      });
      newDisplayedFilterValues = _.orderBy(newDisplayedFilterValues, ['fixed']);

      // added formattedName and formattedGroup to keep original values untouched which are used in tooltip etc
      const updatedSelectedValues =
        selectedValuesStateArgs || selectedValuesState;
      const displayedFilterValuesId = newDisplayedFilterValues.map(f => f._id);
      const newDisplayedSelectedValues = updatedSelectedValues.filter(id =>
        displayedFilterValuesId.includes(id),
      );

      newDisplayedFilterValues = _.cloneDeep(
        functionForValuesOrder(
          newDisplayedFilterValues,
          selectedValuesStateArgs || selectedValuesState,
        ),
      );

      dispatch({
        payload: {
          displayedSelectedValues: newDisplayedSelectedValues,
          searchText: searchTextArgs,
          displayedFilterValues: _.cloneDeep(newDisplayedFilterValues),
          selectedValuesState: selectedValuesStateArgs || selectedValuesState,
          totalColumns: newTotalColumns,
        },
        type: 'filterDisplayedValues',
      });
    },
    [filterValues, selectedValuesState, functionForValuesOrder],
  );

  /**
   * Called every time an option is selected
   *
   * @param -> {Number} index of option and {Object} e for checked status
   * @return -> Reducer action to update items state
   *
   */
  const selectFilterValue = (id, titleArg, e) => {
    let newSelectedValues = _.cloneDeep(selectedValuesState);
    const selectedAllValues = [];

    displayedFilterValues.forEach(section => {
      if (section.key === id && section.children)
        section.children.forEach(question =>
          selectedAllValues.push(question.key),
        );
    });

    if (!_.isEmpty(selectedAllValues)) {
      newSelectedValues = newSelectedValues.filter(
        s => !selectedAllValues.includes(s),
      );
    }
    if (e.target.checked) {
      newSelectedValues.push(id, ...selectedAllValues);
    } else {
      newSelectedValues = newSelectedValues.filter(s => s !== id);
    }
    if (single) {
      if (newSelectedValues.find(s => s === id)) {
        newSelectedValues = newSelectedValues.filter(s => s === id);
      } else {
        newSelectedValues.push(id);
      }
    }

    dispatch({
      payload: {
        selectedValuesState: newSelectedValues,
        title: titleArg,
      },
      type: 'selectFilterValue',
    });
  };
  /**
   * Called every time when select all checkbox is clicked
   *
   * @param -> {Object} event for checked status
   * @return -> Reducer action to update items state
   *
   */
  const selectAllFilterValues = event => {
    let newSelectedValues = [];
    if (event.target.checked) {
      displayedFilterValues.forEach(section => {
        newSelectedValues.push(section.key);
        if (section.children)
          section.children.forEach(question =>
            newSelectedValues.push(question.key),
          );
      });
    } else if (
      filterKeyId === 'selectColumns' &&
      displayedFilterValues.some(displayArg => displayArg.fixed)
    ) {
      newSelectedValues = [
        ...displayedFilterValues
          .filter(displayArg => displayArg.fixed)
          .map(displayArg => displayArg.key),
      ];
    }

    dispatch({
      payload: {
        selectedValuesState: newSelectedValues,
      },
      type: 'selectFilterValue',
    });
  };

  /**
   * Function to get Button name as it is variable depending on selected values
   *
   * @return -> button name
   *
   */
  const getButtonName = () => {
    let newTitle = buttonName;
    if (selectedValues?.length === 1) {
      newTitle = filterValues.find(
        template => template.key === selectedValues[0],
      );
      if (!_.isEmpty(newTitle)) {
        ({ title: newTitle } = newTitle);
      }
      if (!newTitle) {
        newTitle = title;
      }
      return `${buttonName} • ${newTitle || selectedValues[0]}`;
    }
    if (selectedValues?.length > 1) {
      return `${buttonName} • ${selectedValues.length}`;
    }
    return buttonName;
  };

  const resetStateOfPlusButton = () => {
    if (!selectedValues?.length) handleClose();
  };

  const handleClose = () => {
    dispatch({
      type: 'handleClose',
    });
  };

  const getClassName = filterValue => {
    if (
      filterKeyId === 'selectColumns' &&
      (filterValue.fixed && selectedValues.includes(filterValue.key))
    ) {
      return `${styles.fixed} checkbox`;
    }
    return 'checkbox';
  };

  // Removes the tooltip Elements from the DOM
  const resetTooltip = useCallback(() => {
    const tooltipElement = getElementByClassName(`${TOOLTIP_CLASS_NAME}`);
    if (tooltipElement) tooltipElement.remove();
  }, []);

  useLayoutEffect(() => {
    if (multiSelectListContainerRef.current)
      multiSelectListContainerRef.current.addEventListener(
        'scroll',
        resetTooltip,
      );

    return () => {
      if (multiSelectListContainerRef.current)
        multiSelectListContainerRef.current.removeEventListener(
          'scroll',
          resetTooltip,
        );
    };
  }, [showFilter]);

  const getFilter = () => (
    <div>
      {filterValues.length ? (
        <div>
          <div>
            <Search
              allowClear
              autoFocus
              placeholder={buttonName || 'Search Columns'}
              value={searchText}
              onChange={e => {
                filterDisplayedValues(e.target.value);
              }}
            />
          </div>
          {!single && (
            <div style={{ marginLeft: '.5rem', marginTop: '.5rem' }}>
              <Checkbox
                className="checkbox"
                checked={
                  selectedValuesState.length > 0 &&
                  selectedValuesState.length === totalColumns
                }
                indeterminate={
                  selectedValuesState.length > 0 &&
                  selectedValuesState.length !== totalColumns
                }
                onChange={selectAllFilterValues}
              />
              <span>
                {` ${selectedValuesState.length} of ${totalColumns} selected`}
              </span>
              <hr className="divider" />
            </div>
          )}
          <div
            className={styles.multiselectFilterContent}
            ref={multiSelectListContainerRef}
          >
            {displayedFilterValues.map(filterValue => (
              <>
                <Checkbox
                  key={filterValue.key}
                  className={getClassName(filterValue)}
                  checked={selectedValuesState.includes(filterValue.key)}
                  onChange={e =>
                    selectFilterValue(filterValue.key, filterValue.title, e)
                  }
                >
                  <Tooltip
                    title={filterValue.title}
                    type={TEXT_TOOLTIP}
                    disabled={!filterValue.title}
                    placement="topLeft"
                    overlayClassName={TOOLTIP_CLASS_NAME}
                    destroyTooltipOnHide={{
                      keepParent: false,
                    }}
                  >
                    {filterValue.title}
                  </Tooltip>
                </Checkbox>
                <div
                  className={`${
                    filterValue.children ? styles.optionsWithChildren : ''
                  } question-option`}
                >
                  {filterValue.children?.map(question => (
                    <Checkbox
                      key={question.key}
                      className="checkbox"
                      checked={selectedValuesState.includes(question.key)}
                      onChange={e =>
                        selectFilterValue(question.key, question.title, e)
                      }
                    >
                      <Tooltip
                        title={question.title}
                        type={TEXT_TOOLTIP}
                        disabled={!question.title}
                        placement="topLeft"
                        overlayClassName={TOOLTIP_CLASS_NAME}
                        destroyTooltipOnHide={{
                          keepParent: false,
                        }}
                      >
                        {question.title}
                      </Tooltip>
                    </Checkbox>
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
  const handleClickOutside = () => {
    dispatch({ payload: false, type: 'setShowFilter' });
    handleListener(false);
  };

  /**
   * Handles listener apply and removal.
   * */
  const handleListener = bool => {
    if (bool) {
      document.addEventListener('mousedown', handleClickEvent);
    } else {
      document.removeEventListener('mousedown', handleClickEvent);
    }
  };

  /**
   * Detects if click is done outside of component or not.
   * */
  const handleClickEvent = e => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (
      !path.includes(multiSelectFilterRef.current) &&
      !e.target.classList.value.includes('ant-select-dropdown-menu-item') &&
      e.target.classList.value !== 'ant-select-selection__rendered' &&
      e.target.classList.value !== 'ant-select-item-option-content' &&
      !e.target.classList.value.includes('ant-select-item')
    ) {
      // added extra conditions in if because of equal and not equal condition select option
      handleClickOutside();
    }
  };

  /**
   * returns the key from filterValues and section.children as a Array
   *  */
  const getOrderedColumns = value => {
    const top = [];
    if (filterValues) {
      filterValues.forEach(section => {
        if (value.includes(section.key)) {
          top.push(section.key);
        }
        if (section.children)
          section.children.forEach(question => {
            if (value.includes(question.key)) {
              top.push(question.key);
            }
          });
      });
    }
    return top;
  };

  /**
   * Renders FilerLayout on showFilter enabled, else undefined.
   * Wrapping the filter component in Filter Layout for the consistency.
   * */
  const getFilterComponent = () => {
    let component;
    if (showFilter) {
      component = (
        <FilterLayout
          onCancel={() => {
            dispatch({ type: 'cancelChanges' });
            filterDisplayedValues('', selectedValues);
            handleClickOutside();
          }}
          onApply={() => {
            handleClickOutside();
            onFilterApplied(getOrderedColumns(selectedValuesState));
            resetStateOfPlusButton();
          }}
          data-cypress={filterKeyId}
        >
          {getFilter()}
        </FilterLayout>
      );
      // }
    }
    return component;
  };

  /**
   * Counts the total length of data present if flattened.
   * */
  const selectedValuesLength = () => {
    let totalLength = selectedValues?.length || 0;
    if (multiSelectFilter) {
      totalLength = 0;
      selectedValues.forEach(selectedArgs => {
        totalLength += selectedArgs?.value?.length || 0;
      });
    } else if (tagRules) {
      return !(selectedValues === undefined || selectedValues.length === 0);
    }
    return totalLength;
  };

  return (
    <div ref={multiSelectFilterRef} className={styles.container}>
      <div className={styles.filterButtonContainer}>
        {moreFilter && !hideTitle && (
          <Text type="subtitle" text={filter?.name} />
        )}
        <div className="grid-column">
          <div className="filter-button">
            <Button
              id={!selectedValuesLength() ? 'plusBtn' : null}
              height="25px"
              iconSize={12}
              useAntd
              type={selectedValuesLength() ? 'chips' : 'chips-secondary'}
              icon={
                !selectedValuesLength() && (moreFilter || tagRules)
                  ? 'plus'
                  : null
              }
              onClick={() => {
                if (multiSelectFilter && selectedValues?.length > 1) {
                  setShowMoreFilter(true);
                } else {
                  dispatch({
                    type: 'setShowFilter',
                    payload: !showFilter,
                  });
                  handleListener(!showFilter);
                }
              }}
              text={
                selectedValuesLength() || !moreFilter ? getButtonName() : ''
              }
            />
          </div>

          {filter.field !== 'tag' &&
            !tagRules &&
            moreFilter &&
            selectedValues?.length > 0 && (
              <Icon
                size={12}
                onClick={() => handleClear(filter.field)}
                type="delete"
              />
            )}
        </div>
      </div>
      {getFilterComponent()}
    </div>
  );
}
export default MultiSelectTree;

MultiSelectTree.propTypes = {
  filter: PropTypes.object,
  selectedValues: PropTypes.array,
  filterValues: PropTypes.array,
  tagRules: PropTypes.bool,
  moreFilter: PropTypes.bool,
  handleClear: PropTypes.func,
  onFilterApplied: PropTypes.func,
  hideTitle: PropTypes.bool,
  multiSelectFilter: PropTypes.bool,
  setShowMoreFilter: PropTypes.func,
  buttonName: PropTypes.string,
  single: PropTypes.bool,
  filterKeyId: PropTypes.string,
};
