/**
 *
 * Unauthorized
 *
 */

import React from 'react';

import './style.less';
import { Button, EmptyState, Text } from 'components/common';
import Auth from 'auth0-react';

const { logout } = new Auth();

// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function Unauthorized() {
  return (
    <div className="unauthorized">
      <EmptyState
        message={
          <>
            <Text
              text="You are not authorized, mail us at support@prodigaltech.com to get
          access"
              type=""
            />
            <div className="logout-container-unauthorized">
              <Button onClick={() => logout()} type="link-primary">
                Click here to Log Out
              </Button>
            </div>
          </>
        }
      />
    </div>
  );
}

Unauthorized.propTypes = {};

export default Unauthorized;
