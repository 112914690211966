/**
 *
 * Component: Form
 * Date: 24/9/2020
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form as CustomForm, Row, Checkbox } from 'antd';

import { validateEmail, isAlphaNumeric } from 'utils/commonFunctions';

import styles from './style.css';
import Icon from '../Icon';
import Input from '../Input';
import Select from '../Select';
import Button from '../Button';
import Text from '../Text';

function Form({
  form = null,
  items,
  saveButton = {},
  layout,
  className,
  formItemLayout = {},
  cancelButton = {},
  initialValues = {},
  onClose,
  hideButton,
  loading,
  handleFormSubmit,
  resetOnCancel,
  rowGutter,
  onValuesChange,
  errorMessage = '',
}) {
  const [customFormObject] = form ?? CustomForm.useForm();
  const handleSubmit = values => {
    handleFormSubmit(values);
  };

  /**
   * This function generates rules array for each form item
   *
   * @param -> {Object} item
   * @return -> will return rules array {Array} ruleArray
   *
   */
  const getRules = item => {
    const ruleArray = [
      {
        required:
          item.key === 'password' || item.key === 'role_id'
            ? true
            : item.required || false,
        message: `Please enter ${item.placeholder.toLowerCase()}!`,
      },
    ];
    const { key } = item;
    if (['name', 'group', 'displayName'].includes(key)) {
      ruleArray.push({
        // regex for alphanumerics with space
        // eslint-disable-next-line no-useless-escape
        pattern: `^[a-zA-Z0-9\.\_\: \-]+$`,
        message: 'Only alphanumeric, space, . - _ are allowed!',
      });
    }
    if (['firstName', 'lastName'].includes(key)) {
      ruleArray.push({
        // regex for alphanumerics without space
        // eslint-disable-next-line no-useless-escape
        pattern: `^[a-zA-Z0-9\.\_\:\-]+$`,
        message: 'Only alphanumeric, . - _ are allowed!',
      });
    }
    if (['name', 'firstName', 'lastName'].includes(key)) {
      ruleArray.push({
        max: 40,
        message: 'Only 40 characters are allowed',
      });
    }
    if (item.validator) {
      const compareToFirstPassword = value => {
        if (value && value !== customFormObject.getFieldValue('newPassword')) {
          return 'Passwords must match';
        }
        return null;
      };

      const validateToNextPassword = value => {
        if (value && String(value).length < 8) {
          return 'password must be at least 8 characters';
        }
        return null;
      };

      const validateFormEmail = value => {
        if (value && !validateEmail(value)) {
          return 'enter a valid email!';
        }
        return null;
      };
      const validateAlphaNumeric = value => {
        if (value && !isAlphaNumeric(value)) {
          return 'enter an alpha numeric value!';
        }
        return null;
      };

      const validateFunctionsObject = {
        compareToFirstPassword,
        validateToNextPassword,
        validateFormEmail,
        validateAlphaNumeric,
      };
      if (item.validator && validateFunctionsObject[item.validator])
        ruleArray.push(() => ({
          validator(rule, value) {
            const newError = validateFunctionsObject[item.validator](value);

            if (!newError) {
              return Promise.resolve();
            }

            return Promise.reject(newError);
          },
        }));
    }
    return ruleArray;
  };

  /**
   * This function generates form item depending on it's type
   *
   * @param -> {Object} item
   * @return -> will return relevant component for Form item
   *
   */
  const getFormField = item => {
    switch (item.type) {
      case 'password': {
        return (
          <Input
            key={item.key}
            type="password"
            placeholder={item.placeholder}
          />
        );
      }
      case 'description': {
        return (
          <Input
            key={item.key}
            rows={3}
            type={item.type || 'text-area'}
            placeholder={item.placeholder}
          />
        );
      }
      case 'select': {
        return (
          <Select
            key={item.key}
            className={styles.selectClass}
            placeholder={item.placeholder}
          >
            {item.options && Array.isArray(item.options)
              ? item.options.map(role => (
                  // eslint-disable-next-line react/jsx-no-undef
                  <Option value={role.id} key={role.id}>
                    {role.name}
                  </Option>
                ))
              : null}
          </Select>
        );
      }
      case 'priority': {
        return (
          <Input
            type="number"
            key={item.key}
            precision={0}
            min={1}
            max={100}
            placeholder={item.placeholder}
          />
        );
      }
      case 'checkbox': {
        return <Checkbox>{item.placeholder}</Checkbox>;
      }
      case 'custom': {
        return item.component;
      }
      default: {
        return (
          <Input
            maxLength={40}
            key={item.key}
            size={item.size}
            prefix={
              item.prefixType ? (
                <Icon
                  type={item.prefixType}
                  style={{ color: 'rgba(0,0,0,.25)' }}
                />
              ) : null
            }
            suffix={item?.suffix || null}
            type={item.type}
            placeholder={item.placeholder}
            disabled={item?.disabled}
            {...item}
          />
        );
      }
    }
  };

  const { getFieldsError } = customFormObject;
  const errors = getFieldsError();
  const hasErrors = Object.keys(errors).some(
    field => errors[field]?.length > 0,
  );

  const onCustomValueChange = (change, all) => {
    if (onValuesChange) {
      onValuesChange(change, all);
    }
  };

  return (
    <>
      <CustomForm
        data-testid="form-cy"
        form={customFormObject}
        {...formItemLayout}
        layout={layout || 'horizontal'}
        onFinish={handleSubmit}
        className={`${styles.customForm} ${className || ''}`}
        scrollToFirstError
        initialValues={initialValues}
        onValuesChange={onCustomValueChange}
      >
        {rowGutter ? (
          <Row gutter={rowGutter}>
            {items.map(item => (
              <Col span={item?.span || 24}>
                <CustomForm.Item
                  data-testid={`rules-cy-${item.label}`}
                  className={item.className ? item.className : ''}
                  key={item.key}
                  name={item.key}
                  label={item.label}
                  rules={getRules(item)}
                  {...item}
                >
                  {getFormField(item)}
                </CustomForm.Item>
              </Col>
            ))}
          </Row>
        ) : (
          items.map(item => (
            <CustomForm.Item
              data-testid={`rules-cy-${item.label}`}
              className={item.className ? item.className : ''}
              key={item.key}
              name={item.key}
              label={item.label}
              rules={getRules(item)}
              {...item}
            >
              {getFormField(item)}
            </CustomForm.Item>
          ))
        )}

        {errorMessage ? <Text type="Error" text={errorMessage} /> : <></>}

        <CustomForm.Item className={styles.customButton}>
          <div
            className={`${hideButton ? styles.hideCancelBtn : ''} ${
              styles.formButtonTags
            }`}
          >
            {!hideButton && (
              <div>
                <Button
                  width={cancelButton.width ? cancelButton.width : '100%'}
                  onClick={e => {
                    if (resetOnCancel) customFormObject.resetFields();
                    onClose(e);
                  }}
                  disabled={loading}
                >
                  {cancelButton && cancelButton.text
                    ? cancelButton.text
                    : 'Cancel'}
                </Button>
              </div>
            )}

            <div>
              <Button
                type={saveButton.type ? saveButton.type : 'primary'}
                width={saveButton.width ? saveButton.width : '100%'}
                htmlType="submit"
                disabled={hasErrors || saveButton?.disabled}
                loading={loading}
              >
                {saveButton && saveButton.text ? saveButton.text : 'Save'}
              </Button>
            </div>
          </div>
        </CustomForm.Item>
      </CustomForm>
    </>
  );
}

Form.propTypes = {
  items: PropTypes.array,
  saveButton: PropTypes.object,
  layout: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  formItemLayout: PropTypes.object,
  cancelButton: PropTypes.object,
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
  hideButton: PropTypes.bool,
  loading: PropTypes.bool,
  handleFormSubmit: PropTypes.func,
  onValuesChange: PropTypes.func,
  resetOnCancel: PropTypes.bool,
  rowGutter: PropTypes.number,
  form: PropTypes.any,
};

export default Form;

const { useForm } = CustomForm;
export { useForm };
