/**
 *
 * Component: RichInput
 * Date: 29/6/2020
 *
 */

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Slate, Editable, withReact } from 'slate-react';
import { Text, createEditor, Node, Transforms } from 'slate';
import { withHistory } from 'slate-history';

import { Tooltip } from 'components/common';

import styles from './style.css';

const RichInput = ({
  defaultValue,
  backgroundColor,
  onChange,
  placeholder = '',
  hasError = [],
  onPressEnter = () => {},
  updateDefaultInputValue,
  onChangeWithoutBlur,
  ...rest
}) => {
  const style = {
    height: rest.height || '43px',
    lineHeight: `${rest.height}px` || '43px',
    backgroundColor: backgroundColor || 'inherit',
  };
  const [inputText, setInputText] = useState([
    {
      children: [
        {
          text: defaultValue || '',
        },
      ],
    },
  ]);

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  useEffect(() => {
    setInputText([
      {
        children: [
          {
            text: defaultValue || '',
          },
        ],
      },
    ]);
    Transforms.deselect(editor);
  }, [defaultValue, editor, updateDefaultInputValue]);

  /**
   * Returns all items for a query matching with regex.
   * */
  const simplifyRegex = query => {
    const regex = /(?:\s+|^|\|)\w+(\*)|(?<= )\*(?= )|(?<= )\*(\d+)(?= )/gm;

    return [...query.matchAll(regex)];
  };

  /**
   * @param {Array} Node
   * @param {path} path
   *
   * @return {Array} Array of Objects for EditableText, consisting of anchor, focus and highlight.
   */
  const decorate = useCallback(
    ([node, path]) => {
      const ranges = [];
      if (Text.isText(node)) {
        const { text } = node;
        const matches = simplifyRegex(text);

        if (matches.length) {
          const starMap = [...text].flatMap((char, i) =>
            char === '*' ? i : [],
          );
          const starMatchIndices = {};
          matches.forEach(match => {
            const [ast, d] = [match[1], match[2]];
            if (ast) {
              ranges.push({
                anchor: { path, offset: match.index },
                focus: { path, offset: match.index + match[0].length },
                highlight: 'ast',
              });
              starMatchIndices[match.index + match[0].length - 1] = true;
            } else {
              ranges.push({
                anchor: { path, offset: match.index },
                focus: {
                  path,
                  offset: match.index + (match[0].length + (d?.length || 0)),
                },
                highlight: 'num',
              });
              starMatchIndices[match.index] = true;
            }
          });

          starMap.forEach(i => {
            if (!starMatchIndices[i])
              ranges.push({
                anchor: { path, offset: i },
                focus: {
                  path,
                  offset: i + 1,
                },
                highlight: 'error',
              });
          });
        }
      }

      if (hasError?.length && Text.isText(node)) {
        const { text } = node;
        const currentParts = text.split(',');

        let offset = 0;
        currentParts.forEach((part, i) => {
          if (hasError.includes(part.trim())) {
            ranges.push({
              anchor: { path, offset: offset === 0 ? 0 : offset + i },
              focus: { path, offset: offset + i + part.length },
              error: true,
            });
          }
          offset += part.length;
        });
      }

      return ranges;
    },
    [hasError],
  );
  /**
   * Serializes the nodes as String.
   * */
  const serialize = nodes => nodes.map(n => Node.string(n)).join('');
  return (
    <Slate
      editor={editor}
      value={inputText}
      onChange={input => {
        setInputText(input);
        if (onChangeWithoutBlur) onChange(serialize(input));
      }}
    >
      <Editable
        autoFocus
        style={style}
        decorate={decorate}
        placeholder={placeholder}
        className={`${styles.richInput} richInput`}
        renderLeaf={props => <Leaf {...props} />}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            // prevent default behavior
            event.preventDefault();
            onPressEnter();
          }
        }}
        onBlur={() => {
          if (inputText) {
            onChange(serialize(inputText));
          }
        }}
      />
    </Slate>
  );
};

const colors = {
  ast: '#c6e3ff',
  num: '#74c4ff',
  error: '#ed5c65',
};

/**
 * Return the Style as String for the type and leaf.
 * */
const getStyle = (leaf, type) => {
  switch (type) {
    case 'background': {
      if (leaf.highlight) {
        return colors[leaf.highlight];
      }
      if (leaf.error) {
        return '#ed5c65';
      }
      return 'inherit';
    }
    case 'color': {
      if (leaf.highlight === 'error' || leaf.error) {
        return '#fff';
      }
      return 'inherit';
    }
    case 'padding': {
      if (leaf.highlight) {
        return '1px';
      }
      if (leaf.error) {
        return '2px 3px';
      }
      return '0px';
    }

    default:
      break;
  }
  return '';
};

/**
 * Return the Tooltip String.
 * */
const getTooltip = leaf => {
  if (leaf.highlight) {
    switch (leaf.highlight) {
      case 'error':
        return 'Invalid syntax';
      case 'ast':
        return `Captures a word that starts with '${leaf.text.slice(
          0,
          -1,
        )}'. Eg: ${leaf.text.slice(0, -1)}(ed) or ${leaf.text.slice(
          0,
          -1,
        )}(ing)`;
      case 'num':
        return `Wildcard. Captures any phrase that has 0 to ${leaf.text.slice(
          1,
        )} words.`;
      default:
        return '';
    }
  }
  if (leaf.error) {
    return 'error';
  }
  return '';
};

// eslint-disable-next-line react/prop-types
const Leaf = ({ attributes, children, leaf }) => (
  <Tooltip title={getTooltip(leaf)}>
    <span
      {...attributes}
      style={{
        background: getStyle(leaf, 'background'),
        color: getStyle(leaf, 'color'),
        padding: getStyle(leaf, 'padding'),
      }}
    >
      {children}
    </span>
  </Tooltip>
);

RichInput.propTypes = {
  hasError: PropTypes.array,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  backgroundColor: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onPressEnter: PropTypes.func,
  updateDefaultInputValue: PropTypes.number,
  onChangeWithoutBlur: PropTypes.func,
};

export default RichInput;
