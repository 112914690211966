import React from 'react';
import produce from 'immer';
import _ from 'lodash';
import {
  LocalDateTime,
  ZoneId,
  ZonedDateTime,
  DateTimeFormatter,
} from '@js-joda/core';
import '@js-joda/timezone';
import { Locale } from '@js-joda/locale_en-us';

import Text from 'components/common/Text';
import DateUtils from 'utils/date';

const textColor = '#999999';

const StringConstants = {
  defaultTimezone: 'America/New_York',
};
const primaryTimezone =
  getLocalStorageValue('timezone') || StringConstants.defaultTimezone;

const formatter = format =>
  DateTimeFormatter.ofPattern(format).withLocale(Locale.US);

/**
 * Parses Date Time into given format, zoneId, and into GMT if required.
 * @param {Date} date
 * @param {String} format
 * @param {String} zone
 * @param {Boolean} parseInGMT
 * @returns Parsed Date Time based on Zone.
 */
const dateTime = (date, format, zone, parseInGMT = false) => {
  if (!date && zone && !format) {
    return ZonedDateTime.now(ZoneId.of(zone));
  }

  if (format) {
    if (zone) {
      // Used due to inconsistency between how time is stored for calls and scorecards
      if (parseInGMT)
        return ZonedDateTime.of(LocalDateTime.parse(date), ZoneId.of('Etc/GMT'))
          .withZoneSameInstant(ZoneId.of(zone))
          .format(formatter(format));
      return ZonedDateTime.parse(new Date(date).toISOString())
        .withZoneSameInstant(ZoneId.of(zone))
        .format(formatter(format));
    }
    return LocalDateTime.parse(new Date(date).toISOString()).format(
      DateTimeFormatter.ofPattern(format),
    );
  }
  if (zone) {
    return ZonedDateTime.parse(
      new Date(date).toISOString(),
    ).withZoneSameInstant(ZoneId.of(zone));
  }
  return LocalDateTime.parse(new Date(date).toISOString());
};
function setLocalStorageValue(key, value) {
  localStorage.setItem(key, value);
  return JSON.parse(value);
}

function getLocalStorageValue(key) {
  return localStorage.getItem(key);
}

function getTemplateTree(value) {
  // eslint-disable-next-line no-underscore-dangle
  const treePattern = {
    ...value,
    title: value.name,
    value: value._id,
    key: value._id,
  };
  return treePattern;
}

function getTree(value) {
  const treePattern = { title: value, value, key: value };
  return treePattern;
}

function getOptionTree(value, title, obj = {}) {
  const treePattern = { ...obj, title, value, key: value };
  return treePattern;
}

/**
 * Generates column options based on grades and section questions.
 */
function updateOptionTree(template) {
  const columnOptionTree = [];
  if (_.isArray(template.grade)) {
    const mainTree = getTree('Grade');
    mainTree.children = [];
    template.grade.forEach(grade => {
      if (!_.isEmpty(grade)) {
        mainTree.children.push(getOptionTree(`grade${grade.id}`, grade.name));
      }
    });
    columnOptionTree.push(mainTree);
  }
  if (template.sections)
    template.sections.forEach(section => {
      const sectionTree = getTree(section.name, 'section');
      if (!_.isEmpty(section.questions)) {
        sectionTree.children = [];
        section.questions.forEach(question =>
          sectionTree.children.push(getTree(question.name, 'question')),
        );
      }
      columnOptionTree.push(sectionTree);
    });
  return columnOptionTree;
}

function generateKeyValueObjectFromArray(array) {
  const tempObject = {};
  array.forEach(a => {
    tempObject[a] = a;
  });
  return tempObject;
}
/**
 * This function is to create compatible dataSource for antd Tables from response of API
 *
 * @param -> data is {Array}
 * @return -> processed dataSource for antd Table
 *
 */
function getPreprocessedTableData(data) {
  if (!_.isEmpty(data)) {
    const tableData = produce(data, scorecards => {
      scorecards.map(scorecard =>
        scorecard.sections?.forEach(section => {
          // eslint-disable-next-line no-param-reassign
          scorecard[section.name] = {
            score: section.score,
            maxScore: section.maxScore,
            type: 'section',
          };
          section.questions.forEach(question => {
            // eslint-disable-next-line no-param-reassign
            scorecard[question.name] = {
              ...question,
              type: 'question',
            };
          });
        }),
      );
    });
    return tableData;
  }
  return [];
}

/**
 * This function is to create columns for antd Tables
 *
 * @param -> defaultColumnsMap is {Array}, selectedColumnMap is {Object}, dataSource is {Array}
 * @return -> Particular column object for antd Table
 *
 */
const getColumn = (
  defaultColumnsMap,
  basicInitialColumn,
  selectedColumnMap,
  dataSource,
  scorecardTemplateList,
) => {
  let columns = defaultColumnsMap.map(key =>
    getColumnDefaultValue(
      basicInitialColumn,
      key.dataIndex,
      scorecardTemplateList,
    ),
  );
  if (!_.isEmpty(selectedColumnMap)) {
    const selectedKeys = Object.keys(selectedColumnMap);
    columns = [
      ...columns,
      ...selectedKeys.map(key =>
        getColumnDefaultValue(
          selectedColumnMap,
          key,
          scorecardTemplateList,
          dataSource && dataSource[0] ? dataSource[0][key]?.type : '',
        ),
      ),
    ];
  }
  return _.orderBy(columns.filter(column => !_.isEmpty(column)), ['fixed']);
};

/**
 * Returns Percentage as string.
 * @param {Number} n : Number to find the percentage of.
 * @param {Number} d : Total to compute percentage from.
 * @returns {String}: Percentage as string else if d==0 returns N/A.
 */
const getPercent = (n, d) => {
  if (d === 0) {
    return 'N/A';
  }
  return `${Math.round((n * 100) / d)} %`;
};

/**
 * This function is to set column object according to column type
 *
 * @param -> columnsMap is {Object||Array}, Key is {String}, type is {String}
 * @return -> Particular column object for antd Table
 *
 */
function getColumnDefaultValue(
  columnsMap,
  key,
  scorecardTemplateList,
  type = null,
) {
  if (type === 'aggregatedSection') {
    return {};
  }
  const column = _.isArray(columnsMap)
    ? columnsMap.find(c => c.dataIndex === key)
    : null;
  const basicColumn = column || {
    dataIndex: key,
    title: columnsMap[key],
  };
  basicColumn.align = 'center';
  basicColumn.width = 120;
  basicColumn.onHeaderCell = () => ({
    style: {
      whiteSpace: 'normal',
      maxWidth: 120,
      hyphens: 'auto',
    },
  });
  const getAnswerColor = answerType => {
    let color = 'gray';
    switch (answerType) {
      case 'POSITIVE':
        color = 'ptp-green';
        break;
      case 'NEGATIVE':
        color = 'ptp-red';
        break;
      default:
    }
    return color;
  };
  if (type) {
    switch (type) {
      case 'section':
        basicColumn.render = section => (
          <Text
            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
            text={`${
              section.maxScore
                ? `${section.score} / ${section.maxScore}`
                : section.score
            }`}
          />
        );
        basicColumn.width = 100;
        basicColumn.onCell = () => ({
          style: {
            whiteSpace: 'normal',
            maxWidth: 100,
            color: textColor,
            fontStyle: 'italic',
          },
        });
        basicColumn.onHeaderCell = () => ({
          style: {
            whiteSpace: 'normal',
            maxWidth: 100,
            color: textColor,
            fontStyle: 'italic',
            hyphens: 'auto',
          },
        });
        break;
      case 'question':
        basicColumn.render = question => (
          <Text
            style={{
              display: 'block',
              width: '84px',
              overflow: 'hidden',
              'white-space': 'nowrap',
              'text-overflow': 'ellipsis',
            }}
            type={getAnswerColor(question.answerType)}
            text={
              question.qType === 'FREEFORM' ? question.value : question.answer
            }
          />
        );
        basicColumn.width = 100;
        basicColumn.onCell = () => ({
          style: {
            whiteSpace: 'normal',
            maxWidth: 100,
            color: textColor,
          },
        });
        basicColumn.onHeaderCell = () => ({
          style: {
            whiteSpace: 'normal',
            maxWidth: 100,
            color: textColor,
            hyphens: 'auto',
          },
        });
        break;
      case 'aggregatedQuestion':
        basicColumn.render = question => (
          <Text
            style={{
              display: 'block',
              textALign: 'center',
              overflow: 'hidden',
              'white-space': 'nowrap',
              'text-overflow': 'ellipsis',
            }}
            text={`${question.value} %`}
          />
        );
        basicColumn.width = 100;
        basicColumn.onCell = () => ({
          style: {
            whiteSpace: 'normal',
            maxWidth: 100,
            color: textColor,
          },
        });
        basicColumn.onHeaderCell = () => ({
          style: {
            whiteSpace: 'normal',
            maxWidth: 100,
            color: textColor,
            hyphens: 'auto',
          },
        });
        break;
      case 'aggregatedGrade':
        basicColumn.title = basicColumn.title.replace('grade', 'grade-');
        basicColumn.render = grade => (
          <Text
            style={{
              display: 'block',
              overflow: 'hidden',
              'white-space': 'nowrap',
              'text-overflow': 'ellipsis',
            }}
            text={getPercent(grade.value[0], grade.value[1])}
          />
        );
        basicColumn.width = 100;
        basicColumn.onCell = () => ({
          style: {
            whiteSpace: 'normal',
            maxWidth: 100,
          },
        });
        basicColumn.onHeaderCell = () => ({
          style: {
            whiteSpace: 'normal',
            maxWidth: 100,
            hyphens: 'auto',
          },
        });
        break;

      default:
    }
  } else if (basicColumn.type) {
    switch (basicColumn.type) {
      case 'date': {
        basicColumn.dataIndex = basicColumn.key;
        basicColumn.render = date => (
          <Text
            text={
              date
                ? dateTime(date, 'dd MMM uuuu @ h:mm a', primaryTimezone, true)
                : 'N/A'
            }
          />
        );
        break;
      }
      case 'string':
        basicColumn.dataIndex = basicColumn.key;
        basicColumn.render = string => <Text text={string || 'N/A'} />;
        break;
      case 'number':
        basicColumn.dataIndex = basicColumn.key;
        // might change alignment
        basicColumn.render = number => <Text text={number} />;
        break;
      case 'score':
        basicColumn.dataIndex = `${basicColumn.type}${basicColumn.key[0]}${
          basicColumn.key[1]
        }`;
        basicColumn.render = (score, row) => (
          <Text
            text={`${row[basicColumn.key[0]]} / ${row[basicColumn.key[1]]}`}
          />
        );
        break;
      case 'percent': {
        basicColumn.dataIndex = `${basicColumn.type}${basicColumn.key[0]}${
          basicColumn.key[1]
        }`;
        basicColumn.render = (percent, row) => (
          <Text
            text={getPercent(row[basicColumn.key[0]], row[basicColumn.key[1]])}
          />
        );
        break;
      }
      case 'stringifyToPercent': {
        basicColumn.dataIndex = basicColumn.key;
        basicColumn.render = stringifyToPercent => (
          <Text text={`${stringifyToPercent} %`} />
        );
        break;
      }
      case 'fail':
        basicColumn.dataIndex = basicColumn.key;
        basicColumn.render = fail => (
          <Text
            type={fail ? 'ptp-red' : 'ptp-green'}
            text={fail ? 'Fail' : 'Pass'}
          />
        );
        break;
      case 'bool':
        basicColumn.dataIndex = basicColumn.key;
        basicColumn.render = bool => <Text text={bool ? 'Yes' : 'No'} />;
        break;
      case 'duration':
        basicColumn.dataIndex = basicColumn.key;
        basicColumn.render = duration => (
          <Text text={DateUtils.formatDuration(duration || 0)} />
        );
        break;
      case 'custom_scorecardTemplateId':
        basicColumn.dataIndex = basicColumn.key;
        basicColumn.render = string => (
          <Text text={scorecardTemplateList[string]} />
        );
        break;
      default:
    }
  }

  return basicColumn;
}

/**
 * Modifies the searched out filters, with all belonging to singleSelect as type to own type as date for only display filters.
 * @param {Object} fetchedFilters: Information regarding filters fetched.
 */
const modifyInitialFilter = fetchedFilters => {
  const filters = _.cloneDeep(fetchedFilters);
  filters.displayFilters = filters.displayFilters.map(displayFilter => {
    if (displayFilter.type === 'singleSelect') {
      return { ...displayFilter, type: 'date' };
    }
    return displayFilter;
  });
  return filters;
};

/**
 * Generates initial state for the columns.
 * @param {Object | Array} data : data information of column Mapping.
 * @param {Object} scorecardTemplateList : String text from score card template.
 * @returns {array}
 */
const generateBasicColumnState = (data, scorecardTemplateList) => {
  const baseColumn = produce(data, draft => {
    // eslint-disable-next-line no-param-reassign
    draft = draft
      .filter(sketch => sketch.type !== 'tree')
      .map(key =>
        getColumnDefaultValue(data, key.dataIndex, scorecardTemplateList),
      );
    return draft;
  });
  return baseColumn;
};

/**
 * Generates a Column set using dimensions provided by the API, for aggregated scorecard.
 * @param {String} : information regarding key aggregated with.
 * @param {Object} dimensionDetails: Selected filter details.
 * @param {Object} scorecardTemplateList: List of all templates.
 */
const getColumnSetFromDimension = (
  key,
  dimensionDetails,
  scorecardTemplates,
) => {
  const { title, lookupField } = dimensionDetails;
  const newColumn = {
    title,
    key,
    dataIndex: '_id',
    align: 'center',
    fixed: 'left',
  };
  if (lookupField) {
    switch (lookupField) {
      case 'scorecard':
        newColumn.render = cell => (
          <Text
            text={
              scorecardTemplates?.find(template => template.key === cell)?.title
            }
          />
        );
        break;

      default:
        break;
    }
  }

  return newColumn;
};

const numberOfAgentsColumn = {
  title: 'Number of agents',
  value: 'Number of agents',
  key: 'Number of agents',
  dataIndex: 'Number of agents',
  align: 'center',
};

/**
 * Creates all possible columns from aggregated key.
 * @param {Array} columns : All columns possible for the aggregate
 * @param {Object} state : Information regarding to generate column.
 */
const getColumnFromDimension = (columns, state) => {
  const { dimensionsTreeList } = state;
  const selectedDimension = state.selectedDimensions;
  const newColumns = produce(columns, draft => {
    // eslint-disable-next-line no-param-reassign
    draft = [...draft.filter(column => column.key !== 'Number of agents')];
    if (selectedDimension !== 'agentName') {
      // eslint-disable-next-line no-param-reassign
      draft = [numberOfAgentsColumn, ...draft];
    }
    // eslint-disable-next-line no-param-reassign
    draft = [
      getColumnSetFromDimension(
        selectedDimension,
        dimensionsTreeList.find(
          eachDimension => eachDimension.key === selectedDimension,
        ),
        state.scorecardTemplates,
      ),
      ...draft.filter(
        column =>
          column.key !== selectedDimension && column.key !== 'Collector Name',
      ),
    ];
    return draft;
  });
  return newColumns;
};

/**
 * Gets the Columns from the Object structure.
 * @param {Array} initialFixedColumnTree: Column information.
 * @param {String} selectedDimension: Dimension selected to aggregate
 * @param {String} type: Used to identify draft creation based on key or value.
 */
const getColumnTreeFromDimension = (
  initialFixedColumnTree,
  selectedDimension,
  type,
) => {
  const newInitialFixedColumnTree = produce(initialFixedColumnTree, draft => {
    if (type === 'tree') {
      // eslint-disable-next-line no-param-reassign
      draft = [...draft.filter(column => column.key !== 'Number of agents')];
      if (selectedDimension !== 'agentName') {
        // eslint-disable-next-line no-param-reassign
        draft = [numberOfAgentsColumn, ...draft];
      }
    } else if (type === 'value') {
      // eslint-disable-next-line no-param-reassign
      draft = [...draft.filter(column => column !== 'Number of agents')];
      if (selectedDimension !== 'agentName') {
        // eslint-disable-next-line no-param-reassign
        draft = [numberOfAgentsColumn.key, ...draft];
      }
    }
    return draft;
  });
  return newInitialFixedColumnTree;
};

/**
 * Adds key and value in the Object.
 * @param {Array} list
 * @returns {Array} List of new modified objects.
 */
const createDimensionTree = list => {
  const newDimension = produce(list, draft => {
    // eslint-disable-next-line no-param-reassign
    draft = draft.map(sketch => ({
      ...sketch,
      key: sketch.field,
      value: sketch.field,
    }));
    return draft;
  });
  return newDimension;
};
export {
  setLocalStorageValue,
  getLocalStorageValue,
  getTemplateTree,
  getTree,
  getOptionTree,
  updateOptionTree,
  generateKeyValueObjectFromArray,
  getPreprocessedTableData,
  getColumn,
  getColumnDefaultValue,
  modifyInitialFilter,
  generateBasicColumnState,
  getColumnSetFromDimension,
  getColumnFromDimension,
  getColumnTreeFromDimension,
  createDimensionTree,
};
