/**
 * Cleans up SingleCall. so as to separate the top bar from the parent component
 * Component: SingleCallHeader
 * Date: 6/10/2022
 *
 */

import React from 'react';
// import styles from './style.css'
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';

import { Icon, Tooltip, Text, message } from 'components/common';
import DateUtils from 'utils/date';
import { dateTime, StringConstants } from 'utils/commonFunctions';
import Auth from 'auth0-react';
import { EXPORT_TRANSCRIPT_CALLS } from 'containers/SingleCall/constant';
import { mixpanelKeys } from 'utils/mixpanelKeys';
import mixpanel from 'utils/mixpanel';

import SkeletonLoader from '../SkeletonLoader';
const { isScopePresent } = new Auth();
const hasExportTranscriptCalls = isScopePresent(EXPORT_TRANSCRIPT_CALLS);

// if no URL present Fallback to the old state to use origin from browser.
const HOST_URL = process.env.REACT_APP_WEBSITE_URL || window.location.origin;

function SingleCallHeader({
  call,
  callId,
  downloadUrl,
  searchQuery,
  allMatches,
}) {
  const primaryTimezone =
    localStorage.getItem('timezone') || StringConstants.defaultTimezone;

  const handleOpenSCV = () => {
    const allMatchesParam = allMatches
      .map(stringToMatch => `allMatches=${stringToMatch}`)
      .join('&');
    window.open(
      `${HOST_URL}/calls/${callId}${
        searchQuery ? `?searchQuery=${searchQuery}&${allMatchesParam}` : ''
      }`,
    );
    mixpanel('New Window', {
      [mixpanelKeys.callId]: callId,
    });
  };

  return (
    <div className="single-call__call-detail-header">
      <span className="single-call__call-detail-header-name-span">
        <SkeletonLoader type="default">
          {call.direction && (
            <Tooltip
              title={
                call.direction === 'Inbound' ? 'Inbound call' : 'Outbound call'
              }
            >
              <Icon
                style={{
                  color: call.direction === 'Inbound' ? '#69A2CB' : '#FFA240',
                  marginTop: '5px',
                }}
                type={
                  call.direction === 'Inbound' ? 'inboundCall' : 'outboundCall'
                }
              />
            </Tooltip>
          )}
          {(call.agentName || call.agent?.name) && (
            <Text text={call.agentName || call.agent?.name} type="title" />
          )}
        </SkeletonLoader>
      </span>
      <SkeletonLoader type="default">
        {(call.calledAt || call.timestamp) && (
          <Text
            type="paragraph"
            text={dateTime(
              call.calledAt || call.timestamp,
              'dd MMM uuuu @ h:mma z',
              primaryTimezone,
            )}
          />
        )}
      </SkeletonLoader>

      <SkeletonLoader type="default">
        {(call.duration || call.audioDuration) && (
          <span style={{ padding: '0px 20px' }}>
            <Text
              text={DateUtils.formatDuration(
                call.duration || call.audioDuration,
              )}
              type="subtitle"
              className="bold"
            />
          </span>
        )}
      </SkeletonLoader>

      <SkeletonLoader type="default">
        {(call.agentName || call.agent?.name) && (
          <div className="open-call-redirect">
            <Tooltip title="Download audio file">
              <a
                disabled={!hasExportTranscriptCalls}
                className={
                  hasExportTranscriptCalls
                    ? 'enableDownloads'
                    : 'disableDownload'
                }
                onClick={() =>
                  mixpanel('Download', {
                    [mixpanelKeys.callId]: call.id,
                  })
                }
                href={downloadUrl}
                download
              >
                <Icon className="cursor-pointer" type="download" />
              </a>
            </Tooltip>
            <Tooltip title="Copy call link">
              <div>
                <CopyToClipboard
                  text={`${HOST_URL}/calls/${callId}`}
                  onCopy={() => {
                    mixpanel('Call link copied', {
                      [mixpanelKeys.callId]: call.id,
                    });
                    message.success('Link Copied to clipboard!!');
                  }}
                >
                  <Icon className="cursor-pointer" type="link" />
                </CopyToClipboard>
              </div>
            </Tooltip>
            {!window.location.pathname.includes('/calls/') ? (
              <Tooltip title="View Call">
                <div role="presentation" onClick={handleOpenSCV}>
                  <Icon
                    className="cursor-pointer"
                    type="external"
                    alt="new tab"
                  />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title="Cancel">
                <div
                  role="presentation"
                  onClick={e => {
                    e.preventDefault();
                    window.location.replace('/search-results');
                  }}
                >
                  <Icon
                    className="cursor-pointer"
                    type="closeOrange"
                    size={12}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </SkeletonLoader>
    </div>
  );
}

SingleCallHeader.propTypes = {
  call: PropTypes.object.isRequired,
  callId: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string.isRequired,
  searchQuery: PropTypes.string,
  allMatches: PropTypes.array.isRequired,
};

export default SingleCallHeader;
