import _ from 'lodash';

import { isTagPresent } from 'utils/tagUtils';

/**
 *
 * @param {array} callIds: call Ids to add and remove tags
 * @param {array} addTagList: tags to be added.
 * @param {array} removeTagList: tags to be removed
 * @param {array} calls: calls present
 * @param {array} allTags: all tags available
 * @param {array} updateCalls: array of updated calls.
 * @param {array} newTags: all new Tags to be considered.
 * @returns {array} array of updated calls.
 */
const getUpdatedCalls = (
  callIds,
  addTagList,
  removeTagList,
  calls,
  allTags,
  updateCalls,
  newTags,
  searchId,
  selectAll,
  API,
) => {
  if (getUpdatedCalls.length !== 10) {
    console.error('Arguments missing in function `getUpdatedCalls`');
    return {};
  }

  // as update calls need complete tags list instead of tag id, the response is different, also complete tags is received by add tags component and tags
  let data = {
    add_tags: updateCalls ? addTagList.map(tag => tag._id) : addTagList,
    remove_tags: updateCalls
      ? removeTagList.map(tag => tag._id)
      : removeTagList,
  };
  let api;
  if (selectAll) {
    data = {
      search_id: searchId,
      type: 'tags',
      ...data,
    };
    api = API.selectAll;
  } else {
    api = API.modifyTags;
    data = {
      call_ids: callIds,
      ...data,
    };
  }
  if (updateCalls) {
    // shifted this function from call search page to here so that this can be common and newCalls will be updated calls list
    let newCalls = _.cloneDeep(calls);
    // Iterate over all calls.
    newCalls = newCalls.map(callArg => {
      const call = _.cloneDeep(callArg);
      // filter out calls with the tags being present.
      call.tags = call.tags.filter(tag => !isTagPresent(removeTagList, tag));
      // if the present call Id is present in the array
      // check if the tag can be added or not, if tag is not present add from either, allTags list or add the newTag requested to be add.
      if (callIds.includes(call.callId)) {
        addTagList.forEach(newTag => {
          let tagFound = false;
          call.tags.forEach(tag => {
            if (tag._id === newTag._id) {
              tagFound = true;
            }
          });
          const oldTag = allTags.filter(f => f._id === newTag._id);
          if (!tagFound) {
            if (!oldTag.length) {
              call.tags.push(newTag);
            } else if (oldTag.length) {
              call.tags.push(...oldTag);
            }
          }
          tagFound = false;
        });
      }
      return call;
    });
    const newTagsList = [];
    // checks to see if new tags to be added are there in all tags, else update the same and add the tag.
    addTagList.forEach(newTag => {
      let tagFound = false;
      allTags.forEach(t => {
        if (t._id === newTag._id) {
          tagFound = true;
        }
      });
      if (!tagFound) {
        // newTagsList.push({ name: newTag.substring(newTag.indexOf("/") + 1, newTag.length), _id: newTag, type: 'user' })
        newTagsList.push(newTag);
      }
    });
    if (newTagsList.length)
      newCalls = newCalls.map(call => ({
        ...call,
        tags: call.tags.map(f =>
          newTagsList.find(k => k._id === f._id)
            ? { ...f, _id: `custom tag/${f._id}` }
            : f,
        ),
      }));
    // return the updated call list.
    return { updatedCalls: newCalls, data, api };
  }
  let updatedCalls = _.cloneDeep(calls);
  // add newTags to the callId present at 0th index of callIds.
  updatedCalls = updatedCalls.map(newCall => {
    if (newCall.callId === callIds[0]) {
      return {
        ...newCall,
        tags: newTags,
      };
    }
    return newCall;
  });

  // return the updated call list.
  return { updatedCalls, data, api };
};
export { getUpdatedCalls };
