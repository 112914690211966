import { getRequest, API } from 'utils/request';

import data from './mockData';

const fetchAllTags = async () => {
  const res = await getRequest(`${API.getTags}`);
  if (window.Cypress) {
    return data.unique_tags;
  }
  return res.data.data.unique_tags;
};

export { fetchAllTags };
