/**
 *
 * Component: Reactions
 * Date: 11/8/2020
 *
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Icon, Input, Text } from 'components/common';

import styles from './style.css';
const voteMap = {
  UP: 1,
  DOWN: -1,
  NONE: 0,
};

const reverseMap = {
  1: 'UP',
  '-1': 'DOWN',
  0: 'NONE',
};

function Reactions({ vote = 0, text, closable, onClose, onReaction }) {
  const [currentReaction, setCurrentReaction] = useState(voteMap[vote] || 0);
  const [showFeedbackSubmit, setShowFeedbackSubmit] = useState(
    text?.length > 0,
  );
  const feedbackInputRef = useRef();

  useEffect(() => {
    setShowFeedbackSubmit(text?.length > 0);
  }, [text]);

  /**
   * @param {Number} newState: switches from 0 to -1
   * @returns nothing but sets the reaction and feedbackSubmit.
   */
  const reacted = newState => {
    setCurrentReaction(newState);
    onReaction(
      reverseMap[newState],
      feedbackInputRef.current?.state?.value || text,
    );
    setShowFeedbackSubmit(
      (feedbackInputRef.current?.state?.value || text)?.length > 0,
    );
  };

  /**
   * Submits the Feeds on Click, and sets the reaction.
   * */
  const submitFeedback = () => {
    onReaction(
      reverseMap[currentReaction],
      feedbackInputRef.current?.state?.value || text,
    );
    setShowFeedbackSubmit(true);
  };

  return (
    // Prevent click propagation to avoid interaction with elements behind popover
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={styles.container} onClick={e => e.stopPropagation()}>
      <div className={styles.actionButtons}>
        <div className={styles.actionButton}>
          <Icon
            size={16}
            type={currentReaction === 1 ? 'thumbsUpFilled' : 'thumbsUp'}
            onClick={() => reacted(currentReaction === 1 ? 0 : 1)}
          />
          <Text text="Accurate" type="subtitleSmall" />
        </div>
        <div className={styles.actionButton}>
          <Icon
            size={16}
            type={currentReaction === -1 ? 'thumbsDownFilled' : 'thumbsDown'}
            onClick={() => reacted(currentReaction === -1 ? 0 : -1)}
          />
          <Text text="Inaccurate" type="subtitleSmall" />
        </div>
        {closable && onClose && (
          <div className={styles.actionButton}>
            <Icon size={16} type="deleteBlack" onClick={onClose} />
            <Text text="Delete Tag" type="subtitleSmall" />
          </div>
        )}
      </div>

      {currentReaction === -1 ? (
        <div className={styles.feedbackInput}>
          <Input
            type="text-area"
            maxLength={500}
            size="small"
            autoSize={{ minRows: 2, maxRows: 2 }}
            ref={feedbackInputRef}
            placeholder="Please describe what went wrong"
            defaultValue={text}
            disabled={showFeedbackSubmit}
          />
          <div className={styles.feedbackButtons}>
            {showFeedbackSubmit ? (
              <Icon
                className="cursor-pointer feedback-text-edit"
                onClick={() => setShowFeedbackSubmit(false)}
                type="edit"
              />
            ) : (
              <Icon
                className="cursor-pointer feedback-text-edit"
                onClick={submitFeedback}
                type="send"
                size={12}
              />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

Reactions.propTypes = {
  vote: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  onReaction: PropTypes.func.isRequired,
};

export default Reactions;
