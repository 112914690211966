/**
 *
 * FilterLayout
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/common';

import styles from './style.css';
// import styled from 'styled-components';

/**
 * UI component for rendering buttons related to clear, cancel or applying the filter along with the children passed.
 * */
const FilterLayout = ({ onCancel, onApply, onClear, ...rest }) => (
  <div className={styles.container}>
    <div className="filter-layout-filter-position">
      <div className={styles.filterBox}>
        {rest.children}
        <div className={styles.filterContainer}>
          {onClear && (
            <Button
              className={styles.clear}
              text="Clear"
              onClick={onClear}
              {...rest}
            />
          )}
          {onCancel && (
            <Button
              className={styles.cancel}
              text="Cancel"
              onClick={onCancel}
              {...rest}
            />
          )}
          {onApply && (
            <Button
              text="Apply"
              type="primary"
              onClick={onApply}
              {...rest}
              data-testid={`${rest['data-cypress']}-apply-button`}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

FilterLayout.propTypes = {
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
  onClear: PropTypes.func,
};

export default FilterLayout;
