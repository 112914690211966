/**
 * String a padded number string to tens place.
 * @param {Number} num
 * @returns {String}
 */
const paddedTwoDigits = num => num.toString().padStart(2, 0);

export default class DateUtils {
  /**
   * Date in proper format.
   * @param {Date} date: Date Object to operate on.
   * @returns {String}
   */
  static getFormattedDate({ date }) {
    if (!date) {
      return '';
    }
    return `${date.getFullYear()}-${paddedTwoDigits(
      date.getMonth() + 1,
    )}-${paddedTwoDigits(date.getDate())}`;
  }

  /**
   * Gets Formatted Time from Date Obj.
   * @param {Date} date: Date Object to operate on.
   * @returns {String}
   */
  static getFormattedTime({ date }) {
    if (!date) {
      return '';
    }
    return `${paddedTwoDigits(date.getHours())}:${paddedTwoDigits(
      date.getMinutes(),
    )}:${paddedTwoDigits(date.getSeconds())}`;
  }

  /**
   * Converts the seconds into hour, minutes and seconds and gets out remaining seconds.
   * @param {String} seconds: Seconds in String to operate on.
   * @returns {String}
   */
  static formatDuration(seconds) {
    const secNum = parseInt(seconds, 10); // don't forget the second param
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - hours * 3600) / 60);
    const remainingSeconds = secNum - hours * 3600 - minutes * 60;
    return `${(hours > 0 ? `${hours}h ` : '') +
      (minutes > 0 || hours > 0 ? `${minutes}m ` : '')}${remainingSeconds}s`;
  }

  static map(startDate, endDate, f) {
    const allInterval = [];
    if (!startDate || !endDate) {
      return allInterval;
    }
    for (
      let d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      allInterval.push(f(d));
    }
    return allInterval;
  }
}
