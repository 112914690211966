import { message } from 'antd';

message.config({
  maxCount: 1,
});

/**
 * Display the alert message
 * */
const showMessage = (messageType, type = 'success') => {
  switch (type) {
    case 'success':
      message.success(`${messageType}`);
      break;
    case 'error':
      message.error(`${messageType}`);
      break;
    default:
      message.info(`${messageType}`);
      break;
  }
};

export default message;
export { showMessage };
