/**
 *
 * Component: VisualConfig
 * Date: 6/7/2020
 *
 */

import React, { useRef, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

// import message from 'utils/message';
import { changeIndexOfArray } from 'utils/commonFunctions';
import { Input, Card, Text, Button } from 'components/common';
import { notificationError } from 'utils/notification';

import LoadingIndicator from '../LoadingIndicator';
import VisualConfigCard from '../VisualConfigCard';
import style from './style.css';

const { Validator } = require('jsonschema');
const v = new Validator();
const defaultVisualConfig = {
  id: 'defaultId',
  isPublic: false,
  name: 'defaultName',
  thumbnail: '',
  thumbnailUrl: null,
};
const defaultConfig = {
  site: '',
  user: '',
  visualsList: [],
  _id: 'visualsConfiguration',
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line sonarjs/cognitive-complexity
const VisualConfig = ({ configs, loading, modifyConfig, fetchList }) => {
  const configRef = useRef(configs?.config || defaultConfig);
  const [config, setConfig] = useState(configs?.config || {});
  const [acceptDropList, setAcceptDropList] = useState([]);
  const listEndRef = useRef();
  const itemAdded = useRef(false);

  useEffect(() => {
    const clonedConfig = _.cloneDeep(configRef.current);
    const newAcceptDropList =
      clonedConfig?.visualsList?.map(vis => vis.id) || [];
    setAcceptDropList(newAcceptDropList);
  }, [config]);

  useEffect(() => {
    if (configs?.config) {
      setConfig(configs?.config);
      configRef.current = configs?.config;
    }
  }, [configs?.config, setConfig]);

  const scrollIntoView = () => {
    if (listEndRef.current)
      listEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
  };

  useEffect(() => {
    if (itemAdded.current) {
      scrollIntoView();
      itemAdded.current = false;
    }
  }, [config]);

  const handleDrop = (dragIndex, dropIndex) => {
    /**
     *to change order of visual list elements
     */
    if (configRef.current.visualsList) {
      configRef.current.visualsList = changeIndexOfArray(
        configRef.current.visualsList,
        dragIndex,
        dropIndex,
      );
      setConfig(_.cloneDeep(configRef.current));
    }
  };

  const addNewButton = () => {
    if (itemAdded.current === false) {
      if (configRef.current?.visualsList?.length) {
        configRef.current.visualsList.push({ ...defaultVisualConfig });
      } else {
        /**
         * if no entry is present than creating a default state for visualList
         * */
        configRef.current.visualsList = [{ ...defaultVisualConfig }];
      }
      setConfig(_.cloneDeep(configRef.current));
      itemAdded.current = true;
    }
  };

  /**
   * if pressed multiple times, debounce for 1 seconds
   */
  const handleSave = () => {
    const validation = v.validate(configRef.current, configs.configSchema);
    if (validation.valid) {
      const refConfig = configRef.current;
      setConfig(refConfig);
      modifyConfig(refConfig);
    } else {
      notificationError(validation.errors && validation.errors[0].stack);
    }
  };

  const deleteVisual = id => {
    const newConfig = configRef.current;
    const newVisualIndex = newConfig.visualsList.findIndex(
      vis => vis.id === id,
    );
    if (newVisualIndex >= 0) {
      newConfig.visualsList.splice(newVisualIndex, 1);
    }
    setConfig(_.cloneDeep(newConfig));
  };

  const handleRefChange = visualListIndex => (e, type) => {
    const newVisualState = configRef.current.visualsList[visualListIndex];
    newVisualState[type] = e.target.value;
  };

  const getConfigCardsJSX = useCallback(
    () =>
      config &&
      !_.isEmpty(acceptDropList) &&
      config?.visualsList?.map((visual, index) => (
        <VisualConfigCard
          // eslint-disable-next-line react/no-array-index-key
          key={`${visual.id}-${index}`}
          handleDrop={handleDrop}
          acceptDropList={acceptDropList}
          deleteVisual={deleteVisual}
          visual={visual}
          visualIndex={index}
          handleRefChange={handleRefChange(index)}
          fetchList={fetchList}
        />
      )),
    [configs, config, configRef, acceptDropList],
  );

  if (loading && _.isEmpty(configRef?.current?.visualsList))
    return <LoadingIndicator />;
  return (
    <div className={style.container}>
      <div className={style.configHeader}>
        <Card>
          <div className={style.visualDetails}>
            <div>
              <Text text="Site" type="label" />
              <Input
                type="input"
                onChange={e => {
                  configRef.current.site = e.target.value;
                }}
                defaultValue={configs?.config?.site || ''}
              />
            </div>
            <div>
              <Text text="User" type="label" />
              <Input
                type="input"
                onChange={e => {
                  configRef.current.user = e.target.value;
                }}
                defaultValue={configs?.config?.user || ''}
              />
            </div>
          </div>
        </Card>
      </div>

      <div className={style.visualConfigBody}>
        {getConfigCardsJSX()}
        <hr ref={listEndRef} className={style.lineRuler} />
      </div>
      <div className={style.actionButtons}>
        <Button
          type="link-primary"
          onClick={addNewButton}
          icon="plus"
          text="Add new"
        />
        <Button
          type="primary"
          loading={loading}
          onClick={!loading ? handleSave : null}
          icon="save"
          text="Save"
        />
      </div>
    </div>
  );
};

VisualConfig.propTypes = {
  configs: PropTypes.object,
  loading: PropTypes.bool,
  modifyConfig: PropTypes.func,
  fetchList: PropTypes.func,
};

export default VisualConfig;
