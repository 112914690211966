/* cspell: ignore sider */
// Individual exports for testing
// import { call, put, select, takeLatest } from 'redux-saga/effects';
// import { LOAD_REPOS } from 'pages/App/constants';
// import { getRequest, API } from 'utils/request';
// import makeSelectLogin from 'pages/Login/selectors';
// import action from './actions';
// import constant from './constants';

/**
 * Github repos request/response handler
 */
// export function* fetchVisuals() {
//   try {
//     // Call our request helper (see 'utils/request')
//     const res = yield call(getRequest, API.getVisualizations);
//     yield put(action('FETCHED_VISUALS', res.data));
//   } catch (err) {
//     yield put(action('ERROR_FETCHING_VISUALS', err));
//   }
// // }
// export function* fetchAgentVisuals() {
//   try {
//     // Call our request helper (see 'utils/request')
//     const res = yield call(getRequest, API.getAgentVisualizations);
//     yield put(action('FETCHED_AGENT_VISUALS', res.data));
//   } catch (err) {
//     yield put(action('ERROR_FETCHING_AGENT_VISUALS', err));
//   }
// }

// export function* fetchLeftNavConfig() {
//   try {
//     // Call our request helper (see 'utils/request')
//     const res = yield call(getRequest, API.getLeftNavConfig);
//     yield put(action('FETCHED_LEFT_NAV_CONFIG', res.data));
//   } catch (err) {
//     yield put(action('ERROR_FETCHING_LEFT_NAV_CONFIG', err));
//   }
// }

// export function* fetchSearchFilters() {
//   try {
//     // Call our request helper (see 'utils/request')
//     const res = yield call(getRequest, API.getListsForSearchPage);
//     yield put(action('FETCHED_SEARCH_FILTERS', res.data.templateIdsList));
//   } catch (err) {
//     yield put(action('ERROR_FETCHING_SEARCH_FILTERS', err));
//   }
// }

/**
 * Root saga manages watcher lifecycle
 */
export default function* siderSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  // yield takeLatest(constant('FETCH_LEFT_NAV_CONFIG'), fetchLeftNavConfig);
  // // yield takeLatest(constant('FETCH_VISUALS'), fetchVisuals);
  // yield takeLatest(constant('FETCH_AGENT_VISUALS'), fetchAgentVisuals);
  // yield takeLatest(constant('FETCH_SEARCH_FILTERS'), fetchSearchFilters);
}
