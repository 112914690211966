/**
 *
 * PopConfirm
 *
 */

import React from 'react';
import { Popconfirm as CustomPopConfirm } from 'antd';

import './style.less';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
function PopConfirm({ ...rest }) {
  return <CustomPopConfirm {...rest} />;
}

PopConfirm.propTypes = {};

export default PopConfirm;
