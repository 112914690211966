/* eslint-disable no-param-reassign */
/**
 *
 * Component: TagBox
 * Date: 10/9/2022
 *
 */

import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  START_SUBTRACTION_AFTER_HEIGHT,
  FIXED_HEIGHT_TAG_BOX,
  FIXED_LENGTH_DIFFERENCE,
  PLUS_N_TOOLTIP_DELAY,
  DEBOUNCE_TIME_DELAY,
  EXPANDED_TAG_BOX_FIXED_HEIGHT_DIFFERENCE,
  COLLAPSED_TAG_BOX_FIXED_HEIGHT_DIFFERENCE,
} from 'utils/commonConstants';
import Auth from 'auth0-react';
import { Tooltip, Popover, Icon, Tag } from 'components/common';
import {
  getSubtractableTagBoxHeight,
  sortTags,
  tagAppliedMixpanelEvent,
} from 'utils/commonFunctions';
import AddTags from 'containers/AddTags';
import SkeletonLoader from 'components/SkeletonLoader';
import Reactions from 'components/Reactions';
import useWindowSize from 'utils/hooks/useWindowSize';

import styles from './style.css';

const { isScopePresent } = new Auth();
const hasFeedbackTags = isScopePresent('feedback_tags');
const hasDeleteCustomTagsScope = isScopePresent('delete_custom_tags');
const hasDeleteSystemTagsScope = isScopePresent('delete_system_tags');
const TagBox = ({
  call,
  callId,
  tags,
  handleReaction,
  onApplyTags,
  analyticsFeedback = [],
  loading,
  addTagsPlacement,
  showTagsControl,
  dimensions = { height: 0, width: 0 },
  showMore,
  setShowMore,
  metaDataRef,
  showExtraMetaData,
  setShowTagToolTipMessage,
  showTagToolTipMessage,
}) => {
  const tagBoxRef = useRef();
  const { height: searchDetailHeight, width: searchDetailWidth } = dimensions;
  const tagListRef = useRef([]);
  const uniqueRowsRef = useRef([]);
  const hiddenTagsCount = useRef(0);
  const expandedTagBoxRef = useRef();
  const [hiddenTagCount, setHiddenCount] = useState(0);
  const [toolTipInfo, setToolTipInfo] = useState('');
  const lastShowMoreStateRef = useRef(false);

  const { height: windowHeight, width: windowWidth } = useWindowSize();

  useEffect(() => {
    setShowMore(false);
    lastShowMoreStateRef.current = false;
  }, [callId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTagToolTipMessage(false);
    }, PLUS_N_TOOLTIP_DELAY);
    return () => clearTimeout(timer);
  }, [showTagToolTipMessage]);

  useEffect(() => {
    if (
      expandedTagBoxRef.current &&
      metaDataRef.current?.getBoundingClientRect()?.height
    ) {
      expandedTagBoxRef.current.style.maxHeight = `calc(100vh - ${metaDataRef.current.getBoundingClientRect()
        .height + EXPANDED_TAG_BOX_FIXED_HEIGHT_DIFFERENCE}px)`;
    }
  }, [showExtraMetaData, showMore]);

  const handleCallReactions = (reactionType, id) => {
    lastShowMoreStateRef.current = showMore;
    return handleReaction(reactionType, id);
  };

  useEffect(() => {
    setShowMore(lastShowMoreStateRef.current);
  }, [call]);

  /**
   * Function hides all the tag that do not fit the Fixed width provided
   */
  const handleWidthAndHeightChange = () => {
    uniqueRowsRef.current = [];
    hiddenTagsCount.current = 0;
    setHiddenCount(0);
    if (tagListRef.current && tagListRef.current.length > 0) {
      /**
       * Makes all the tags such so that the hidden logic and count works properly
       */

      tagListRef.current.forEach(eachTag => {
        if (eachTag?.style) {
          eachTag.style.display = 'inline-block';
        }
      });
      hiddenTagsCount.current = 0;
      if (tagBoxRef.current) {
        /**
         * Scroll to top helps helps avoid cases. When only visible tags width is calculated instead of tags from start
         */
        tagBoxRef.current.scrollTop = 0;
      }

      /**
       *Makes an array of the bottoms of tags and rounds it off as well
       */

      tagListRef.current.forEach(eachTag => {
        if (
          !uniqueRowsRef.current.includes(
            Math.floor(eachTag?.getBoundingClientRect().bottom),
          ) &&
          eachTag?.getBoundingClientRect().bottom !== 0
        )
          uniqueRowsRef.current.push(
            Math.floor(eachTag?.getBoundingClientRect().bottom),
          );
      });

      if (!showMore) {
        let lastRowAccumulatedTagWidth = 0;
        tagListRef.current.forEach(eachTag => {
          /**
           * finds the last bottom that fits inside the Tag box
           */
          const lastBottomInsideTagBox = uniqueRowsRef.current
            .sort((a, b) => a - b)
            .reverse()
            .find(e => e <= tagBoxRef.current?.getBoundingClientRect()?.bottom);

          /**
           * Bottom of each Tag
           */
          const eachTagBottom = Math.floor(
            eachTag?.getBoundingClientRect().bottom,
          );
          /**
           * Hides all tags that fall outside of available space
           */

          if (eachTagBottom > lastBottomInsideTagBox && eachTag) {
            eachTag.style.display = 'none';
            hiddenTagsCount.current += 1;
            return;
          }

          if (eachTagBottom + 5 >= lastBottomInsideTagBox) {
            /**
             * Identifies if the tag falls in the last Row
             * if it, adds to the values of last accumulated width
             */

            if (
              lastRowAccumulatedTagWidth <
              tagBoxRef.current?.getBoundingClientRect()?.width -
                FIXED_LENGTH_DIFFERENCE
            ) {
              /**
               * Conditions Makes sure that subtraction only happens when the height is above the limit and we want to hide tags
               */
              if (
                tagBoxRef.current?.getBoundingClientRect()?.height >
                  getSubtractableTagBoxHeight() ||
                window.innerHeight < START_SUBTRACTION_AFTER_HEIGHT
              ) {
                lastRowAccumulatedTagWidth +=
                  eachTag.getBoundingClientRect()?.width || 0;
              }
              if (eachTag?.style) {
                eachTag.style.display = 'inline-block';
              }
            } else if (eachTag?.style) {
              eachTag.style.display = 'none';
              hiddenTagsCount.current += 1;
            }
          }
        });
      }
    } else {
      hiddenTagsCount.current = 0;
    }
    setHiddenCount(hiddenTagsCount.current);
  };

  /**
   * Function will only be called one the difference between two calls is more than
   */

  const debouncedHandleHeightAndWidthChanges = useCallback(
    _.debounce(handleWidthAndHeightChange, DEBOUNCE_TIME_DELAY),
    [],
  );

  /**
   * handles the case when, Function call is needed instantly, So that changes don't feel slow
   */

  useEffect(() => {
    handleWidthAndHeightChange(tagBoxRef, tagListRef, uniqueRowsRef);
  }, [showMore, showExtraMetaData, call]);

  useLayoutEffect(() => {
    debouncedHandleHeightAndWidthChanges(tagBoxRef, tagListRef, uniqueRowsRef);
  }, [searchDetailHeight, searchDetailWidth, windowHeight, windowWidth]);

  const getNonScrollableContainerMaxHeight = () =>
    window.innerHeight - COLLAPSED_TAG_BOX_FIXED_HEIGHT_DIFFERENCE <
    FIXED_HEIGHT_TAG_BOX
      ? `${window.innerHeight - COLLAPSED_TAG_BOX_FIXED_HEIGHT_DIFFERENCE}px`
      : `${FIXED_HEIGHT_TAG_BOX}px`;

  const tagList = useMemo(() => {
    const getColor = (tagHasColor, tagType = 'user') => {
      if (tagHasColor) return tagHasColor;
      return tagType === 'user' ? 'orange' : 'blue';
    };

    const deletableTags = typeArg => {
      if (typeArg === 'user' && hasDeleteCustomTagsScope) return true;
      if (typeArg === 'system' && hasDeleteSystemTagsScope) return true;
      return false;
    };

    /**
     * A Tooltip like PopOver Wrapper, for reactions.
     * */
    const getReactionPopoverWrapper = (tag, tagNode) => {
      // Show reactions only if user can modify tags
      if (hasFeedbackTags) {
        return (
          <Popover
            content={
              <Reactions
                {...getReactionData(tag._id)}
                onReaction={handleCallReactions('Tag', tag._id)}
                closable={deletableTags(tag.type)}
                onClose={() => {
                  tagAppliedMixpanelEvent({
                    itemIds: [call.id],
                    deletedTag: tag,
                    allTags: tags,
                  });
                  onApplyTags([call.id], [], [tag], true);
                }}
              />
            }
          >
            {tagNode}
          </Popover>
        );
      }
      return tagNode;
    };
    const getReactionIcon = id => {
      const reactionIconMap = {
        UP: <Icon size={10} type="thumbsUpFilled" />,
        DOWN: <Icon size={10} type="thumbsDownFilled" />,
        NONE: null,
      };
      const idx = analyticsFeedback.findIndex(
        feedback => feedback.capsule?.name === id,
      );
      if (idx !== -1) return reactionIconMap[(analyticsFeedback[idx]?.vote)];
      return reactionIconMap.NONE;
    };
    const getReactionData = id => {
      const idx = analyticsFeedback.findIndex(
        feedback => feedback.capsule?.name === id,
      );
      if (idx !== -1) return analyticsFeedback[idx];
      return {};
    };
    tagListRef.current = [];

    /**
     * Adds Refs to each element to change display props and get positions
     */

    return sortTags(tags).map((tag, index) => (
      <div
        key={tag._id}
        className="padding-1"
        ref={ele => {
          tagListRef.current[index] = ele;
        }}
      >
        {getReactionPopoverWrapper(
          tag,
          <Tag
            key={tag._id}
            color={getColor(tag.color, tag.type)}
            className={` ${styles.eachTag} ${
              tag.type === 'system' ? 'system-tag' : null
            }`}
            visible={tag.type !== 'hidden'}
          >
            {`${tag.name}   `}
            <div className={styles.tagReactionIcon}>
              {getReactionIcon(tag._id)}
            </div>
          </Tag>,
        )}
      </div>
    ));
  }, [JSON.stringify(tags), analyticsFeedback]);

  const memoizedNonScrollableTagBox = useMemo(
    () => (
      <div
        className={`${styles.containerNonScrollable} tagBoxContainer`}
        ref={tagBoxRef}
        style={{
          maxHeight: getNonScrollableContainerMaxHeight(),
        }}
      >
        <div className={styles.tagsListContainer}>
          <SkeletonLoader type="tags" repeat={1} loading={loading}>
            {tagList}
          </SkeletonLoader>
          {hiddenTagCount > 0 && !showMore ? (
            <Tooltip title={toolTipInfo || ''} visible={showTagToolTipMessage}>
              <Tag
                onClick={() => {
                  setShowMore(true);
                }}
                className={styles.showMoreButton}
              >
                {`+ ${hiddenTagCount}`}
              </Tag>
            </Tooltip>
          ) : (
            <></>
          )}
          {!loading && showTagsControl ? (
            <AddTags
              onApplyTags={onApplyTags}
              selectedItems={[call]}
              calls={[call]}
              placement={addTagsPlacement}
              setToolTipInfo={setToolTipInfo}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    ),
    [tagList, hiddenTagCount, showTagsControl, showTagToolTipMessage],
  );

  return showMore ? (
    <div className={styles.containerScrollable} ref={expandedTagBoxRef}>
      <div className={styles.tagsListContainer}>
        <SkeletonLoader type="tags" repeat={1} loading={loading}>
          {tagList}
        </SkeletonLoader>
        <Tag
          color="#008AF5"
          onClick={() => {
            setShowMore(false);
          }}
          className={styles.showLessButton}
        >
          Show Less
        </Tag>

        {!loading && showTagsControl ? (
          <AddTags
            onApplyTags={onApplyTags}
            selectedItems={[call]}
            calls={[call]}
            placement={addTagsPlacement}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    memoizedNonScrollableTagBox
  );
};

TagBox.propTypes = {
  call: PropTypes.object,
  callId: PropTypes.string,
  tags: PropTypes.array.isRequired,
  onApplyTags: PropTypes.func.isRequired,
  handleReaction: PropTypes.func,
  analyticsFeedback: PropTypes.array,
  loading: PropTypes.bool,
  addTagsPlacement: PropTypes.string,
  showTagsControl: PropTypes.bool,
  dimensions: PropTypes.object,
  showMore: PropTypes.bool,
  setShowMore: PropTypes.func,
  metaDataRef: PropTypes.object,
  showExtraMetaData: PropTypes.bool,
  setShowTagToolTipMessage: PropTypes.func,
  showTagToolTipMessage: PropTypes.bool,
};

export default TagBox;
