/**
 * TODO: There is no difference between lambda and region URl map, and can be combined.
 * This will require destructuring API server endpoint from getUrlByRegion method
 */
const regionUrlMap = {
  UK: process.env.REACT_APP_API_BASE_URL_UK,
  CANADA: process.env.REACT_APP_API_BASE_URL_CANADA,
  US: process.env.REACT_APP_API_BASE_URL_US,
};
const lambdaUrlMap = {
  US: {
    CLIENT_ADMIN_PANEL: process.env.REACT_APP_CLIENT_ADMIN_PANEL_API_US,
  },
  CANADA: {
    CLIENT_ADMIN_PANEL: process.env.REACT_APP_CLIENT_ADMIN_PANEL_API_CANADA,
  },
  UK: {
    CLIENT_ADMIN_PANEL: process.env.REACT_APP_CLIENT_ADMIN_PANEL_API_UK,
  },
};
const regionWSUrlMap = {
  UK: process.env.REACT_APP_WEBSOCKET_URL_UK,
  CANADA: process.env.REACT_APP_WEBSOCKET_URL_CANADA,
  US: process.env.REACT_APP_WEBSOCKET_URL_US,
};
const UNEXPECTED_LOGIN_ERROR = 'UNEXPECTED_LOGIN_ERROR';
/**
 * List of words where adding a "s" suffix does not makes sense, Non Plural words
 */
const NON_PLURAL_WORDS_FOR_TOOLTIPS = [
  'duration',
  'direction',
  '%',
  'failed',
  'approved',
  'by',
  'score',
  'ids',
  'tags',
];

const BUTTON_TOOLTIP_DELAY = 0.75;
const ICON_TOOLTIP_DELAY = 0.5;
const TEXT_TOOLTIP_DELAY = 0.5;
const SCROLLABLE_TOOLTIP_CLASS = 'scrollableTooltip';
const BUTTON_TOOLTIP = 'BUTTON_TOOLTIP';
const TEXT_TOOLTIP = 'TEXT_TOOLTIP';
const STYLED_TEXT_TOOLTIP = 'STYLED_TEXT_TOOLTIP';
const ICON_TOOLTIP = 'ICON_TOOLTIP';
const PRODIGAL_LOGO_DARK =
  'https://public.prodigaltech.com/prodigal-logo-dark.svg';

const PRODIGAL_LOGO_LIGHT =
  'https://public.prodigaltech.com/prodigal-logo-light.svg';

const REMOVE_NODES_ON_SCROLL_DELAY = 16;
const CUSTOM_TAG = 'custom tag/';

const FIXED_HEIGHT_TAG_BOX = 115;

const FIXED_LENGTH_DIFFERENCE = 420;
/**
 * Pass new Dimensions Every 200ms to Child components as Props
 */
const DIMENSION_PROPAGATION_RATE = 500;

/**
 * Tooltip delay when a Tag operation is done via the AddTagPopover
 */
const PLUS_N_TOOLTIP_DELAY = 1000;

/**
 * Enables Subtraction for all the rows below the inner height of the const defined here
 * Subtracted place will be give to "+n" and Add tag button
 */
const START_SUBTRACTION_AFTER_HEIGHT = 400;

/**
 * values calculated based on the fixed height of note section and Transcript sections
 */
const EXPANDED_TAG_BOX_FIXED_HEIGHT_DIFFERENCE = 340;
const COLLAPSED_TAG_BOX_FIXED_HEIGHT_DIFFERENCE = 305;
/**
 * debounce Time delay for handleHeightAndWidthChanges in Tag box
 * */
const DEBOUNCE_TIME_DELAY = 200;

const NON_SUBTRACTABLE_TAG_BOX_HEIGHT = 90;

const WEEK_DAY_INITIALS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const DAY_NUMBER_BOUNDS = {
  MIN_MONTH: 1,
  MAX_MONTH: 31,
  MIN_WEEK: 1,
  MAX_WEEK: 7,
};

const REGEX_SEARCH_ERROR_MESSAGE = 'Regex searches are not allowed';

export {
  lambdaUrlMap,
  regionUrlMap,
  regionWSUrlMap,
  UNEXPECTED_LOGIN_ERROR,
  NON_PLURAL_WORDS_FOR_TOOLTIPS,
  BUTTON_TOOLTIP_DELAY,
  ICON_TOOLTIP_DELAY,
  TEXT_TOOLTIP_DELAY,
  PRODIGAL_LOGO_DARK,
  PRODIGAL_LOGO_LIGHT,
  SCROLLABLE_TOOLTIP_CLASS,
  BUTTON_TOOLTIP,
  TEXT_TOOLTIP,
  ICON_TOOLTIP,
  REMOVE_NODES_ON_SCROLL_DELAY,
  CUSTOM_TAG,
  FIXED_HEIGHT_TAG_BOX,
  FIXED_LENGTH_DIFFERENCE,
  DIMENSION_PROPAGATION_RATE,
  PLUS_N_TOOLTIP_DELAY,
  START_SUBTRACTION_AFTER_HEIGHT,
  EXPANDED_TAG_BOX_FIXED_HEIGHT_DIFFERENCE,
  COLLAPSED_TAG_BOX_FIXED_HEIGHT_DIFFERENCE,
  NON_SUBTRACTABLE_TAG_BOX_HEIGHT,
  DEBOUNCE_TIME_DELAY,
  WEEK_DAY_INITIALS,
  DAY_NUMBER_BOUNDS,
  REGEX_SEARCH_ERROR_MESSAGE,
  STYLED_TEXT_TOOLTIP,
};
