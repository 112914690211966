// cspell: ignore DDTHH
/* eslint-disable no-underscore-dangle */
/*
 *
 * ReviewScorecardList reducer
 *
 */
import produce from 'immer';
import _ from 'lodash';
import moment from 'moment';

import { IsJsonString, scorecardList } from 'utils/commonFunctions';
import { notifyUser } from 'utils/notification';

import * as constant from './constants';
const {
  setLocalStorageValue,
  getLocalStorageValue,
  getTemplateTree,
  getOptionTree,
  updateOptionTree,
  generateKeyValueObjectFromArray,
  getPreprocessedTableData,
  getColumn,
  modifyInitialFilter,
  generateBasicColumnState,
} = scorecardList;

// 220 is commutative pixels size other than table rows, 39 is per row height in pixel
// tablePageSize is number of rows we can have with current height of browser
const tablePageSize = Math.floor((window.innerHeight - 220) / 39);
const locallyStoredTemplateId = getLocalStorageValue('scorecardTemplateId');
let templateId = [];
if (locallyStoredTemplateId) {
  templateId = IsJsonString(locallyStoredTemplateId)
    ? JSON.parse(locallyStoredTemplateId)
    : [locallyStoredTemplateId] || [];
}
// TODO: change functions to const to make it less probable to overwrite functions with same name
// TODO: move local storage outside reducer since it's a side effect

export const initialState = {
  filters: [],
  selectedFilters: [
    {
      field: 'callTime',
      condition: 'between',
      value: [
        moment()
          .startOf('month')
          .format('YYYY-MM-DDTHH:mm:ss'),
        moment()
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
      ],
    },
  ],
  appliedFilters: [],
  scorecardList: [],
  columns: [],
  selectedScorecard: [],
  scorecardTemplate: typeof templateId === 'string' ? [templateId] : templateId,
  limit: tablePageSize,
  skip: 0,
  sortFieldList: [{ field: 'createdAt', order: -1 }],
  columnsNameMap: {},
  columnOptionTree: [],
  selectedColumnTree:
    typeof templateId === 'string'
      ? JSON.parse(
          getLocalStorageValue(
            `selectedColumnTree_${_.sortBy([templateId])}`,
          ) || JSON.stringify([]),
        )
      : JSON.parse(
          getLocalStorageValue(`selectedColumnTree_${_.sortBy(templateId)}`) ||
            JSON.stringify([]),
        ),
  dataSource: [],
  filterChanged: false,
  searchId: '',
  totalCount: null,
  selectedRows: [],
  selectAll: false,
  initialFixedColumn: [],
  initialFixedColumnTree: [],
  initialColumn: [],
  basicInitialColumn: [],
  loadingExport: false,
  scorecardTemplates: [],
  pageNo: 1,
  loadingList: true,
  forceLoadByAggregatedScorecard: false,
};
const scorecardTemplateList = {};
/* eslint-disable default-case, no-param-reassign */
const reviewScorecardListReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constant.CANCEL_SEARCH:
        draft.searchId = '';
        draft.loadingList = false;
        draft.error = false;
        break;
      case constant.SET_SEARCH_ID:
        draft.searchId = action.payload;
        break;
      case constant.FETCHED_FILTERS: {
        const filters = modifyInitialFilter(action.payload);
        draft.filters = {
          ...filters,
          advancedFilters: action.payload.advancedFilters
            ? action.payload.advancedFilters
            : [],
        };
        if (action.payload?.displayFields) {
          const newBasicInitialColumn = generateBasicColumnState(
            action.payload?.displayFields,
            scorecardTemplateList,
          );
          draft.basicInitialColumn = newBasicInitialColumn;

          draft.initialFixedColumnTree = _.cloneDeep(
            newBasicInitialColumn.map(p =>
              getOptionTree(p.dataIndex, p.title, p),
            ),
          );
          const newInitialColumn = newBasicInitialColumn.map(i => i.dataIndex);
          if (getLocalStorageValue('initialColumn')) {
            let initialColumn = JSON.parse(
              getLocalStorageValue('initialColumn'),
            );
            initialColumn = initialColumn.filter(initialColumnArg =>
              newInitialColumn.includes(initialColumnArg),
            );
            setLocalStorageValue(
              'initialColumn',
              JSON.stringify(initialColumn),
            );
            draft.initialFixedColumn = initialColumn;
            const initialColumnState = newBasicInitialColumn.filter(
              newBasicArg => initialColumn.includes(newBasicArg.dataIndex),
            );
            draft.initialColumn = _.cloneDeep(initialColumnState);
          } else {
            draft.initialFixedColumn = newInitialColumn;
            draft.initialColumn = _.cloneDeep(newBasicInitialColumn);
          }
        }
        if (state.dataSource?.length) {
          draft.columns = getColumn(
            draft.initialColumn,
            draft.basicInitialColumn,
            generateKeyValueObjectFromArray(state.selectedColumnTree),
            state.dataSource,
            scorecardTemplateList,
          );
          draft.sortList = getColumn(
            draft.initialColumn,
            draft.basicInitialColumn,
            undefined,
            undefined,
            scorecardTemplateList,
          );
        }
        break;
      }
      case constant.FETCHING_SCORECARD_SEARCH:
        draft.loadingList = true;
        break;
      case constant.ERROR_FETCHING_SCORECARD_SEARCH:
        draft.loadingList = false;
        draft.totalCount = 0;
        break;
      case constant.FETCHED_SCORECARD_SEARCH: {
        if (action.payload.searchId === state.searchId) {
          if (!_.isEmpty(action.payload.responses)) {
            const dataSource = getPreprocessedTableData(
              action.payload.responses,
            );
            draft.dataSource = _.cloneDeep(dataSource);
            draft.columns = getColumn(
              state.initialColumn,
              state.basicInitialColumn,
              generateKeyValueObjectFromArray(state.selectedColumnTree),
              dataSource,
              scorecardTemplateList,
            );
            draft.sortList = getColumn(
              state.initialColumn,
              state.basicInitialColumn,
              undefined,
              undefined,
              scorecardTemplateList,
            );
            const columnOptionTree = updateOptionTree(
              action.payload.responses[0],
            );
            draft.columnOptionTree = _.cloneDeep(columnOptionTree);
          } else {
            draft.dataSource = [];
          }
          draft.scorecardList = action.payload.responses;
          draft.totalCount = action.payload.totalCount;
          if (state.selectAll) {
            draft.selectedRows = draft.dataSource;
          }
          draft.loadingList = false;
          notifyUser(
            'Search complete!',
            {
              onclick: e => {
                e.preventDefault();
                window.focus();
              },
              body:
                'Review Scorecards have been prepared. Click here to review.',
            },
            true,
          );
          draft.forceLoadByAggregatedScorecard = false;
        }
        break;
      }
      case constant.SET_COLUMN_NAME_MAP: {
        draft.columns = getColumn(
          state.initialColumn,
          state.basicInitialColumn,
          generateKeyValueObjectFromArray(state.selectedColumnTree),
          state.dataSource,
          scorecardTemplateList,
        );
        draft.sortList = getColumn(
          state.initialColumn,
          state.basicInitialColumn,
          undefined,
          undefined,
          scorecardTemplateList,
        );
        break;
      }
      case constant.SET_COLUMNS:
        draft.columns = action.payload;
        break;
      case constant.HANDLE_CHANGE_COLUMN_TREE: {
        const selectedColumnTree = setLocalStorageValue(
          `selectedColumnTree_${_.sortBy(state.scorecardTemplate)}`,
          JSON.stringify(action.payload),
        );
        draft.selectedColumnTree = selectedColumnTree;
        draft.columns = getColumn(
          state.initialColumn,
          state.basicInitialColumn,
          generateKeyValueObjectFromArray(action.payload),
          state.dataSource,
          scorecardTemplateList,
        );
        draft.sortList = getColumn(
          state.initialColumn,
          state.basicInitialColumn,
          undefined,
          undefined,
          scorecardTemplateList,
        );
        break;
      }
      case constant.HANDLE_SELECTED_FILTER_KEY:
        draft.showFilterKey = action.payload;
        break;
      case constant.HANDLE_NEW_FILTERS:
        draft.selectedFilters = action.payload;
        break;
      case constant.HANDLE_CHANGE_SORT_ORDER:
        draft.sortFieldList = [
          {
            field:
              action.payload.field === 'callDirection'
                ? 'isDirOutbound'
                : action.payload.field,
            order: action.payload.order,
          },
        ];
        draft.skip = 0;
        draft.pageNo = 1;
        break;
      case constant.SET_SCORECARDS:
        draft.dataSource = [];
        draft.totalCount = null;
        draft.skip = 0;
        break;
      case constant.SET_SELECT_ALL:
        draft.selectAll = action.payload;
        break;
      case constant.SET_SELECTED_ROWS:
        draft.selectedRows = action.payload;
        break;
      case constant.CLEAR_SEARCH:
        // TODO: not sure why we need to use cloneDeep look again
        draft.selectedFilters = _.cloneDeep(initialState.selectedFilters);
        break;
      case constant.FETCHED_SCORECARD_TEMPLATES:
        draft.scorecardTemplates = action.payload.map(template =>
          getTemplateTree(template),
        );
        action.payload.forEach(
          // eslint-disable-next-line no-return-assign
          template => (scorecardTemplateList[template._id] = template.name),
        );
        if (
          action.payload &&
          action.payload[0] &&
          !state.scorecardTemplate?.length
        ) {
          if (!localStorage.getItem(`scorecardTemplateId`)) {
            localStorage.setItem(
              `scorecardTemplateId`,
              JSON.stringify([action.payload[0]._id]),
            );
            draft.scorecardTemplate = [action.payload[0]._id];
          } else {
            draft.scorecardTemplate = JSON.parse(
              localStorage.getItem(`scorecardTemplateId`),
            );
          }
        }
        break;
      case constant.HANDLE_CHANGE_SCORECARD_TEMPLATE: {
        if (!_.isEmpty(action.payload)) {
          draft.scorecardTemplate = action.payload;
          const selectedColumnTree = getLocalStorageValue(
            `selectedColumnTree_${_.sortBy(action.payload)}`,
          );
          if (selectedColumnTree) {
            draft.selectedColumnTree = JSON.parse(selectedColumnTree);
          } else {
            setLocalStorageValue(
              `selectedColumnTree_${_.sortBy(action.payload)}`,
              JSON.stringify([]),
            );
            draft.selectedColumnTree = [];
          }
          localStorage.setItem(
            `scorecardTemplateId`,
            JSON.stringify(action.payload),
          );
        } else {
          draft.scorecardTemplate = [state.scorecardTemplates[0]._id];
        }
        // making this true even if not forced by Aggregated scorecard for simplicity in loading
        draft.forceLoadByAggregatedScorecard = true;
        break;
      }
      case constant.HANDLE_CHANGE_FIXED_COLUMNS:
        draft.initialFixedColumn = action.payload;
        draft.initialColumn = state.basicInitialColumn.filter(i =>
          action.payload.includes(i.dataIndex),
        );
        localStorage.setItem('initialColumn', JSON.stringify(action.payload));
        break;
      case constant.EXPORTING_SCORECARD:
        draft.loadingExport = true;
        break;
      case constant.EXPORTED_SCORECARD:
        draft.loadingExport = false;
        break;
      case constant.ERROR_EXPORTING_SCORECARD:
        draft.loadingExport = false;
        break;
      case constant.HANDLE_PAGE_CHANGE: {
        if (action.payload) {
          draft.skip = state.limit * (action.payload - 1);
        } else {
          draft.skip = 0;
        }
        draft.pageNo = action.payload || 1;
        break;
      }
      case constant.UPDATE_DATE_FILTER: {
        if (action.payload.value)
          draft.selectedFilters = draft.selectedFilters.map(filter => {
            if (filter.field === 'callTime') {
              filter.value = action.payload.value;
              filter.condition = action.payload.condition;
              return filter;
            }
            return filter;
          });
        else
          draft.selectedFilters = draft.selectedFilters.filter(
            filter => filter.field !== 'callTime',
          );
        draft.forceLoadByAggregatedScorecard = true;
        break;
      }
      case constant.UPDATE_AUDIT_DATE_FILTER: {
        const { condition, value } = action.payload;
        if (
          state.selectedFilters.find(filter => filter.field === 'createdAt')
        ) {
          if (action.payload.value)
            draft.selectedFilters = draft.selectedFilters.map(filter => {
              if (filter.field === 'createdAt') {
                filter.condition = condition;
                filter.value = value;
                filter.field = 'createdAt';
                return filter;
              }
              return filter;
            });
          else
            draft.selectedFilters = draft.selectedFilters.filter(
              filter => filter.field !== 'createdAt',
            );
        } else {
          const selectedFilters = _.cloneDeep(state.selectedFilters);
          selectedFilters.push({
            condition,
            value,
            field: 'createdAt',
          });
          draft.selectedFilters = selectedFilters;
        }
        draft.forceLoadByAggregatedScorecard = true;
        break;
      }
      case constant.UPDATE_TEMPLATE_WITHOUT_STORING_IN_LOCAL_STORAGE: {
        if (!_.isEmpty(action.payload)) {
          draft.scorecardTemplate = action.payload;
          const selectedColumnTree = getLocalStorageValue(
            `selectedColumnTree_${_.sortBy(action.payload)}`,
          );
          if (selectedColumnTree) {
            draft.selectedColumnTree = JSON.parse(selectedColumnTree);
          } else {
            setLocalStorageValue(
              `selectedColumnTree_${_.sortBy(action.payload)}`,
              JSON.stringify([]),
            );
            draft.selectedColumnTree = [];
          }
        } else {
          draft.scorecardTemplate = [state.scorecardTemplates[0]._id];
        }

        break;
      }
      case constant.CLEAR_NON_PRIMARY_FILTERS:
        draft.selectedFilters = state.selectedFilters.filter(
          selectedFilter =>
            selectedFilter.field === 'callTime' ||
            selectedFilter.field === 'createdAt',
        );
        break;
    }
  });

export default reviewScorecardListReducer;
