/* eslint-disable import/order */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import ScrollMemory from 'react-router-scroll-memory';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
// import { IntercomProvider } from 'react-use-intercom';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// import { ReactQueryDevtools } from 'react-query/devtools';

import history from 'utils/history';
import Auth from 'auth0-react';
import 'sanitize.css/sanitize.css';

// Import root app
import App from 'pages/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.png';
import 'file-loader?name=.htaccess!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

import mixpanel from 'utils/mixpanel';

import { MixpanelProvider } from 'react-mixpanel';

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';
// import { Auth0Provider } from './auth0-react';
// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');
// const onRedirectCallback = appState => {
//   history.push(
//     appState && appState.targetUrl
//       ? appState.targetUrl
//       : window.location.pathname
//   );
// };
// console.log(process.env, process.env.REACT_APP_AUTH0_DOMAIN)
const queryClient = new QueryClient();
queryClient.setQueryDefaults('callSearchFilters', {
  staleTime: Infinity,
  cacheTime: Infinity,
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENV,
  dist: 'production',
  release: process.env.APPLICATION_VERSION,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
});

const render = messages => {
  ReactDOM.render(
    // <Auth0Provider
    //   domain={process.env.REACT_APP_AUTH0_DOMAIN}
    //   client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
    //   redirect_uri={process.env.REACT_APP_WEBSITE_URL + '/callback'}
    //   audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    //   onRedirectCallback={onRedirectCallback}
    // >

    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <MixpanelProvider mixpanel={mixpanel}>
          {/* <IntercomProvider
            appId={process.env.INTERCOM_APP_ID}
            autoBoot
            onShow={() => {
              mixpanel('Clicked on Intercom', {});
            }}
          > */}
          <ConnectedRouter history={history}>
            <ScrollMemory elementID="search-results-list-container" />
            <QueryClientProvider client={queryClient}>
              <App />
              {/* React Query Devtools, uncomment import and JSX to load. */}
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
          </ConnectedRouter>
          {/* </IntercomProvider> */}
        </MixpanelProvider>
      </LanguageProvider>
    </Provider>,

    // </Auth0Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot re-loadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'pages/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

const { scheduleRenewal } = new Auth();
scheduleRenewal();
// on each reload starting schedule renewal cycle to keep user always logged in by refreshing token before expiry

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
//   require('offline-plugin/runtime').install(); // eslint-disable-line global-require
// }

// in the page being controlled
