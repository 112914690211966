/*
 *
 * AggregatedScorecard constants
 *
 */
const dir = 'aggregatedScorecard';
const constant = type => `prodigalVoice/${dir}/${type}`;
export default constant;

const FETCHING_FILTERS = constant('FETCHING_FILTERS');
const FETCHED_FILTERS = constant('FETCHED_FILTERS');
const ERROR_FETCHING_FILTERS = constant('ERROR_FETCHING_FILTERS');
const FETCH_FILTERS = constant('FETCH_FILTERS');
const FETCHING_SCORECARD_SEARCH = constant('FETCHING_SCORECARD_SEARCH');
const FETCHED_SCORECARD_SEARCH = constant('FETCHED_SCORECARD_SEARCH');
const ERROR_FETCHING_SCORECARD_SEARCH = constant(
  'ERROR_FETCHING_SCORECARD_SEARCH',
);
const FETCH_SCORECARD_SEARCH = constant('FETCH_SCORECARD_SEARCH');
const SET_COLUMN_NAME_MAP = constant('SET_COLUMN_NAME_MAP');
const HANDLE_CHANGE_COLUMN_TREE = constant('HANDLE_CHANGE_COLUMN_TREE');
const HANDLE_SELECTED_FILTER_KEY = constant('HANDLE_SELECTED_FILTER_KEY');
const HANDLE_NEW_FILTERS = constant('HANDLE_NEW_FILTERS');
const HANDLE_CHANGE_SORT_ORDER = constant('HANDLE_CHANGE_SORT_ORDER');
const SET_COLUMNS = constant('SET_COLUMNS');
const SET_SCORECARDS = constant('SET_SCORECARDS');
const SET_SELECT_ALL = constant('SET_SELECT_ALL');
const SET_SELECTED_ROWS = constant('SET_SELECTED_ROWS');
const EXPORT_SCORECARD = constant('EXPORT_SCORECARD');
const EXPORTING_SCORECARD = constant('EXPORTING_SCORECARD');
const EXPORTED_SCORECARD = constant('EXPORTED_SCORECARD');
const ERROR_EXPORTING_SCORECARD = constant('ERROR_EXPORTING_SCORECARD');
const APPROVE_EXPORTS = constant('APPROVE_EXPORTS');
const CLEAR_SEARCH = constant('CLEAR_SEARCH');
const HANDLE_CHANGE_SCORECARD_TEMPLATE = constant(
  'HANDLE_CHANGE_SCORECARD_TEMPLATE',
);
const FETCH_SCORECARD_TEMPLATES = constant('FETCH_SCORECARD_TEMPLATES');
const FETCHING_SCORECARD_TEMPLATES = constant('FETCHING_SCORECARD_TEMPLATES');
const FETCHED_SCORECARD_TEMPLATES = constant('FETCHED_SCORECARD_TEMPLATES');
const ERROR_FETCHING_SCORECARD_TEMPLATES = constant(
  'ERROR_FETCHING_SCORECARD_TEMPLATES',
);
const HANDLE_CHANGE_FIXED_COLUMNS = constant('HANDLE_CHANGE_FIXED_COLUMNS');
const HANDLE_PAGE_CHANGE = constant('HANDLE_PAGE_CHANGE');
const UPDATE_DATE_FILTER = constant('UPDATE_DATE_FILTER');
const UPDATE_AUDIT_DATE_FILTER = constant('UPDATE_AUDIT_DATE_FILTER');
const FETCH_INITIAL_DATA = constant('FETCH_INITIAL_DATA');
const UPDATE_TEMPLATE_WITHOUT_STORING_IN_LOCAL_STORAGE = constant(
  'UPDATE_TEMPLATE_WITHOUT_STORING_IN_LOCAL_STORAGE',
);
const HANDLE_AGGREGATION_DIMENSIONS_CHANGE = constant(
  'HANDLE_AGGREGATION_DIMENSIONS_CHANGE',
);

const UPDATE_DIMENSIONS_TREE_LIST = constant('UPDATE_DIMENSIONS_TREE_LIST');
const CLEAR_NON_PRIMARY_FILTERS = constant('CLEAR_NON_PRIMARY_FILTERS');
const NON_NA_GRADE_COUNT = 'non_na_grade_count';
const NUMBER_OF_CALLS_AUDITED = 'Number of calls audited';
const SET_SEARCH_ID = constant('SET_SEARCH_ID');
const CANCEL_SEARCH = constant('CANCEL_SEARCH');
export {
  dir,
  FETCHING_FILTERS,
  FETCHED_FILTERS,
  ERROR_FETCHING_FILTERS,
  FETCH_FILTERS,
  FETCHING_SCORECARD_SEARCH,
  FETCHED_SCORECARD_SEARCH,
  ERROR_FETCHING_SCORECARD_SEARCH,
  FETCH_SCORECARD_SEARCH,
  SET_COLUMN_NAME_MAP,
  HANDLE_CHANGE_COLUMN_TREE,
  HANDLE_SELECTED_FILTER_KEY,
  HANDLE_NEW_FILTERS,
  HANDLE_CHANGE_SORT_ORDER,
  SET_COLUMNS,
  SET_SCORECARDS,
  SET_SELECT_ALL,
  SET_SELECTED_ROWS,
  EXPORT_SCORECARD,
  EXPORTING_SCORECARD,
  EXPORTED_SCORECARD,
  ERROR_EXPORTING_SCORECARD,
  APPROVE_EXPORTS,
  CLEAR_SEARCH,
  HANDLE_CHANGE_SCORECARD_TEMPLATE,
  FETCH_SCORECARD_TEMPLATES,
  FETCHING_SCORECARD_TEMPLATES,
  FETCHED_SCORECARD_TEMPLATES,
  ERROR_FETCHING_SCORECARD_TEMPLATES,
  HANDLE_CHANGE_FIXED_COLUMNS,
  HANDLE_PAGE_CHANGE,
  UPDATE_DATE_FILTER,
  UPDATE_AUDIT_DATE_FILTER,
  FETCH_INITIAL_DATA,
  UPDATE_TEMPLATE_WITHOUT_STORING_IN_LOCAL_STORAGE,
  CLEAR_NON_PRIMARY_FILTERS,
  UPDATE_DIMENSIONS_TREE_LIST,
  HANDLE_AGGREGATION_DIMENSIONS_CHANGE,
  NUMBER_OF_CALLS_AUDITED,
  NON_NA_GRADE_COUNT,
  SET_SEARCH_ID,
  CANCEL_SEARCH,
};
