// cspell: ignore hardreset, sider, smartreports, scorecardbuilder, userlist, cbgd, managetags, timespan
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import FullStory, { FullStoryAPI } from 'react-fullstory';
// import { useIntercom } from 'react-use-intercom';
import { useQuery, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';

// import ValidateEvents from 'pages/ValidateEvents/Loadable';

import Sider from 'containers/SideBar';
import makeSelectCallSearch from 'pages/CallSearch/selectors';
import { MODIFY_SEARCH_CONFIG } from 'pages/CallSearch/constants';
import ScorecardTemplates from 'pages/ScorecardTemplates/Loadable';
import ConfigsList from 'pages/ConfigsList/Loadable';
import Configs from 'pages/Configs/Loadable';
import { useInjectReducer } from 'utils/injectReducer';
import AuthRoute from 'pages/AuthRoute/Loadable';
import Login from 'pages/Login/Loadable';
import Callback from 'components/Callback';
// import AddTagsEventsInCalls from 'pages/AddTagsEventsInCalls/Loadable';
// import AccountSearch from 'pages/AccountSearch/Loadable';
// import Import from 'containers/Import/Loadable';
// import ImportCalls from 'containers/ImportCalls/Loadable';
import Logout from 'pages/Logout/Loadable';
import ManageTags from 'pages/ManageTags/Loadable';
import CallSearch from 'pages/CallSearch/Loadable';
import SmartReport from 'pages/SmartReport/Loadable';
import SingleCallView from 'pages/SingleCallView/Loadable';
import ScorecardTemplate from 'pages/ScorecardTemplate/Loadable';
import SmartReportList from 'pages/SmartReportList/Loadable';
import Unsupported from 'pages/Unsupported/Loadable';
import UserRoles from 'pages/UserRoles/Loadable';
import UserManagement from 'pages/UserManagement/Loadable';
// import ValidateSnippets from 'containers/ValidateSnippets/Loadable';
import Visuals from 'pages/Visuals/Loadable';
import { Layout, Modal, Button, Icon, Text, NotFound } from 'components/common';
import {
  parseJwt,
  clearCache,
  getBaseUrl,
  getPageName,
  mixpanelDomainCheck,
} from 'utils/commonFunctions';
import history from 'utils/history';
// import makeSelectSider from 'containers/Sider/selectors';
import mixpanelFunction, { mixpanel } from 'utils/mixpanel';
// import Meta from 'antd/lib/card/Meta';
import UserPermissions from 'pages/UserPermissions/Loadable';
import Scorecard from 'pages/Scorecard/Loadable';
import Annotation from 'pages/Annotation/Loadable';
import Inbox from 'pages/Inbox/Loadable';
import ReviewScorecardList from 'pages/ReviewScorecardList/Loadable';
import AggregatedScorecard from 'pages/AggregatedScorecard/Loadable';
import Unauthorized from 'components/Unauthorized';
import HardReset from 'pages/HardReset';
import WebSocketConnection from 'pages/WebSocketConnection/Loadable';
// import { LoadingIndicator } from 'components';

import { fetchAppConfig, fetchInboxCounter } from './api-local';
import * as constant from './constants';
import actions from './actions';
import Auth from '../../auth0-react';
import makeSelectApp from './selectors';
import reducer from './reducer';
import './style.less';
import ChangeLog from '../../../changelog.md';

const runtime = require('offline-plugin/runtime');
const { detect } = require('detect-browser');
const ReactMarkdown = require('react-markdown');

const browser = detect();
const auth = new Auth();
const { isAuthenticated } = auth;
const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};
const { clearScorecardLocalStoredData } = auth;
const { Content } = Layout;
export function App({
  location,
  accessToken,
  handleModalVisible,
  // email,
  // name,
  handleUser,
  domain,
  cardVisible,
  modal1Visible,
  onLine,
  onChangeOnline,
  showCancelButton,
  handleCancelButton,
  trackUserOnFS,
  searchConfig,
  handleUpdateSearchConfig,
}) {
  useInjectReducer({ key: 'app', reducer });
  const pageLocation = useLocation();
  // const { update, hide } = useIntercom();

  const cardAllowed = !['/login', '/callback'].includes(
    window.location.pathname,
  );
  const queryClient = useQueryClient();
  /**
   * To get InboxCounter
   */
  const { data: inboxCounter, refetch: refetchInboxCounter } = useQuery(
    'inboxCounter',
    fetchInboxCounter,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      select: res => res?.data?.unreadCount || 0,
      enabled: false,
    },
  );

  /**
   * React-query to get appConfig's on load.
   */
  const { isFetching, data, refetch } = useQuery('appConfig', fetchAppConfig, {
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: res => handleAppConfig(res),
    retry: (failureCount, err) => {
      if (err?.code === 'Unauthorized') {
        history.push(`/unauthorized`);
        return false;
      }
      return failureCount > 2;
    },
    enabled: false,
  });

  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.register(
        `${process.env.PUBLIC_URL}service-worker.js`,
      );
      navigator.serviceWorker.getRegistration().then(reg => {
        // There's an active SW, but no controller for this tab.
        if (reg.active && !navigator.serviceWorker.controller) {
          // Perform a soft reload to load everything from the SW and get
          // a consistent set of resources.
          window.location.reload();
        }
      });
    }
  }, []);

  const handleEventListener = useCallback(
    event => {
      switch (event.data.type) {
        // cspell: disable-next
        case 'invalidatQueries':
          queryClient.fetchQuery('inboxCounter');
          queryClient.fetchQuery('getInboxList');
          break;
        default:
          break;
      }
    },
    [queryClient],
  );

  /**
   * Place to handle service worker events
   */
  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', handleEventListener);
    }
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        // If the user accepts, let's create a notification
        if (permission !== 'granted') {
          mixpanelFunction('Notification Denied', {});
        }
      });
    }
    return () => {
      navigator.serviceWorker.removeEventListener(
        'message',
        handleEventListener,
      );
    };
  }, [handleEventListener]);

  /*
   * it updates name, email for intercom and hides the intercom launcher on admin section
   */
  // useEffect(() => {
  //   // if (name) update({ name, userId: email, email });
  //   const isIntercomHidden = constant.ADMIN_PATHS.includes(
  //     window.location.pathname,
  //   );

  //   // update({
  //   //   customAttributes: {
  //   //     hide_default_launcher: isIntercomHidden,
  //   //   },
  //   // });

  //   if (isIntercomHidden) {
  //     // hide();
  //   }
  // }, [email, name, window.location.pathname]);

  useEffect(() => {
    if (accessToken && isAuthenticated() && !data) refetch();
    if (accessToken && isAuthenticated()) refetchInboxCounter();
  }, [accessToken, refetch, data, refetchInboxCounter]);

  useEffect(() => {
    trackFunction();
  }, [accessToken, domain, trackFunction]);

  /**
   * handles the response from the appConfig API;
   * @param {Object} res;
   */
  const handleAppConfig = res => {
    localStorage.setItem(
      'timezone',
      res?.primaryTimezone || 'America/Los_Angeles',
    );
    localStorage.setItem('numRows', res?.numRows || '2');
    localStorage.setItem(
      'defaultSearchTimeSpan',
      res?.defaultSearchTimespan || '7',
    );
    if (
      res?.defaultSearchTimespan ??
      searchConfig.filters.find(f => f.field === 'relativeDate')?.value !==
        res.defaultSearchTimespan
    ) {
      handleUpdateSearchConfig({
        ...searchConfig,
        filters: [
          {
            field: 'relativeDate',
            condition: 'equals',
            value: String(res.defaultSearchTimespan),
          },
        ],
      });
    }
  };

  useEffect(() => {
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, [updateOnlineStatus]);

  useEffect(() => {
    // This should be a list of routes with subpaths
    if (mixpanelDomainCheck(domain)) {
      const properties = getPageName();

      mixpanel.track('PAGE LOADED', properties);
    }
  }, [pageLocation.pathname, domain]);

  /**
   * offline-plugin/runtime, configuration to make app offline compatible.
   */
  runtime.install({
    onUpdating: () => {},
    onUpdateReady: () => {
      // Tells to new SW to take control immediately
      // runtime.applyUpdate();
      // onChangeNewVersion(true)
      // clearCache()
      // if (!modal1Visible) {

      handleModalVisible({
        modal1Visible: true,
        cardVisible: false,
      });
      handleCancelButton({ showCancelButton: true });

      // }
    },
    onUpdated: () => {
      handleCancelButton({ showCancelButton: false });
      clearScorecardLocalStoredData();
      // Reload the webpage to load into the new version
      // handleModalVisible({ modal1Visible: false, cardVisible: false });
      // window.location.reload(true);
      // onChangeNewVersion(false)
      // setTimeout(() => window.location.reload(true), 1000);
      // setTimeout(() => window.location.reload(), 1100);
    },

    onUpdateFailed: () => {
      clearCache();
    },
  }); // eslint-disable-line global-require

  const updateOnlineStatus = useCallback(() => {
    onChangeOnline(navigator.onLine);
  }, [onChangeOnline]);

  const scheduleTime = time => {
    setTimeout(() => {
      // fetcher().get(API.version).then(res => {
      if (!cardVisible && !modal1Visible) {
        // if (res.data.version !== jsonData.default) {
        handleModalVisible({
          modal1Visible: true,
          cardVisible: false,
        });
        // }
      }
      // })
      // scheduleTime(600000)
    }, time);
  };

  const trackFunction = useCallback(() => {
    const accessTokenStorage = localStorage.getItem('access_token');
    const idTokenStorage = localStorage.getItem('id_token');
    const parsedJwtAccessToken = parseJwt(accessTokenStorage);
    const parsedJwtIdToken = parseJwt(idTokenStorage);
    if (!parsedJwtAccessToken) {
      return;
    }
    const aud = process.env.REACT_APP_AUTH0_AUDIENCE;
    const region = parsedJwtAccessToken[`${aud}/region`];
    const tenant = parsedJwtAccessToken[`${aud}/tenant`];
    const emailFromToken = parsedJwtAccessToken[`${aud}/email`];
    const trackUserOnFSFromToken =
      parsedJwtAccessToken[`${aud}/trackUserOnFS`] || false;
    if (!emailFromToken || !emailFromToken.split('@')) {
      return;
    }
    const domain1 = emailFromToken.split('@')[1];
    const prodigal = emailFromToken.split('@')[0];
    handleUser({
      email: emailFromToken,
      domain: domain1,
      name: prodigal,
      trackUserOnFS: trackUserOnFSFromToken,
    });

    let accessGroup = '';
    if (parsedJwtIdToken) {
      accessGroup =
        parsedJwtIdToken['https://auth.prodigaltech.com/accessGroup'];
    }
    try {
      localStorage.setItem('userEmail', emailFromToken);
      localStorage.setItem('user_name', prodigal);
      localStorage.setItem('domain', domain1);
      localStorage.setItem('region', region);
      localStorage.setItem('tenant', tenant);
      localStorage.setItem('accessGroup', accessGroup);
    } catch (error) {
      // TODO: move to common function
    }

    // enabled by default
    if (true || mixpanelDomainCheck(domain)) {
      if (mixpanel.has_opted_out_tracking()) {
        mixpanel.opt_in_tracking();
        let ip = '';
        try {
          ip = localStorage.getItem('ipv4');
        } catch {
          // TODO: move to common function
        }
        mixpanel.identify(emailFromToken);
        mixpanel.register({
          user_name: prodigal,
          email: emailFromToken,
          version: process.env.APPLICATION_VERSION,
          server: getBaseUrl(),
          region,
          tenant,
          ip,
          accessGroup,
          domain:
            domain.split('.') && domain.split('.').length > 0
              ? domain.split('.')[0]
              : domain,
        });
      }
      if (trackUserOnFS) {
        FullStoryAPI('identify', emailFromToken);
        FullStoryAPI('setUserVars', {
          displayName: prodigal,
          email: emailFromToken,
        });
      }
    } else if (!mixpanel.has_opted_out_tracking()) {
      mixpanel.opt_out_tracking();
    }
    Sentry.setUser({
      username: prodigal,
      email: emailFromToken,
      id: emailFromToken,
    });
  }, [domain, handleUser, trackUserOnFS]);

  /**
   * Makes Modal visible or not.
   * @param {Boolean} modal1VisibleParam : modal visible or not.
   * @param {String} locationParam: Defines the params for the location, like modal.
   */
  const setModal1Visible = (modal1VisibleParam, locationParam) => {
    mixpanelFunction('New version available', {
      name: window.location.href,
      description: locationParam,
      ipAddress: localStorage.getItem('ipv4'),
    });
    handleModalVisible({
      modal1Visible: modal1VisibleParam,
      cardVisible: false,
    });
  };

  if (
    browser.name === 'ie' &&
    location &&
    location.pathname &&
    location.pathname !== '/unsupported'
  ) {
    return (
      <Fragment>
        <Route path="/">
          <Redirect to="/unsupported" />
        </Route>
      </Fragment>
    );
  }

  return (
    <Layout className="app-container">
      {domain &&
        trackUserOnFS &&
        window.location.host.includes('prodigaltech.com') && (
          <FullStory org="D4JS9" />
        )}
      <WebSocketConnection />
      <Route path="/">
        <Switch>
          <AuthRoute exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/hardreset" component={HardReset} />
          <Route
            exact
            path="/callback"
            render={props => {
              handleAuthentication(props);
              return <Callback {...props} />;
            }}
          />
          <Redirect exact from="/" to="/search-results" />
          <>
            <Sider
              leftNavConfig={data?.leftNav || []}
              runtime={runtime}
              setModal1Visible={setModal1Visible}
              cardVisible={cardVisible}
              inboxCounter={inboxCounter || 0}
            />
            <Layout
              className={`${
                !history.location.pathname.includes('/calls/')
                  ? 'fixed-sider-content-layout'
                  : ''
              }`}
              style={{ overflowY: 'hidden' }}
            >
              <Content>
                {!onLine && (
                  <div className="offline-message">You are offline!</div>
                )}
                <div>
                  {
                    // is fetching is there so that queryCache of app config have data
                  }
                  {!isFetching && (
                    <Switch>
                      <Route
                        exact
                        path="/unsupported"
                        component={Unsupported}
                      />
                      <AuthRoute
                        exact
                        path="/visuals"
                        component={Visuals}
                        requiredScope="view_visuals"
                      />
                      <AuthRoute
                        exact
                        path="/visuals/:id"
                        component={Visuals}
                        requiredScope="view_visuals"
                      />
                      <AuthRoute
                        exact
                        path="/agent-visualizations"
                        component={Visuals}
                        requiredScope="view_visuals"
                      />
                      <AuthRoute
                        exact
                        path="/agent-visualizations/:id"
                        component={Visuals}
                        requiredScope="view_visuals"
                      />
                      {/* <AuthRoute
                        exact
                        path="/accounts/search"
                        component={AccountSearch}
                      /> */}
                      <AuthRoute
                        exact
                        path="/smart-reports"
                        component={SmartReportList}
                        requiredScope="view_smartreports"
                      />
                      <AuthRoute
                        exact
                        path="/configs/:id"
                        component={Configs}
                        requiredScope="globalconfig_admin"
                      />
                      <AuthRoute
                        exact
                        path="/configs"
                        component={ConfigsList}
                        requiredScope="globalconfig_admin"
                      />
                      <AuthRoute
                        exact
                        path="/manage-tags"
                        component={ManageTags}
                        requiredScope="managetags_admin"
                      />
                      {/* <AuthRoute
                        exact
                        path="/validate-events"
                        component={ValidateEvents}
                      />
                      <AuthRoute
                        exact
                        path="/validate-events/:callId"
                        component={ValidateEvents}
                      /> */}
                      <AuthRoute
                        exact
                        path="/search-results"
                        component={CallSearch}
                        requiredScope="voice_dashboard"
                      />
                      <AuthRoute
                        exact
                        path="/smart-reports/:id"
                        component={SmartReport}
                        requiredScope="view_smartreports"
                      />
                      <AuthRoute
                        exact
                        path="/scorecard/:id/:templateId"
                        component={Scorecard}
                        requiredScope="view_scorecards"
                      />
                      <AuthRoute
                        exact
                        path="/calls/:callId"
                        component={SingleCallView}
                        requiredScope="voice_dashboard"
                      />
                      <AuthRoute
                        admin
                        path="/scorecard-templates/:templateId"
                        component={ScorecardTemplate}
                        requiredScope="scorecardbuilder_admin"
                      />
                      <AuthRoute
                        admin
                        path="/scorecard-templates"
                        component={ScorecardTemplates}
                        requiredScope="scorecardbuilder_admin"
                      />
                      <AuthRoute
                        path="/review-scorecard"
                        component={ReviewScorecardList}
                        requiredScope="voice_dashboard"
                      />
                      <AuthRoute
                        path="/aggregated-scorecards"
                        component={AggregatedScorecard}
                        requiredScope="voice_dashboard"
                      />
                      <AuthRoute
                        exact
                        path="/user-permission"
                        component={UserPermissions}
                        requiredScope="userlist_admin"
                      />
                      <AuthRoute
                        exact
                        path="/user-management"
                        component={UserManagement}
                        requiredScope="view_user_roles_admin"
                      />
                      <AuthRoute
                        exact
                        path="/inbox/:primaryId?"
                        component={Inbox}
                        requiredScope="voice_dashboard"
                      />
                      <AuthRoute
                        exact
                        path="/user-roles"
                        component={UserRoles}
                        requiredScope="view_user_roles_admin"
                      />
                      <AuthRoute
                        exact
                        path="/annotation__cbgd4"
                        component={Annotation}
                      />
                      {/* <AuthRoute exact path="/add-events-tags-to-calls" component={AddTagsEventsInCalls} /> */}
                      {/* <AuthRoute exact path="/calls/import" component={ImportCalls} /> */}
                      {/* <AuthRoute exact path="/validate-snippets" component={ValidateSnippets} /> */}
                      {/* <AuthRoute exact path="/import" component={Import} /> */}
                      <Redirect
                        exact
                        from="/scorecard-builder*"
                        to="/scorecard-templates"
                      />
                      <AuthRoute
                        path="/unauthorized"
                        component={Unauthorized}
                      />
                      <AuthRoute path="" component={NotFound} />
                    </Switch>
                  )}
                </div>
              </Content>
            </Layout>
          </>
        </Switch>
      </Route>
      <Modal
        title={null}
        visible={modal1Visible && cardAllowed}
        bodyStyle={{ width: 440 }}
        confirmLoading={false}
        destroyOnClose
        centered
        closable={false}
        footer={null}
      >
        <div className="new-version-div">
          <Icon size={{ width: '159px', height: '114px' }} type="newVersion" />
          <Text text="New Version Available" type="title" />
          <ReactMarkdown className="markdown-container" allowDangerousHtml>
            {ChangeLog}
          </ReactMarkdown>
          <Text text="Click OK to upgrade" type="paragraph" className="grey" />
          <div className="upgrade-modal-buttons">
            <Button
              disabled={!showCancelButton}
              onClick={() => {
                scheduleTime(1000 * 60 * 30);
                handleModalVisible({
                  modal1Visible: false,
                  cardVisible: true,
                });
              }}
              height="50px"
              text="Remind in 30 min"
            />
            <Button
              type="primary"
              height="50px"
              onClick={() => {
                runtime.applyUpdate();
                setModal1Visible(false, 'modal');
                setTimeout(() => window.location.reload(), 1000);
                localStorage.setItem('newVersionInstalled', true);
                // setTimeout(() => window.location.reload(), 1100);
              }}
              text="OK"
            />
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  accessToken: PropTypes.string,
  handleModalVisible: PropTypes.func,
  handleUser: PropTypes.func,
  email: PropTypes.string,
  name: PropTypes.string,
  domain: PropTypes.string,
  cardVisible: PropTypes.bool,
  modal1Visible: PropTypes.bool,
  newVersion: PropTypes.bool,
  onChangeNewVersion: PropTypes.func,
  onChangeOnline: PropTypes.func,
  handleCancelButton: PropTypes.func,
  onLine: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  location: PropTypes.string,
  trackUserOnFS: PropTypes.bool,
  searchConfig: PropTypes.object,
  handleUpdateSearchConfig: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  app: makeSelectApp(),
  accessToken: makeSelectApp('accessToken'),
  email: makeSelectApp('email'),
  name: makeSelectApp('name'),
  domain: makeSelectApp('domain'),
  cardVisible: makeSelectApp('cardVisible'),
  modal1Visible: makeSelectApp('modal1Visible'),
  onLine: makeSelectApp('onLine'),
  showCancelButton: makeSelectApp('showCancelButton'),
  trackUserOnFS: makeSelectApp('trackUserOnFS'),
  searchConfig: makeSelectCallSearch('searchConfig'),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    handleModalVisible: e =>
      dispatch(actions(constant.HANDLE_MODAL_VISIBLE, e)),
    handleUser: e => dispatch(actions(constant.HANDLE_USER, e)),
    onChangeNewVersion: e => dispatch(actions(constant.CHANGE_NEW_VERSION, e)),
    onChangeOnline: e => dispatch(actions(constant.CHANGE_ONLINE, e)),
    handleCancelButton: e =>
      dispatch(actions(constant.HANDLE_CANCEL_BUTTON, e)),
    handleUpdateSearchConfig: e => dispatch(actions(MODIFY_SEARCH_CONFIG, e)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(App);
