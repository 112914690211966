import { API, patchRequest } from 'utils/request';

export const patchScheduleSmartReportExport = async params => {
  const res = await patchRequest(
    `${API.deleteSmartReport}/${params?.reportId}?action=schedule`,
    params?.requestParams || null,
  );
  return { success: !res?.error, error: res?.error || '' };
};

export const patchUnscheduleSmartReportExport = async reportId => {
  const res = await patchRequest(
    `${API.deleteSmartReport}/${reportId}?action=unschedule`,
  );
  return { success: !res?.error, error: res?.error || '' };
};
