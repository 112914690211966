/*
 *
 * SmartReport reducer
 *
 */

import produce from 'immer';
import moment from 'moment';

import mixpanel from 'utils/mixpanel';
import { isCallPresent, handleStatsFetchLoaded } from 'utils/commonFunctions';
import { mixpanelKeys } from 'utils/mixpanelKeys';
import { notifyUser } from 'utils/notification';

import * as constant from './constants';

export const initialState = {
  searchConfig: {
    searchQuery: '',
    filters: [
      {
        field: 'relativeDate',
        condition: 'equals',
        value: localStorage.getItem('defaultSearchTimeSpan') || '7',
      },
    ],
    sortFieldList: [{ field: 'timestamp', order: -1 }],
    saveInSearchHistory: true,
    displayName: `Search run at ${moment().format('YYYY-MM-DD hh:mm:ss')}`,
    priority: true,
    pagination: { pageNo: 1, pageSize: 50 },
  },
  calls: [],
  loading: false,
  originalDescription: '',
  selectedCalls: [],
  selectedCall: null,
  showSearchStats: true,
  searchStats: {},
  items: [
    {
      type: 'name',
      placeholder: 'Smart report name',
      key: 'displayName',
      required: true,
      label: 'Name',
      validator: 'validateSmartReportName',
    },
    {
      type: 'description',
      placeholder: 'Smart report description',
      key: 'description',
      required: true,
      label: 'Description',
      validator: 'validateSmartReportName',
    },
  ],
  pagination: {},
};

/**
 * This function is called after user click on checkbox of individual call card to either export it or apply calls
 *
 * @param {number} index - index of selected checkbox
 * @param {object} e
 * @param {object} state - current state of redux
 *
 * @returns updated selected calls list
 */
const onCheckboxChange = (index, e, state) => {
  let { selectedCalls } = state;
  const { calls } = state;
  e.stopPropagation();
  let checkboxClicked = true;
  const newSelectedCallId = calls[index].callId;
  selectedCalls = selectedCalls.filter(callId => callId !== newSelectedCallId);
  if (e.target.checked) {
    if (!checkboxClicked) {
      selectedCalls = [newSelectedCallId];
    } else {
      selectedCalls.push(newSelectedCallId);
    }
  }
  if (selectedCalls.length === 0) {
    let selectedCall = null;
    if (state.selectedCall) {
      calls.forEach(call => {
        if (state.selectedCall === call.callId) {
          selectedCall = call.callId;
        }
      });
      selectedCalls.push(selectedCall);
    }
    checkboxClicked = false;
  }
  let showSearchStats = true;
  if (checkboxClicked || state.selectedCall) {
    showSearchStats = false;
  }
  return { showSearchStats, selectedCalls, checkboxClicked, selectAll: false };
};

/**
 * This function is called after user click on checkbox to select all
 *
 * @param {object} e
 * @param {object} state - current state of redux
 *
 * @returns updated selected calls list
 */
const onSelectAllCheckboxChange = (e, state) => {
  e.stopPropagation();
  const { calls } = state;
  let selectedCalls = [];
  let checkboxClicked = true;
  let { showSearchStats } = state;
  if (e.target.checked) {
    const alreadySelectedCalls =
      state.selectedCalls?.filter(f => !isCallPresent(calls, f)) || [];
    selectedCalls = [
      ...alreadySelectedCalls,
      ...calls.map(call => call.callId),
    ];
    showSearchStats = false;
  } else {
    if (state.selectedCall) {
      selectedCalls.push(state.selectedCall);
    } else {
      showSearchStats = true;
    }
    checkboxClicked = false;
  }
  return { showSearchStats, selectedCalls, checkboxClicked, selectAll: false };
};

/**
 * This function is called when user selects call to open Single call on right side
 *
 * @param {[number, string]} index - index of selected call or callId
 * @param {boolean} boolean - boolean tells the function if index is callId or index of selected Call
 * @param {object} state - current state of redux
 *
 * @returns updated selected calls list
 */
const onCallSelect = (index, boolean = false, state) => {
  let newSelectedCall;
  const { calls } = state;
  if (boolean) {
    newSelectedCall =
      calls && calls.length > 0
        ? calls.find(f => f.callId === index).callId
        : '';
  } else {
    newSelectedCall = state.calls[index].callId;
  }
  let { showSearchStats } = state;
  const selectedCalls = [];
  const alreadySelectedCalls = state.selectedCalls;
  if (
    !alreadySelectedCalls ||
    !alreadySelectedCalls.length ||
    !state.selectedCall ||
    state.checkboxClicked ||
    (alreadySelectedCalls[0] && alreadySelectedCalls[0] !== newSelectedCall)
  ) {
    showSearchStats = false;
  } else if (showSearchStats && newSelectedCall) {
    showSearchStats = false;
    newSelectedCall = state.selectedCall;
  }

  selectedCalls.push(newSelectedCall);

  return {
    selectedCalls,
    selectedCall: newSelectedCall,
    checkboxClicked: false,
    selectedCallIndex: index,
    showSearchStats,
    selectAll: false,
  };
};

/**
 * This function is called reset checkbox screen appears on right side
 *
 * @param {object} state- current state of redux
 */
const clearSelection = state => {
  mixpanel('Clear Selection', {
    [mixpanelKeys.callIds]: state.selectedCalls.length,
  });
  const selectedCalls = [];
  let showSearchStats = true;
  if (state.selectedCall) {
    showSearchStats = false;
    selectedCalls.push(state.selectedCall);
  }
  return {
    showSearchStats,
    selectedCalls,
    checkboxClicked: false,
    selectAll: false,
  };
};

/* eslint-disable default-case, no-param-reassign */
const smartReportReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constant.FETCHED_ADHOC_SEARCH: {
        if (action.payload.searchId === state.searchId) {
          const { calls, pagination } = action.payload;
          draft.loading = false;
          draft.calls = calls;
          draft.searchConfig.pagination = pagination;
          // draft.searchStats = handleStatsFetchLoaded(searchStats);
          draft.originalTitle = state.searchConfig.displayName;
          draft.pagination = pagination;
          if (calls.length) {
            const [firstCall] = calls;
            draft.initialCallSelected = true;
            draft.selectedCall = firstCall.callId;
            draft.selectedCalls = [firstCall.callId];
          }
          notifyUser(
            'Search complete!',
            {
              onclick: e => {
                e.preventDefault();
                window.focus();
              },
              body: 'Smart Report has been prepared. Click here to review.',
            },
            true,
          );
        }
        break;
      }
      case constant.HANDLE_FETCH_STATS: {
        draft.statsLoading = true;
        draft.searchStats = {};
        draft.statsSearchId = action.payload;
        break;
      }
      case constant.HANDLE_STATS_LOAD: {
        const { searchStats, searchId } = action.payload;
        draft.searchStats = handleStatsFetchLoaded(searchStats);
        draft.statsSearchId = searchId;
        draft.statsLoading = false;
        break;
      }
      case constant.CANCEL_SEARCH:
        draft.searchId = '';
        draft.loading = false;
        draft.error = false;
        break;
      case constant.SET_SEARCH_ID:
        draft.searchId = action.payload;
        draft.showSearchStats = false;
        draft.searchStats = {};
        draft.statsLoading = false;
        draft.statsSearchId = undefined;
        break;
      case constant.FETCHING_ADHOC_SEARCH:
        draft.loading = true;
        draft.error = false;
        break;
      case constant.RESET_SELECTED_CALLS:
        draft.selectedCalls = [];
        draft.selectedCall = null;
        break;
      case constant.ERROR_FETCHING_ADHOC_SEARCH:
        if (action.payload.searchId === state.searchId) {
          draft.loading = false;
          draft.calls = [];
          draft.error = true;
        }
        break;
      case constant.ERROR_FETCHING_ADHOC_CALL_STATS:
        if (action.payload.searchId === state.statsSearchId) {
          draft.statsLoading = false;
          draft.searchStats = {};
        }
        break;
      case constant.FETCHING_FILTER:
        draft.loading = true;
        draft.filters = {};
        break;
      case constant.FETCHED_FILTER:
        draft.filters = action.payload;
        break;
      case constant.CLEAR_SELECTION: {
        const {
          showSearchStats,
          selectedCalls,
          checkboxClicked,
          selectAll,
        } = clearSelection(state);
        draft.showSearchStats = showSearchStats;
        draft.selectedCalls = selectedCalls;
        draft.checkboxClicked = checkboxClicked;
        draft.selectAll = selectAll;
        break;
      }
      case constant.CLEAR_SEARCH:
        draft.searchConfig = {
          ...initialState.searchConfig,
          filters: [...initialState.searchConfig.filters],
        };
        break;
      case constant.SET_STATS_PAGE_WIDTH:
        draft.statsPageWidth = action.payload;
        break;
      case constant.RENAMED_SMART_REPORT:
        draft.searchConfig.displayName = action.payload;
        draft.originalTitle = action.payload;
        break;
      case constant.ON_CALL_SELECT: {
        const {
          selectedCalls,
          selectedCall,
          checkboxClicked,
          selectedCallIndex,
          showSearchStats,
          selectAll,
        } = onCallSelect(action.payload.index, action.payload.boolean, state);
        draft.checkboxClicked = checkboxClicked;
        draft.showSearchStats = showSearchStats;
        draft.selectedCalls = [...selectedCalls];
        draft.selectedCall = selectedCall;
        draft.selectedCallIndex = selectedCallIndex;
        draft.selectAll = selectAll;
        break;
      }
      case constant.ON_CHECKBOX_CLICKED: {
        const {
          showSearchStats,
          selectedCalls,
          checkboxClicked,
          selectAll,
        } = onCheckboxChange(action.payload.index, action.payload.e, state);
        draft.checkboxClicked = checkboxClicked;
        draft.showSearchStats = showSearchStats;
        draft.selectedCalls = [...selectedCalls];
        draft.selectAll = selectAll;
        break;
      }
      case constant.ON_SELECT_ALL_CHECKBOX_CHANGE: {
        const {
          showSearchStats,
          selectedCalls,
          checkboxClicked,
          selectAll,
        } = onSelectAllCheckboxChange(action.payload, state);

        draft.checkboxClicked = checkboxClicked;
        draft.showSearchStats = showSearchStats;
        draft.selectedCalls = [...selectedCalls];
        draft.selectAll = selectAll;
        break;
      }
      case constant.ON_APPLY_TAGS:
        draft.calls = action.payload;
        break;
      case constant.ON_SORT_CLICKED:
        // draft;
        break;
      case constant.HANDLE_SELECT_ALL:
        draft.selectAll = action.payload;
        break;
      case constant.HANDLE_SHOW_STATS_CLICK:
        if (!state.showSearchStats) {
          draft.showSearchStats = true;
          draft.selectedCalls = [];
          draft.selectedCall = null;
        }
        break;
      case constant.MODIFY_SEARCH_CONFIG:
        draft.searchConfig = action.payload;
        break;
      case constant.REORDER_STATS_DATA: {
        const { type, value } = action.payload;
        draft.searchStats = {
          ...state.searchStats,
          [type]: { records: value, numRecords: state.searchStats[type] },
        };
        break;
      }
      case constant.RESET_SMART_REPORT: {
        // reset keys in draft same as initialState content
        Object.keys(initialState).forEach(key => {
          draft[key] = initialState[key];
        });
        break;
      }
    }
  });

export default smartReportReducer;
