import {
  SET_IS_POPOVER_VISIBLE,
  SET_IS_EDITING_FREQUENCY,
  SET_IS_EDITING_EMAIL,
  SET_SHOULD_ENABLE_SCHEDULE_EMAIL,
  SET_SHOULD_ENABLE_SCHEDULE_FREQUENCY,
  SET_FREQUENCY_INPUT_ERROR,
  SET_EMAIL_INPUT_ERROR,
} from './constants';
const autoExportReducer = (state, action) => {
  switch (action.type) {
    case SET_IS_EDITING_FREQUENCY:
    case SET_IS_POPOVER_VISIBLE:
    case SET_IS_EDITING_EMAIL:
    case SET_SHOULD_ENABLE_SCHEDULE_EMAIL:
    case SET_EMAIL_INPUT_ERROR:
    case SET_FREQUENCY_INPUT_ERROR:
    case SET_SHOULD_ENABLE_SCHEDULE_FREQUENCY:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export { autoExportReducer };
