/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 *
 * Component: FrequencyHandler
 * Date: 8/11/2022
 * Handles the cadence of scheduled reports
 */

import React, { useState, useMemo, forwardRef, useEffect } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { Radio, RadioGroup, Popover, Input, Text } from 'components/common';
import { WEEK_DAY_INITIALS, DAY_NUMBER_BOUNDS } from 'utils/commonConstants';

import styles from './style.css';

const FrequencyHandler = forwardRef(
  (
    {
      isPopoverVisible,
      setIsPopoverVisible,
      setShouldEnableScheduleFrequency,
      selectedExportReport,
      setIsEditing,
      loading,
      frequencyInputNotModifiedError,
      setFrequencyInputNotModifiedError,
    },
    autoExportInfoRef,
  ) => {
    const [frequencyCadence, setFrequencyCadence] = useState(
      selectedExportReport?.schedule?.isScheduled
        ? selectedExportReport?.schedule?.frequency || 'None'
        : 'None',
    );
    const [weeklyFrequencyNumber, setWeeklyFrequencyNumber] = useState(2);
    const [monthlyFrequencyNumber, setMonthlyFrequencyNumber] = useState(1);
    const { MAX_MONTH, MIN_MONTH, MAX_WEEK, MIN_WEEK } = DAY_NUMBER_BOUNDS;

    const between = (x, min, max) => x >= min && x <= max;

    useEffect(() => {
      // Sets up initial Weekly and Monthly Day Number states
      switch (selectedExportReport?.schedule?.frequency) {
        case 'weekly':
          if (
            between(
              selectedExportReport?.schedule?.dayNumber,
              MIN_WEEK,
              MAX_WEEK,
            )
          ) {
            if (selectedExportReport?.schedule?.isScheduled) {
              setWeeklyFrequencyNumber(
                selectedExportReport?.schedule?.dayNumber,
              );
            }
          }
          break;

        case 'monthly':
          if (selectedExportReport?.schedule?.isScheduled) {
            if (
              between(
                selectedExportReport?.schedule?.dayNumber,
                MIN_MONTH,
                MAX_MONTH,
              )
            ) {
              setMonthlyFrequencyNumber(
                selectedExportReport?.schedule?.dayNumber,
              );
            }
          }
          break;

        default:
      }
    }, [selectedExportReport]);

    const onChange = e => {
      setFrequencyCadence(e.target.value);
    };

    useEffect(() => {
      let frequencyData = {};
      /**
       * handle the initial disabled state of Scheduled button
       */

      setShouldEnableScheduleFrequency(frequencyCadence !== 'None');

      switch (frequencyCadence) {
        case 'daily':
          frequencyData = { frequency: 'daily' };
          break;
        case 'weekly':
          frequencyData = {
            frequency: 'weekly',
            dayNumber: weeklyFrequencyNumber,
          };
          break;

        case 'monthly':
          frequencyData = {
            frequency: 'monthly',
            dayNumber: monthlyFrequencyNumber,
          };
          break;

        default:
      }

      // eslint-disable-next-line no-param-reassign
      autoExportInfoRef.current = {
        recipientEmails: autoExportInfoRef.current?.recipientEmails,
        ...frequencyData,
      };

      /**
       * Checks to update Scheduled and unScheduled buttons on the modal
       * 1st condition Checks if the frequency of schedules has changed
       * 2nd condition Deals with change of date numbers in the same Frequency being selected
       * We are using this information to change schedule button to unschedule based by setting
       * the Editing state here.
       */
      switch (frequencyCadence) {
        case 'daily': {
          setIsEditing(
            selectedExportReport?.schedule?.frequency !== 'daily' ||
              !selectedExportReport?.schedule?.isScheduled,
          );
          break;
        }
        case 'weekly': {
          setIsEditing(
            selectedExportReport?.schedule?.frequency !== 'weekly' ||
              weeklyFrequencyNumber !==
                selectedExportReport?.schedule?.dayNumber,
          );
          break;
        }
        case 'monthly': {
          setIsEditing(
            selectedExportReport?.schedule?.frequency !== 'monthly' ||
              monthlyFrequencyNumber !==
                selectedExportReport?.schedule?.dayNumber,
          );
          break;
        }
        default:
          setIsEditing(false);
      }

      if (frequencyInputNotModifiedError)
        setFrequencyInputNotModifiedError(false);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      frequencyCadence,
      monthlyFrequencyNumber,
      weeklyFrequencyNumber,
      selectedExportReport,
    ]);

    const WeekFrequencyJSX = selectedFrequencyNumber => (
      <div>
        <div className={styles.frequencyText}>
          <Text
            text="Select the Day when you need this report"
            className="frequencyHandlerTitle"
          />
        </div>
        <div className={styles.weekDaysDiv}>
          {WEEK_DAY_INITIALS.map((eachWeekDay, dayIndex) => (
            <div
              role="button"
              tabIndex={0}
              // eslint-disable-next-line react/no-array-index-key
              key={dayIndex}
              className={`${styles.eachDayDiv} ${
                dayIndex === selectedFrequencyNumber - 1
                  ? styles.selectedEachDayDiv
                  : ''
              }`}
              onClick={() => {
                if (!loading) {
                  setWeeklyFrequencyNumber(dayIndex + 1);
                }
              }}
            >
              <Text
                text={eachWeekDay}
                type={
                  dayIndex === selectedFrequencyNumber - 1
                    ? 'weekDaySelected'
                    : 'weekDay'
                }
              />
            </div>
          ))}
        </div>
      </div>
    );

    const dateJSX = selectedDate => {
      /**
       * This is for showing the day count on UI, We have taken a decision show all 31 days here
       * and email will go on the last day of the month in case the month does not have the selected day in month.
       * Hence, This is Not related to Day of Export
       */
      const NUMBER_OF_DAYS = 31;
      const allDates = [];
      for (let i = 1; i <= NUMBER_OF_DAYS; i += 1) {
        allDates.push(
          <div
            className={`${styles.eachDate} ${
              selectedDate === i ? styles.eachDateSelected : ''
            }`}
            role="button"
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            tabIndex={0}
            onClick={() => {
              setMonthlyFrequencyNumber(i);
              setIsPopoverVisible(false);
            }}
          >
            {i}
          </div>,
        );
      }
      return <div className={styles.datePopoverDiv}>{allDates}</div>;
    };

    const memoizedMonthlyDateSelector = useMemo(
      () => dateJSX(monthlyFrequencyNumber, loading),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [monthlyFrequencyNumber, loading],
    );

    const monthlyFrequencyJSX = selectedFrequencyNumber => (
      <>
        <div className={styles.frequencyText}>
          <Text
            text="Select the Day when you need this report"
            className="frequencyHandlerTitle"
          />
        </div>
        <Popover
          placement="bottomLeft"
          trigger="click"
          content={memoizedMonthlyDateSelector}
          visible={isPopoverVisible && !loading}
        >
          <div
            className={styles.inputContainer}
            onClick={e => {
              e.stopPropagation();
              if (!loading) {
                setIsPopoverVisible(true);
              }
            }}
          >
            <Input
              type="number"
              min={1}
              height={32}
              max={31}
              defaultValue={selectedFrequencyNumber}
              className="inputNumberStyle"
              readOnly
              value={monthlyFrequencyNumber}
            />
            <CalendarOutlined className={styles.calendarIcon} />
          </div>
        </Popover>
      </>
    );

    const buildJSXfromFrequency = () => {
      switch (frequencyCadence) {
        case 'daily':
          return <></>;

        case 'weekly':
          return <div>{WeekFrequencyJSX(weeklyFrequencyNumber)}</div>;

        case 'monthly':
          return <div>{monthlyFrequencyJSX(monthlyFrequencyNumber)}</div>;

        default:
          return <></>;
      }
    };

    const memoizedCadenceSelector = useMemo(
      () => (
        <>
          <div className={styles.frequencyText}>
            <Text
              text="Select frequency of the report"
              className="frequencyHandlerTitle"
            />
          </div>
          <div className={styles.frequencyRadio}>
            <RadioGroup
              onChange={onChange}
              value={frequencyCadence}
              disabled={loading}
            >
              <Radio value="daily">Daily</Radio>
              <Radio value="weekly">Weekly</Radio>
              <Radio value="monthly">Monthly</Radio>
            </RadioGroup>
          </div>
        </>
      ),
      [frequencyCadence, loading],
    );

    /**
     * Generates error message based on the current state of the component
     * @returns String
     */
    const getErrorMessage = () => {
      if (frequencyInputNotModifiedError)
        return 'Please select one of the above options';
      return '';
    };

    return (
      <>
        <div>
          {memoizedCadenceSelector}
          {buildJSXfromFrequency()}
        </div>

        <div className={styles.errorContainer}>
          <span className={styles.errorMessage}>{getErrorMessage()}</span>
        </div>
      </>
    );
  },
);

FrequencyHandler.propTypes = {
  isPopoverVisible: PropTypes.bool,
  loading: PropTypes.bool,
  setIsPopoverVisible: PropTypes.func,
  setShouldEnableScheduleFrequency: PropTypes.func,
  setIsEditing: PropTypes.func,
  selectedExportReport: PropTypes.object,
  frequencyInputNotModifiedError: PropTypes.bool,
  setFrequencyInputNotModifiedError: PropTypes.func,
};

export default FrequencyHandler;
