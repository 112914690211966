/**
 *
 * Component: NextBestUtterance
 * Date: 5/4/2023
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useQueryClient } from 'react-query';

import Icon from 'components/common/Icon';
import Tooltip from 'components/common/Tooltip';
import Popover from 'components/common/PopOver';
import { message } from 'components/common';
import Reactions from 'components/Reactions';
import mixpanel from 'utils/mixpanel';
import { mixpanelKeys } from 'utils/mixpanelKeys';

import styles from './style.css';
import { fetchNextBestUtterance } from './api';

function NextBestUtterance({ isAgentSpeaker, index, call }) {
  const queryClient = useQueryClient();

  const {
    data: nextBestUtterance,
    isFetching,
    refetch: refetchNextBestUtterance,
    isSuccess,
  } = useQuery({
    queryFn: fetchNextBestUtterance,
    queryKey: ['nextBestUtterance', index, call.callId],
    enabled: false,
    keepPreviousData: true,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    onError: error => {
      message.error('Unable to retrieve expert recommendation');
      mixpanel('Failed to fetch Utterance', {
        [mixpanelKeys.error]: error,
        [mixpanelKeys.index]: index || '',
        [mixpanelKeys.callId]: call?.callId || '',
      });
    },
    onSuccess: data => {
      mixpanel('Utterance shown', {
        [mixpanelKeys.utterance]: data?.utterance || '',
        [mixpanelKeys.index]: data?.query?.index || '',
        [mixpanelKeys.callId]: data?.query?.call_id || '',
      });
    },
  });

  const handleUtteranceReactions = (vote, feedbackText = '') => {
    const { callId = '' } = call;

    mixpanel('Feedback', {
      [mixpanelKeys.action]: vote,
      [mixpanelKeys.callId]: callId,
      [mixpanelKeys.index]: index,
      [mixpanelKeys.text]: feedbackText,
      [mixpanelKeys.utterance]: nextBestUtterance?.utterance || '',
      [mixpanelKeys.details]: nextBestUtterance,
    });
    message.success('Thank you for sharing your feedback!');
  };

  if (!isAgentSpeaker || index === 0 || index === 1) return <></>;

  const handlePopoverClick = e => e.stopPropagation();

  const popoverJSX = () => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={styles.popoverContent} onClick={handlePopoverClick}>
      <div className={styles.bestUtterance}>
        <div className={styles.utteranceQuoteDiv} />
        <div className={styles.bestUtteranceText}>
          {nextBestUtterance.utterance}
        </div>
      </div>
      <div className={styles.reactions}>
        <Reactions
          onReaction={handleUtteranceReactions}
          iconSize={16}
          showDescriptions={false}
          inputAutoSizeProps
        />
      </div>
    </div>
  );

  const handleBulbIconClick = e => {
    e.stopPropagation();
    queryClient.resetQueries(['nextBestUtterance']);
    queryClient.cancelQueries(['nextBestUtterance']);
    refetchNextBestUtterance();
    mixpanel('Clicked on get utterance', {
      [mixpanelKeys.index]: index || '',
      [mixpanelKeys.callId]: call?.callId || '',
    });
  };

  const handleCancelIconClick = () => {
    queryClient.cancelQueries(['nextBestUtterance', index]);
    mixpanel('Clicked on cancel utterance', {
      [mixpanelKeys.index]: index || '',
      [mixpanelKeys.callId]: call?.callId || '',
    });
  };

  const handleLoadingIconClick = e => e.stopPropagation();

  const handleCheckIconClick = () =>
    queryClient.resetQueries(['nextBestUtterance', index]);

  if (isSuccess) {
    return (
      <span className={styles.checkedIcon}>
        <Popover
          placement="bottom"
          overlayClassName={styles.nextBestUtterancePopover}
          visible={isSuccess}
          content={popoverJSX()}
        >
          <Icon type="checkedIcon" size={16} onClick={handleCheckIconClick} />
        </Popover>
      </span>
    );
  }
  if (isFetching) {
    return (
      <>
        <span className={styles.loadingIcon}>
          <Tooltip title="Loading">
            <Icon
              type="loadingIcon"
              size={16}
              onClick={handleLoadingIconClick}
            />
          </Tooltip>
        </span>
        <span className={styles.cancelIcon}>
          <Tooltip title="Click to cancel fetching">
            <Icon type="cancelIcon" size={16} onClick={handleCancelIconClick} />
          </Tooltip>
        </span>
      </>
    );
  }

  return (
    <>
      <span className={styles.bulbIcon}>
        <Tooltip title="Click to see the expert recommendation">
          <Icon type="bulbIcon" size={16} onClick={handleBulbIconClick} />
        </Tooltip>
      </span>
    </>
  );
}

NextBestUtterance.propTypes = {
  isAgentSpeaker: PropTypes.bool,
  index: PropTypes.number,
  call: PropTypes.object,
};

export default NextBestUtterance;
