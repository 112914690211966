/**
 * Checks if tagDict_.id exist in tagList or not.
 * @param {Array} tagList : List of tags present.
 * @param {Object} tagDict : Object of tag to search for.
 * @returns {Boolean}
 */
const isTagPresent = (tagList, tagDict) => {
  let isTagPresentBool = false;
  tagList.map(tag => {
    if (tagDict._id === tag._id) {
      isTagPresentBool = true;
    }
    return tag;
  });
  return isTagPresentBool;
};
/**
 * Checks to see if tag name exist in the tagList.
 * @param {Array} tagList : List of Tags present.
 * @param {Object} tagDict : Tag Object to search for.
 * @returns {Boolean}
 */
const isTagIdPresent = (tagList, tagDict) => {
  let isTagPresentBool = false;
  tagList.map(tag => {
    if (tagDict.name === tag.substring(tag.indexOf('/') + 1, tag.length)) {
      isTagPresentBool = true;
    }
    return tag;
  });
  return isTagPresentBool;
};

export { isTagPresent, isTagIdPresent };
