const SET_TALK_METRICS = 'setTalkMetrics';
const CREATE_SPANS = 'createOverTalkSilenceSpans';
const SET_SOURCE_URL = 'setSourceURL';
const CREATE_AUDIO_BARS = 'createAudioBars';
const CHANGE_PLAY_RATE = 'changePlaybackRate';
const CHANGE_VOLUME = 'changeVolume';
const SET_HOVER_STATES = 'setHoverStates';
const SET_SKIP_SILENCE = 'setSkipSilence';
const OVER_TALK_COLOR = '#d0021b';
const SILENCE_COLOR = '#DCDDE0';

export {
  SET_HOVER_STATES,
  SET_SKIP_SILENCE,
  SET_TALK_METRICS,
  CREATE_SPANS,
  SET_SOURCE_URL,
  CREATE_AUDIO_BARS,
  CHANGE_PLAY_RATE,
  CHANGE_VOLUME,
  OVER_TALK_COLOR,
  SILENCE_COLOR,
};
