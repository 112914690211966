/**
 *
 * Component: InboxRow
 * Date: 17/11/2021
 *
 */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router';
import moment from 'moment';

import DateUtils from 'utils/date';
import { StringConstants, dateTime } from 'utils/commonFunctions';
import { Card, Checkbox, Icon, Tooltip, Text, Tag } from 'components/common';
import SkeletonLoader from 'components/SkeletonLoader';
import { ICON_TOOLTIP } from 'utils/commonConstants';

import styles from './style.css';
const primaryTimezone =
  localStorage.getItem('timezone') || StringConstants.defaultTimezone;
/**
 * @param  {Number} index: Index of The Inbox Row in the list
 * @param  {Object} rowItem: defines the data present in the Inbox Row.
 * @param  {bool} isSelected: toggling value for defining if note is selected.
 * @param  {bool} checkboxClicked: toggles values for checkbox click
 * @param  {bool} isRowSelected: sets the style related to selected row, if checkbox is not clicked.
 * @param  {bool} loading: sets style of the button as not-selectable or selectable.
 * @param  {Func} onSelect: Sets the index for the note if selected.
 * @param  {Func} onCheckboxChange: Sets the checkbox value true/false, for the current index of InboxRow
 * @return {JSX} for a Inbox Row.
 */
const InboxRow = ({
  index,
  rowItem,
  isSelected,
  checkboxClicked,
  isRowSelected,
  loading,
  onSelect,
  onCheckboxChange,
  onCallDeselect,
  selectedCall,
}) => {
  const history = useHistory();
  const scrollRef = useRef(null);

  /**
   *
   * UseEffect to
   */
  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTop = parseInt(
        localStorage.getItem('scrollToIdHeight'),
        10,
      );
      scrollRef.current.scrollIntoView({
        block: 'center',
      });
    }
  }, []);

  const getRef = () => {
    if (isSelected) {
      return scrollRef;
    }
    return null;
  };

  /**
   * accepts call Object, Returns true if call direction is inbound else false
   * @param {Object} call
   * @returns {Boolean}
   */
  // if (window.cypress) {
  //   window.checkboxClicked = checkboxClicked;
  //   window.isRowSelected = isRowSelected;
  // }

  const isInBoundCall = call => {
    if (call.direction === 'Inbound') {
      return true;
    }
    return false;
  };

  /**
   *
   * @returns class name for the card, it is dependent on the status of note read/selected status
   */
  const getClassName = () => {
    if (isSelected) {
      return styles.selectedMessage;
    }
    if (rowItem?.messageStatus?.isRead) {
      return styles.readMessage;
    }
    if (
      !rowItem?.messageStatus?.isRead &&
      !rowItem?.messageStatus?.isArchived
    ) {
      return styles.unreadMessage;
    }
    return styles.readMessage;
  };

  return (
    <Card
      hoverable
      bordered={false}
      bodyStyle={{
        padding: '0px',
        marginBottom: 5,
        borderRadius: 4,
      }}
      className={getClassName()}
    >
      <div
        data-testid="inbox-cy"
        ref={getRef()}
        role="button"
        tabIndex="0"
        className={`${styles.searchResults} ${styles.hvrShadow} ${
          isRowSelected && !checkboxClicked ? styles.rowSelected : ''
        }`}
        style={{
          cursor: `${loading ? 'not-allowed' : 'pointer'}`,
          borderRadius: 4,
        }}
        onClick={() => {
          onSelect(isSelected);
          if (selectedCall) onCallDeselect(selectedCall);
          if (history) {
            if (!isSelected) history.push(`/inbox/${rowItem.primaryId}`);
            else history.push('/inbox');
          }
        }}
      >
        <div data-testid="inbox-checkbox-cy">
          <Checkbox
            checked={checkboxClicked && isSelected}
            onClick={e => e.stopPropagation()}
            onChange={e => {
              e.stopPropagation();
              onCheckboxChange(index, e);
            }}
          />
          {/* <div style={{ flex: 1 }} onClick={onSelect} /> */}
        </div>
        <div className={styles.searchResultsContent}>
          <div className={styles.senderDetails}>
            <Text type="subtitleLarge" text={rowItem.createdBy} />
            {rowItem.createdAt ? (
              <Text
                text={moment(
                  dateTime(
                    rowItem.createdAt,
                    'MM/dd/uuuu @ hh:mma',
                    primaryTimezone,
                  ),
                  'MM/DD/YYYY @ hh:mm a',
                ).calendar()}
                type="paragraph"
                className="grey"
              />
            ) : null}
          </div>
          <div className={styles.searchResultsHeaderRow}>
            <div className={styles.callDetailsRow}>
              <>
                {_.isEmpty(rowItem) && (
                  <SkeletonLoader type="icon" data-testid="skeleton-cy" />
                )}
                {rowItem.direction && (
                  <Tooltip
                    title={
                      isInBoundCall(rowItem) ? 'Inbound call' : 'Outbound call'
                    }
                  >
                    <Icon
                      style={{
                        color: isInBoundCall(rowItem) ? '#69A2CB' : '#FFA240',
                        marginRight: '5px',
                      }}
                      type={
                        isInBoundCall(rowItem) ? 'inboundCall' : 'outboundCall'
                      }
                    />
                  </Tooltip>
                )}
              </>
              <SkeletonLoader type="name">
                {rowItem.agentName && (
                  <Text
                    text={rowItem.agentName}
                    type="titleSmall"
                    className="extraBold"
                  />
                )}
              </SkeletonLoader>
              <SkeletonLoader type="line" height={14} width={250}>
                {rowItem.calledAt && (
                  <Text
                    text={dateTime(
                      rowItem.calledAt,
                      ' | MM/dd/uuuu @ hh:mma',
                      primaryTimezone,
                    )}
                    type="paragraph"
                  />
                )}
              </SkeletonLoader>
              <span>
                {rowItem.termCode && (
                  <Tooltip
                    placement="right"
                    type={ICON_TOOLTIP}
                    title="Agent Disposition"
                  >
                    <Tag text={rowItem.termCode} />
                  </Tooltip>
                )}
              </span>
            </div>
          </div>
          <div className={styles.searchResultsDetailsRow}>
            <div />
            <div>
              {rowItem.duration && (
                <Text
                  text={DateUtils.formatDuration(rowItem.duration)}
                  type="paragraph"
                />
              )}
            </div>
          </div>
          <div
            className={`${styles.snippetContainer} ${
              isRowSelected && !checkboxClicked ? styles.noteExpanded : ''
            }`}
          >
            <SkeletonLoader type="line" height={14}>
              {!_.isEmpty(rowItem) && (
                <>
                  <Text
                    text={rowItem.text}
                    type="subtitle"
                    className={`${rowItem.new && !rowItem.read ? '' : 'grey'}`}
                  />
                  <Icon
                    type={
                      isRowSelected && !checkboxClicked ? 'collapse' : 'expand'
                    }
                    size={10}
                    data-testid="cy-expand-collapse"
                  />
                </>
              )}
            </SkeletonLoader>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default InboxRow;

InboxRow.propTypes = {
  rowItem: PropTypes.object,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  checkboxClicked: PropTypes.bool,
  isRowSelected: PropTypes.bool,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  onCheckboxChange: PropTypes.func,
  onCallDeselect: PropTypes.func,
  selectedCall: PropTypes.string,
};
