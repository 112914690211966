const mixpanelKeys = {
  name: 'name',
  description: 'description',
  callId: 'callId',
  pageNumber: 'pageNumber',
  card: 'card',
  eventType: 'eventType',
  speaker: 'speaker',
  smartReportId: 'smartReportId',
  uuid: 'uuidAdhocSearch',
  api: 'api',
  addTags: 'addTagsId',
  tagId: 'tagId',
  removeTags: 'removeTagsId',
  error: 'error',
  word: 'word',
  searchText: 'searchText',
  agent: 'agentId',
  violation: 'violationId',
  dialerService: 'dialerService',
  agentDisposition: 'agentDispositionId',
  advanceFilter: 'advanceFilter',
  filters: 'filters',
  count: 'count',
  accountId: 'accountId',
  savedSearchQuery: 'savedSearchQuery',
  request: 'request',
  templateId: 'Template Id',
  callIds: 'callIds',
  code: 'Code',
  sortFieldList: 'sortFieldList',
  searchQuery: 'searchQuery',
  dimensionFilter: 'dimensionFilter',
  titleId: 'tourReleaseId',
  optionId: 'tourOptionId',
  mentionedEmails: 'mentionedEmails',
  mentionedScorecards: 'mentionedScorecards',
  action: 'action',
  option: 'option',
  statusCode: 'statusCode',
  message: 'message',
  noteId: 'noteId',
  type: 'type',
  selectedValues: 'selectedValues',
  id: 'id',
  text: 'text',
  origSearchQuery: 'origSearchQuery',
  selectAll: 'selectAll',
  rate: 'rate',
  time: 'time',
  metadata: 'metadata',
  call: 'call',
  removeLength: 'removeLength',
  addLength: 'addLength',
  newLength: 'newLength',
  addCustomTagsLength: 'addCustomTagsLength',
  removeCustomTagsLength: 'removeCustomTagsLength',
  deletedTagName: 'deletedTagName',
  sortOrder: 'sortOrder',
  sortField: 'sortField',
  iconName: 'iconName',
  dayNumber: 'dayNumber',
  exportFrequency: 'exportFrequency',
  scheduledExportEmails: 'scheduledExportEmails',
  isReportScheduled: 'isReportScheduled',
  pageSize: 'pageSize',
  email: 'email',
  details: 'details',
  // This will be "Heading" for all the icons without Submenus
  menuName: 'menuName',
  scorecardId: 'Scorecard Id',
  scorecardSectionNumber: 'Section Number',
  auditorEmails: 'Auditor Emails',
  isNoteDisputed: 'isDispute',
  utterance: 'utterance',
  index: 'index',
};
const mixpanelValue = {
  totalCalls: 'Total calls',
  insights: 'Insights',
  agentCompliance: 'Agent Compliance',
  nonCompliantCalls: 'Non Compliant Calls',
  lastWeekSummary: 'Last Week Summary',
};
const mixpanelPageMap = {
  '/search-results': 'Search page',
  '/smart-reports': 'Smart Reports List page',
  '/accounts/search': 'Accounts page',
  '/inbox': 'Inbox page',
  '/users': 'Users List page',
  '/global-config': 'Global Config page',
  '/manage-tags': 'Manage Tags page',
  '/login': 'Login page',
  '/logout': 'Logout page',
  '/unsupported': 'Unsupported page',
  '/calls': 'Single Call page',
  '/scorecard': 'Scorecard page',
  '/visuals': 'Visuals',
  '/agent_visualizations': 'Agent Visualizations',
  '/review-scorecard': 'Review Scorecards',
  '/aggregated-scorecards': 'Aggregated Scorecards',
};
const mixpanelSubpathMap = {
  'smart-reports': 'Smart Report ID',
  calls: 'Single Call ID',
  scorecard: 'Single Call ID',
  visuals: 'Visualization ID',
  'agent-visualizations': 'Agent Visualization ID',
  inbox: 'CallID_NoteId',
};
const mixpanelApiParamMap = {
  fetchSingleCallV2: 'callId',
  notes: 'callId',
  getEventsForReview: 'callId',
  downloadAudioFile: 'filename',
  getTranscriptFile: 'callId',
  singleAccountView: 'accountId',
};
const extractKeys = obj => {
  const parsedObj = {};
  Object.keys(mixpanelKeys).forEach(key => {
    if (obj[key]) parsedObj[mixpanelKeys[key]] = obj[key];
  });
  return parsedObj;
};
export {
  mixpanelKeys,
  mixpanelValue,
  mixpanelPageMap,
  mixpanelSubpathMap,
  mixpanelApiParamMap,
  extractKeys,
};
