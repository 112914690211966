// cspell: ignore Ecode
import React from 'react';
import { notification } from 'antd';

import Button from 'components/common/Button';
import { mixpanelBackend as mixpanel } from 'utils/mixpanel';
import { mixpanelKeys } from 'utils/mixpanelKeys';
import Icon from 'components/common/Icon';
import Logo from 'images/logo.png';

import { getPageName } from './commonFunctions';

const notificationDescription =
  'Please try again in some time. If the error persists, please reach out to us via chat or email us at support@prodigaltech.com';
/**
 * Notification Utility for user needs.
 * @param {String} message : Message String to render.
 * @param {String} statusCode : Status Code of the error.
 * @param {Boolean} title : Title required or not.
 * @param {Object} dataForMixpanel : Extra data for Mixpanel tracking
 */
export const notificationError = (
  message,
  statusCode = '',
  title = false,
  dataForMixpanel = {},
) => {
  const key = `error${Date.now()}`;
  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        notification.close(key);
        mixpanel('Error Notification Closed', {
          description: message,
          ...getPageName(),
          ...dataForMixpanel,
        });
      }}
    >
      Continue
    </Button>
  );
  /**
   * Suitable Description generator, based on message, along with status Code reflection.
   * @returns {String}
   */
  const getDescription = () => {
    const defaultMessage = `${
      title ? '' : `${message ? `${message}.` : ''}`
    } ${notificationDescription}${
      statusCode ? `. \nEcode: ${statusCode}` : ''
    }`;
    switch (message) {
      case 'Unauthorized':
        return `You don't have the required permissions to perform this action. If your access levels were changed recently, Please log out and log back in before trying again. For further assistance, email us at support@prodigaltech.com${
          statusCode ? `. \nEcode: ${statusCode}` : ''
        }`;

      case 'Timeout Error':
        return statusCode === 599
          ? `Looks like it took too long to prepare the results. Please try again with a more narrow search criteria. For further assistance, email us at support@prodigaltech.com${
              statusCode ? `. \nEcode: ${statusCode}` : ''
            }`
          : defaultMessage;

      default:
        return defaultMessage;
    }
  };

  mixpanel('Error Notification Shown', {
    [mixpanelKeys.description]: getDescription(),
    [mixpanelKeys.message]: title ? message : null,
    [mixpanelKeys.statusCode]: statusCode,
    ...getPageName(),
    ...dataForMixpanel,
  });

  /**
   * Sends Out Notification.
   */
  notification.error({
    message: title ? message : null,
    // description: !title ? message || notificationDescription : notificationDescription,
    description: getDescription(),
    btn,
    key,
    duration: 0,
    icon: <Icon size={24} type="warning" style={{ color: 'red' }} />,
  });
  // TODO: modify this code to make this more readable. it is currently confusing
};
export default notification;

const handleDesktopNotification = (name, object) => {
  // Let's check if the browser supports notifications
  // eslint-disable-next-line no-unused-vars
  let notificationTemp;
  if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification');
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === 'granted') {
    mixpanel('Notification Shown', {
      [mixpanelKeys.description]: 'desktop',
      ...getPageName(),
    });
    // If it's okay let's create a notification
    notificationTemp = new Notification(name, { icon: Logo, ...object });
    if (object.onclick) {
      mixpanel('Notification Clicked', {
        [mixpanelKeys.description]: 'desktop',
      });
      notificationTemp.onclick = object.onclick;
    }
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(permission => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        notificationTemp = new Notification(name, { icon: Logo, ...object });
        if (object.onclick) {
          notificationTemp.onclick = object.onclick;
          mixpanel('Notification Clicked', {
            [mixpanelKeys.description]: document.hasFocus() ? 'app' : 'desktop',
          });
        }
      } else {
        mixpanel('Notification Denied', getPageName());
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them any more.
};

const notifyUser = (name, object = {}, onlyDesktopNotification = false) => {
  if (!document.hasFocus()) {
    handleDesktopNotification(name, object);
  } else if (!onlyDesktopNotification) {
    mixpanel('Notification Shown', {
      [mixpanelKeys.description]: 'app',
      ...getPageName(),
    });

    notification.info({
      message: name,
      description: object.body || '',
      onClick: object.onclick || (() => {}),
    });
  }
};

export { notifyUser };
