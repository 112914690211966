import auth0 from 'auth0-js';
import publicIp from 'public-ip';

import { isStringJson, parseJwt } from 'utils/commonFunctions';
import { UNEXPECTED_LOGIN_ERROR } from 'utils/commonConstants';

const { REACT_APP_AUTH0_DOMAIN } = process.env;
const { REACT_APP_AUTH0_CLIENT_ID } = process.env;
const REACT_APP_AUTH0_CALLBACK_URL = `${
  process.env.REACT_APP_WEBSITE_URL
}/callback`;
const { REACT_APP_AUTH0_AUDIENCE } = process.env;
const { REACT_APP_WEBSITE_URL } = process.env;

export default class Auth {
  WebAuth = new auth0.WebAuth({
    domain: REACT_APP_AUTH0_DOMAIN,
    clientID: REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: REACT_APP_AUTH0_CALLBACK_URL,
    audience: REACT_APP_AUTH0_AUDIENCE,
    responseType: 'token id_token',
    scope: 'read:current_user update:current_user_metadata',
  });

  /**
   * Initialize Auth variables.
   */
  constructor() {
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('id_token') &&
      localStorage.getItem('expires_at')
    ) {
      this.accessToken = localStorage.getItem('access_token');
      this.idToken = localStorage.getItem('id_token');
      this.expiresAt = parseFloat(localStorage.getItem('expires_at'));
    }
    // added access token, id token, expired at in class Auth for refresh token functionality to work properly
    this.authorize = this.authorize.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.setSession = this.setSession.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.clearScorecardLocalStoredData = this.clearScorecardLocalStoredData.bind(
      this,
    );
    this.clearLocalStorageValuesWithKeys = this.clearLocalStorageValuesWithKeys.bind(
      this,
    );
  }

  tokenRenewalTimeout;

  scheduleRenewal = () => {
    if (window.location.pathname === '/callback') {
      return;
    }
    const expiresAt = parseFloat(localStorage.getItem('expires_at') || 0);
    if (!expiresAt) {
      return;
    }
    const timeout = expiresAt - Date.now();
    // we will refresh token before 1 minute of expiry
    // this is the case when user is already logged in and using our product
    if (timeout > 60000) {
      clearTimeout(this.tokenRenewalTimeout);
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, timeout - 60000);
    } else {
      // this is the case when user tries to access after token is expired
      localStorage.setItem('redirect_location', window.location.pathname);
      this.renewSession(null, true);
      // passing true to reload page as token is expired
    }
  };

  isScopePresent = (scope, getLength = false) => {
    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
      const parse = parseJwt(accessToken);

      const scopes =
        parse['https://vointell.prodigaltech.com/claims/permissions'];
      if (getLength) {
        return scopes.length;
      }
      // const scopeItems = scopes.split(' ');
      if (scopes?.find(s => s === scope) !== undefined) {
        return true;
      }
      return false;
    }
    return false;
  };

  /**
   * Performs the call if authenticated and reloads if requested.
   * @param {Callback} callback
   * @param {Boolean} reload
   */
  renewSession = (callback, reload = false) => {
    this.WebAuth.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, reload);
        if (callback) callback();
      } else if (err) {
        this.logout();
      }
    });
  };

  /**
   * Performs Authorization.
   * @param {String} username : username to authenticate.
   * @param {Password} password : password to authenticate.
   * @param {Callback} onLoginError : operation on getting error.
   */
  authorize(username, password, onLoginError) {
    this.WebAuth.login(
      {
        realm: 'VoiceAppLogin',
        email: username,
        password,
      },
      errorInfo => {
        if (isStringJson(errorInfo.description)) {
          onLoginError(UNEXPECTED_LOGIN_ERROR);
        } else {
          onLoginError(errorInfo.description);
        }
      },
    );
  }

  handleAuthentication() {
    (async () => {
      const ipv4 = await publicIp.v4();
      localStorage.setItem('ipv4', ipv4);
    })();
    this.WebAuth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, true);
      } else if (err && !this.isAuthenticatedNoRenew()) {
        this.logout();
      } else if (err && this.isAuthenticatedNoRenew()) {
        window.location.replace(process.env.PUBLIC_URL || '/');
      }
    });
  }

  setSession(authResult, isFreshLogin) {
    localStorage.setItem('isLoggedIn', 'true');
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();

    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;
    // updating field values of access token , expires at, id token in class Auth for refresh token to work properly
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt));
    localStorage.setItem('access_token', authResult.accessToken);
    // after updating refresh token we will schedule next renewal of token
    this.scheduleRenewal();
    if (isFreshLogin) {
      const redirectLocation = localStorage.getItem('redirect_location');
      if (redirectLocation && redirectLocation !== '/callback') {
        window.location.replace(redirectLocation);
      } else {
        window.location.replace(process.env.PUBLIC_URL || '/');
      }
    }
    localStorage.removeItem('redirect_location');
  }

  clearLocalStorageValuesWithKeys(keys) {
    keys.forEach(key => localStorage.removeItem(key));
  }

  /**
   * removes scorecard related data from browser.
   */
  clearScorecardLocalStoredData() {
    const keys = Object.keys(localStorage);
    const arrayOfKeysToDelete = ['scorecardTemplateId'];
    // to clear all stored values of scorecard in local storage on logout
    keys.forEach(key => {
      if (key.startsWith('selectedColumnTree')) {
        arrayOfKeysToDelete.push(key);
      }
    });
    this.clearLocalStorageValuesWithKeys(arrayOfKeysToDelete);
  }

  /**
   * deletes local storage.
   * @param {Boolean} bool : Whether to clear scoreCard Local Data or not.
   */
  clearLocalStorage(bool = false) {
    const arrayOfKeysToDelete = [
      'isLoggedIn',
      'access_token',
      'id_token',
      'expires_at',
      'dimension',
    ];
    if (bool) {
      this.clearScorecardLocalStoredData();
    }

    this.clearLocalStorageValuesWithKeys(arrayOfKeysToDelete);
    this.deleteAllCookies();
  }

  /**
   * Deletes all cookies from browser safely.
   */
  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }

  /**
   * Logs out user on request.
   * @param {Boolean} bool : Clear Local Storage or not.
   */
  logout(bool = false) {
    this.clearLocalStorage(bool);
    clearTimeout(this.tokenRenewalTimeout);
    const urlLogout = `https://${REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${encodeURI(
      REACT_APP_WEBSITE_URL,
    )}&client_id=${REACT_APP_AUTH0_CLIENT_ID}`;
    window.location.assign(urlLogout);
    localStorage.setItem('logout', 'true');
  }

  /**
   * returns true if session is valid and still usable else, returns false.
   * @returns {Boolean}
   */
  isAuthenticatedNoRenew() {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      if (new Date().getTime() < expiresAt) {
        return true;
      }
    }
    return false;
  }

  /**
   * returns true if session is valid and still usable else, returns false.
   * @returns {Boolean}
   */
  isAuthenticated() {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      if (new Date().getTime() < expiresAt) {
        return true;
      }
    }
    return false;
  }

  /**
   * Prompts user to change password.
   * @param {String} username : username to change Password.
   * @param {Callback} callback : operation after changing Password.
   */
  handleForgotPassword(username, callback) {
    this.WebAuth.changePassword(
      {
        connection: 'VoiceAppLogin',
        email: username,
      },
      err => {
        callback(err);
      },
    );
  }
}
