/**
 *
 * Component: TagList - Used to show first 3 tags in card view
 * Author: Mayank Agarwal
 * Date: 07/04/2020
 *
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { sortTags } from 'utils/commonFunctions';
import { Text, Tag } from 'components/common';
/**
 * Renders a tag list based on Tag Component, based on props passed.
 * @param  {Object} tags: Data related to tag render.
 * @param  {Object} tagStyle: Style to be applied on the tag.
 */
function TagList({ tags, tagStyle }) {
  /**
   * Memorize list of tags
   * */
  const getTagList = useMemo(() => {
    const getColor = (tagHasColor, tagType = 'user') => {
      if (tagHasColor) return tagHasColor;
      return tagType === 'user' ? 'orange' : 'blue';
    };

    const sortedTags = sortTags(tags);

    /**
     * Reduce list to first 5 tags
     * */
    const reducedList = sortedTags.slice(0, 5);
    const tagsLayout = reducedList.map(tag => (
      // eslint-disable-next-line no-underscore-dangle
      <div key={tag._id}>
        <Tag
          color={getColor(tag.color, tag.type)}
          visible={tag.type !== 'hidden'}
          className={tag.type === 'system' ? 'system-tag' : null}
          style={tagStyle}
        >
          <Text text={tag.name} type="tag" />
        </Tag>
      </div>
    ));

    if (tags?.length > 5)
      tagsLayout.push(
        <div key="last" data-testid="last-tag-cy">
          <Tag style={tagStyle} visible>
            {`+${tags.length - 5} more`}
          </Tag>
        </div>,
      );
    return tagsLayout;
  }, [JSON.stringify(tags)]);

  return <>{getTagList}</>;
}

TagList.propTypes = {
  tags: PropTypes.array,
  tagStyle: PropTypes.object,
};

export default TagList;
