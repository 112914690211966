/* cspell: ignore sider */

import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the sider state domain
 */

const selectSiderDomain = state => state.sider || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Sider
 */

const makeSelectSider = f =>
  createSelector(
    selectSiderDomain,
    subState => (f ? subState[f] : subState),
  );

export default makeSelectSider;
export { selectSiderDomain };
