/**
 *
 * SortSelection
 *
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components/common';

import './style.less';

function SortSelection({
  selectedValue,
  onClickOutside,
  onSortApplied,
  showSort,
  sortList,
  sortKeyId,
}) {
  const [selectedSort, setSelectedSort] = useState(selectedValue);

  useEffect(() => {
    setSelectedSort(selectedValue);
  }, [selectedValue]);

  const sortValuesWrapper = useRef();

  useEffect(() => {
    document.addEventListener('mouseup', handleClickEvent, false);
    return () => {
      document.removeEventListener('mouseup', handleClickEvent, false);
    };
  }, [handleClickEvent]);

  /**
   * detects the outside click from component, else returns false if not.
   * @param {*} e
   */
  const handleClickEvent = useCallback(
    e => {
      if (sortValuesWrapper.current) {
        if (
          sortValuesWrapper.current.contains(e.target) ||
          e.target.id === sortKeyId
        ) {
          // The click is inside, continue to whatever you are doing
          return false;
        }
        e.preventDefault();
        handleClickOutside(e);
      }
      return false;
    },
    [handleClickOutside, sortKeyId],
  );

  const handleClickOutside = useCallback(() => {
    onClickOutside(sortKeyId);
  }, [onClickOutside, sortKeyId]);

  const onValueChange = value => {
    setSelectedSort(value);
    onSortApplied(sortKeyId, value);
  };
  return (
    <div className="sort-box-container" ref={sortValuesWrapper}>
      {showSort && (
        <div className="sort-box">
          {sortList.length ? (
            <div>
              <div className="sort-content">
                {sortList.map(sortKey => (
                  <div key={sortKey._id}>
                    <span
                      data-testid="button-cy"
                      role="button"
                      tabIndex={0}
                      className={`sort-row cursor-pointer ${
                        selectedSort === sortKey._id ? 'bg-selected' : ''
                      }`}
                      onClick={e => {
                        e.stopPropagation();
                        onValueChange(sortKey._id);
                      }}
                    >
                      {sortKey.name}
                      <Icon
                        type={sortKey.sortType === 1 ? 'arrowUp' : 'arrowDown'}
                      />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default SortSelection;

SortSelection.propTypes = {
  selectedValue: PropTypes.object,
  onClickOutside: PropTypes.func,
  onSortApplied: PropTypes.func,
  showSort: PropTypes.bool,
  sortList: PropTypes.array,
  sortKeyId: PropTypes.string,
};
