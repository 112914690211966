/**
 *
 * Container: HardReset
 * Date: 21/7/2021
 *
 */

import React, { useEffect } from 'react';
import { clear } from 'idb-keyval';

import { LoadingIndicator } from 'components';
import mixpanel from 'utils/mixpanel';
import { parseJwt } from 'utils/commonFunctions';
import { getLocalStorageValue } from 'utils/common/scorecardList';

import styles from './style.css';
/**
 * Resets all configuration for the app.
 * @returns {JSX}
 */
export function HardReset() {
  const getPermissions = () => {
    const accessTokenStorage = getLocalStorageValue('access_token');
    const parseJwtValue = parseJwt(accessTokenStorage);
    if (!parseJwtValue) {
      return '';
    }
    const aud = Array.isArray(parseJwtValue.aud)
      ? parseJwtValue.aud[0]
      : parseJwtValue.aud;
    return parseJwtValue[`${aud}/claims/permissions`];
  };
  /**
   * Clears the storage, adds MixPanel Event and then redirects to /.
   */
  const handleHardReset = async () => {
    let cacheLength = 0;
    const cookiesLength = document.cookie?.split('; ')?.length;
    const localStorageLength = localStorage.length;

    // delete cache
    await caches.keys().then(cacheNames => {
      cacheLength = cacheNames.length;
      Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
    });

    // add Mixpanel event
    mixpanel('Hard Reset', {
      cacheLength,
      cookiesLength,
      localStorageLength,
      permissionsOfUser: getPermissions(),
      volumeOfAudioPlayer: getLocalStorageValue(
        `clientAudioVolume$${getLocalStorageValue('userEmail')}`,
      ),
      scorecardTemplateIds: getLocalStorageValue('scorecardTemplateId'),
      collapsedStateOfSidebar: getLocalStorageValue('collapsed'),
      tokenExpiresAt: getLocalStorageValue('expires_at'),
    });

    // localStorage clear
    localStorage.clear();

    // cookies clear
    (function deleteAllCookies() {
      const cookies = document.cookie.split(';');

      for (let i = 0; i < cookies.length; i += 1) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
    })();

    // indexedDB clean
    clear();

    // service worker uninstall
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        // eslint-disable-next-line no-restricted-syntax
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }

    // reload page so that it redirects to login
    setTimeout(() => {
      window.location.assign(process.env.PUBLIC_URL || '/');
    }, 1000);
  };

  useEffect(() => {
    handleHardReset();
  }, []);

  return (
    <div className={styles.container}>
      <LoadingIndicator text="Resetting App..." />
    </div>
  );
}

export default HardReset;
