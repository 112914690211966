/**
 *
 * Component: EmailHandler
 * Date: 7/11/2022
 * Handles the Email part of configuration including add,Edit and Delete functionalities
 */

import React, { useState, forwardRef, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Text } from 'components/common';

import styles from './style.css';
import EmailInput from './EmailInput';

const EmailHandler = forwardRef(
  (
    {
      setShouldEnableScheduleMail,
      selectedExportReport,
      setIsEditing,
      loading,
      emailInputNotModifiedError,
      setEmailInputNotModifiedError,
    },
    autoExportInfoRef,
  ) => {
    const [mailList, setMailList] = useState(
      selectedExportReport?.schedule?.isScheduled
        ? selectedExportReport?.schedule?.recipientEmails ?? []
        : [],
    );

    useEffect(() => {
      setShouldEnableScheduleMail(mailList?.length >= 1);
      /**
       * Checks if all the mails are present in the mailList
       */
      if (
        _.xor(mailList, selectedExportReport?.schedule?.recipientEmails)
          ?.length === 0
      ) {
        setIsEditing(false);
      } else {
        setIsEditing(true);
      }
      /**
       * Updates the Export info
       */
      // eslint-disable-next-line no-param-reassign
      autoExportInfoRef.current = {
        ...autoExportInfoRef.current,
        recipientEmails: mailList,
      };
    }, [mailList]);

    return (
      <div className={styles.mailDiv}>
        <Text text="Enter Email" className="emailTitle" />
        <Text
          text="Please use comma separated values or space to add multiple emails."
          className="emailSubtitle"
        />

        <EmailInput
          loading={loading}
          setMailList={setMailList}
          initialEmailList={
            selectedExportReport?.schedule?.isScheduled
              ? selectedExportReport?.schedule?.recipientEmails ?? []
              : []
          }
          emailInputNotModifiedError={emailInputNotModifiedError}
          setEmailInputNotModifiedError={setEmailInputNotModifiedError}
        />
      </div>
    );
  },
);

EmailHandler.propTypes = {
  loading: PropTypes.bool,
  setShouldEnableScheduleMail: PropTypes.func,
  setIsEditing: PropTypes.func,
  selectedExportReport: PropTypes.object,
  emailInputNotModifiedError: PropTypes.bool,
  setEmailInputNotModifiedError: PropTypes.func,
};

export default EmailHandler;
