/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Input, Text, Icon, Tooltip } from 'components/common';
import { validateEmail } from 'utils/commonFunctions';
import { TEXT_TOOLTIP } from 'utils/commonConstants';

import styles from './style.css';
import {
  ALLOWED_EMAIL_COUNT,
  INVALID_EMAIL,
  OVER_LIMIT_ERROR_MESSAGE,
  CHIP_CLOSE_ICON,
  EMPTY_EMAIL_INPUT_ERROR,
} from './constants';

const EmailInput = ({
  initialEmailList = [],
  setMailList,
  emailInputNotModifiedError,
  setEmailInputNotModifiedError,
  loading,
}) => {
  const [currentInputValue, setCurrentInputValue] = useState('');
  const [emailChips, setEmailChips] = useState(initialEmailList);
  const [errorMessage, setErrorMessage] = useState('');

  const formEndRef = useRef(null);
  const inputBoxRef = useRef(null);

  /**
   * Add trimmed string to the chips list
   * @param {String} email
   * @returns undefined
   */
  const addEmailChip = (email, clearCurrentInput = true) => {
    const cleanEmail = email?.trim()?.toLowerCase();
    if (!cleanEmail) return;
    if (clearCurrentInput && currentInputValue) setCurrentInputValue('');
    if (emailChips.includes(cleanEmail)) {
      const indexOfChip = emailChips.indexOf(cleanEmail);
      handleChipRemove(indexOfChip);
    }
    setEmailChips(allEmail => [...allEmail, cleanEmail]);
  };

  /**
   * Handle Input box change for emails.
   * @param {HTML element} e
   * @param {boolean} forceAdd: to add the string to chip collection directly without regex match
   */
  const handleCurrentInputChange = (e, forceAdd = false) => {
    if (loading) return;
    const inputString = e.target.value;
    setCurrentInputValue(inputString?.toLowerCase());
    if (inputString && (inputString.match(/\s|,/g) || forceAdd)) {
      inputString.split(/\s|,/g).forEach(string => {
        addEmailChip(string);
      });
    }
  };

  /**
   * Enter key handler
   * @param {HTMLElement} e
   */
  const handleEnterPress = e => {
    const inputString = e.target.value;
    addEmailChip(inputString);
  };

  /**
   * Handler which gets called when focus moves out, i.e. on mouse leaves the area
   * - adds the input content to chip collection
   * - than moves the focus of input box into unfocused state.
   */
  const handleFocusChange = () => {
    const inputString = currentInputValue;
    addEmailChip(inputString);
    document.activeElement.blur();
  };

  /**
   * Removes the string present at the given index of the chip collection
   * @param {Number} index : index of the item to remove from array
   */
  const handleChipRemove = index => {
    const emailChipCopy = emailChips;
    emailChipCopy.splice(index, 1);
    setEmailChips([...emailChipCopy]);
  };

  /**
   * Called when clicked on the chip, making the content to go into edit state.
   * @param {HTMLElement} e : HTML element from event handler
   * @param {Number} index : index of the item in the chip collection
   */
  const handleChipEdit = (e, index) => {
    if (!e.target.classList.contains(CHIP_CLOSE_ICON)) {
      const emailText = emailChips[index];
      const currentEmail = inputBoxRef?.current?.input?.value || '';
      handleChipRemove(index);
      addEmailChip(currentEmail, false);
      setCurrentInputValue(emailText?.toLowerCase());
      inputBoxRef.current.focus();
    }
  };

  /**
   * Validates if input email is correct or not.
   * @param {String} emailToCheck
   * @returns boolean
   */
  const isEmailInvalid = emailToCheck => {
    const isInvalid = !validateEmail(emailToCheck);
    if (isInvalid && errorMessage !== 'Invalid Email')
      setErrorMessage('Invalid Email');
    return isInvalid;
  };

  /**
   * Used to display only the first part before the '@' of the email
   * @param {String} email
   * @returns String
   */
  const displayShortenEmail = email => {
    if (!email) return '';
    const initialEmail = email.split('@')[0];
    if (!initialEmail) return email;
    return initialEmail;
  };

  const hasValidEmailCount = () => {
    if (emailChips.length <= ALLOWED_EMAIL_COUNT) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    formEndRef.current.scrollIntoView({
      block: 'end',
      inline: 'end',
    });

    if (emailInputNotModifiedError) setEmailInputNotModifiedError(false);
  }, [currentInputValue]);

  useEffect(() => {
    if (errorMessage) setErrorMessage('');
    if (emailInputNotModifiedError) setEmailInputNotModifiedError(false);
  }, [emailChips]);

  useEffect(() => {
    if (!errorMessage && emailChips.length) setMailList([...emailChips]);
    else setMailList([]);
  }, [emailChips, errorMessage]);

  /**
   * Generates error message based on the current state of the component
   * @returns String
   */
  const getErrorMessage = () => {
    if (!hasValidEmailCount()) return OVER_LIMIT_ERROR_MESSAGE;
    if (errorMessage === INVALID_EMAIL) return INVALID_EMAIL;
    if (emailInputNotModifiedError && emailChips.length < 1)
      return EMPTY_EMAIL_INPUT_ERROR;
    return '';
  };

  /**
   * Move focus to the end of the input element using auto scroll
   */
  const focusToInputEnd = () => {
    formEndRef.current.focus();
  };

  const emailContainerMemo = useMemo(
    () =>
      emailChips.map((email, index) => (
        <Tooltip
          type={TEXT_TOOLTIP}
          title={email}
          overlayClassName="email-chip-tooltip"
        >
          <span
            data-testid="emailChip"
            // eslint-disable-next-line react/no-array-index-key
            key={`${email}-${index}`}
            className={
              isEmailInvalid(email) ? styles.invalidEmailChip : styles.emailChip
            }
            onClick={e => !loading && handleChipEdit(e, index)}
          >
            <Text text={displayShortenEmail(email)} className="chipText" />
            <Icon
              type="close"
              className={CHIP_CLOSE_ICON}
              onClick={() => !loading && handleChipRemove(index)}
            />
          </span>
        </Tooltip>
      )),
    [emailChips, errorMessage],
  );

  return (
    <>
      <div
        className={styles.emailInputContainer}
        onMouseLeave={handleFocusChange}
      >
        <section className={styles.chipsContainer} onClick={focusToInputEnd}>
          {emailContainerMemo}
        </section>
        <Input
          value={currentInputValue}
          onChange={handleCurrentInputChange}
          onPressEnter={handleEnterPress}
          className={styles.emailInputBoxStyle}
          height={34}
          ref={inputBoxRef}
          placeholder="example@prodigaltech.com"
        />
        <div ref={formEndRef} />
      </div>

      <div className={styles.errorContainer}>
        <span className={styles.errorMessage} data-testid="exportErrorMessage">
          {getErrorMessage()}
        </span>
      </div>
    </>
  );
};

EmailInput.propTypes = {
  initialEmailList: PropTypes.array,
  setMailList: PropTypes.func,
  emailInputNotModifiedError: PropTypes.bool,
  setEmailInputNotModifiedError: PropTypes.func,
  loading: PropTypes.bool,
};

export default EmailInput;
