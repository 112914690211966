/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
/**
 *
 * Component: AutoExportModal
 * Date: 10/11/2022
 * Handles the Modal where configuration of Date and Email happens for Scheduled Exports
 */

import React, { useRef, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import moment from 'moment';

import { notificationError } from 'utils/notification';
import { Modal, Text, Button, message, Icon } from 'components/common';
import FrequencyHandler from 'components/FrequencyHandler';
import EmailHandler from 'components/EmailHandler';
import { canExportToday, getMomentDate } from 'utils/commonFunctions';
import mixpanel from 'utils/mixpanel';
import { mixpanelKeys } from 'utils/mixpanelKeys';

import { autoExportReducer } from './reducer';
import {
  initAutoExportState,
  SET_IS_POPOVER_VISIBLE,
  SET_IS_EDITING_FREQUENCY,
  SET_IS_EDITING_EMAIL,
  SET_SHOULD_ENABLE_SCHEDULE_EMAIL,
  SET_SHOULD_ENABLE_SCHEDULE_FREQUENCY,
  SET_EMAIL_INPUT_ERROR,
  SET_FREQUENCY_INPUT_ERROR,
} from './constants';
import styles from './style.css';
import {
  patchScheduleSmartReportExport,
  patchUnscheduleSmartReportExport,
} from './api';

const AutoExportModal = ({
  isExportModalVisible,
  setIsExportModalVisible,
  selectedExportReport,
  fetchSmartReports,
}) => {
  const autoExportInfoRef = useRef({});

  const [state, dispatch] = useReducer(autoExportReducer, initAutoExportState);

  const {
    isPopoverVisible,
    shouldEnableScheduleMail,
    shouldEnableScheduleFrequency,
    isEditingEmail,
    isEditingFrequency,
    frequencyInputNotModifiedError,
    emailInputNotModifiedError,
  } = state;

  const handleDispatch = (type, payload) => dispatch({ type, payload });

  const setIsPopoverVisible = val => {
    handleDispatch(SET_IS_POPOVER_VISIBLE, { isPopoverVisible: val });
  };

  const setShouldEnableScheduleMail = val => {
    handleDispatch(SET_SHOULD_ENABLE_SCHEDULE_EMAIL, {
      shouldEnableScheduleMail: val,
    });
  };
  const setShouldEnableScheduleFrequency = val => {
    handleDispatch(SET_SHOULD_ENABLE_SCHEDULE_FREQUENCY, {
      shouldEnableScheduleFrequency: val,
    });
  };
  const setIsEditingEmail = val => {
    handleDispatch(SET_IS_EDITING_EMAIL, {
      isEditingEmail: val,
    });
  };
  const setIsEditingFrequency = val => {
    handleDispatch(SET_IS_EDITING_FREQUENCY, {
      isEditingFrequency: val,
    });
  };
  const setEmailInputNotModifiedError = val => {
    handleDispatch(SET_EMAIL_INPUT_ERROR, {
      emailInputNotModifiedError: val,
    });
  };
  const setFrequencyInputNotModifiedError = val => {
    handleDispatch(SET_FREQUENCY_INPUT_ERROR, {
      frequencyInputNotModifiedError: val,
    });
  };

  /**
   *
   * @param {*} frequency cadence of Reports
   * @param {*} dayNumber dayNumber applicable for Monthly and weekly Exports
   * @returns Next Export date based on Frequency and dayNumber selected
   * accounts for cases when the dayNumber selected is greater than days in the month
   */

  const getExportDate = (frequency, dayNumber) => {
    const { exportDate, isExportPossible } = canExportToday();

    if (!frequency) {
      return null;
    }

    const todayDateNumber = moment().date();
    const momentDayNumber = dayNumber - 1;
    // as we want to add days to the start of the month/Week

    const startOfMonth = getMomentDate('startOf', 'months');
    const lastDayOfMonth = getMomentDate('endOf', 'months');
    const startOfNextMonth = getMomentDate('startOf', 'months', 1);
    const lastDayOfNextMonth = getMomentDate('endOf', 'months', 1);

    const startOfWeek = getMomentDate('startOf', 'weeks');
    const todayWeekDayNumber = moment().day() + 1;
    // as days of the week on moment is 0-6
    const startOfNextWeek = getMomentDate('startOf', 'weeks', 1);

    switch (frequency) {
      case 'daily':
        return exportDate;
      case 'weekly':
        if (dayNumber) {
          if (todayWeekDayNumber < dayNumber) {
            return startOfWeek.add(momentDayNumber, 'days');
          }
          if (todayWeekDayNumber === dayNumber) {
            if (isExportPossible) {
              return moment();
            }
          }
          return startOfNextWeek.add(momentDayNumber, 'days');
        }
        return null;

      case 'monthly':
        if (dayNumber) {
          if (dayNumber < todayDateNumber) {
            if (dayNumber < lastDayOfNextMonth.date()) {
              return startOfNextMonth.add(momentDayNumber, 'days');
            }
            return lastDayOfNextMonth;
          }
          if (dayNumber === todayDateNumber) {
            if (isExportPossible) {
              return moment();
            }
            if (dayNumber > lastDayOfNextMonth.date()) {
              return lastDayOfNextMonth;
            }
            return startOfNextMonth.add(momentDayNumber, 'days');
          }
          if (dayNumber > todayDateNumber) {
            if (dayNumber > lastDayOfMonth.date()) {
              return lastDayOfMonth;
            }
            return startOfMonth.add(momentDayNumber, 'days');
          }
        }
        return null;

      default:
        return null;
    }
  };

  const buildNextExportMessage = () => {
    const frequency = autoExportInfoRef.current?.frequency;
    const dayNumber = autoExportInfoRef.current?.dayNumber;
    const nextExportDate = getExportDate(frequency, dayNumber);
    if (nextExportDate) {
      return `Next Export will be on ${nextExportDate.format(
        'MMMM Do YYYY, dddd',
      )}`;
    }
    return '';
  };

  const {
    mutate: scheduleAutoExports,
    isLoading: scheduleLoading,
  } = useMutation(patchScheduleSmartReportExport, {
    onSuccess: (data, variables) => {
      message.success('Scheduled successfully');
      fetchSmartReports();
      setIsExportModalVisible(false);
      mixpanel('Scheduled Export API Status', {
        variables,
        success: true,
        data,
      });
    },
    onError: (err, variables) => {
      notificationError(
        err?.response?.data?.message,
        err?.response?.status,
        true,
      );
      mixpanel('Scheduled Export API Status', {
        variables,
        success: false,
        errorMessage: err.message,
      });
    },
  });

  const {
    mutate: unscheduleAutoExports,
    isLoading: unscheduleLoading,
  } = useMutation(patchUnscheduleSmartReportExport, {
    onSuccess: (data, variables) => {
      message.success('Unscheduled successfully');
      fetchSmartReports();
      setIsExportModalVisible(false);
      mixpanel('Unscheduled Export API Status', {
        variables,
        success: true,
        data,
      });
    },
    onError: (variables, err) => {
      notificationError(
        err?.response?.data?.message,
        err?.response?.status,
        true,
      );
      mixpanel('Scheduled Export API Status', {
        variables,
        success: false,
        errorMessage: err.message,
      });
    },
  });

  /**
   * memoization saves unwanted rerenders required to maintain Schedule and Unschedule states for buttons
   */
  const memoizedEmailHandlerAndText = useMemo(
    () => (
      <>
        <Text
          text="Scheduled Auto-Export"
          className="modal-title-left autoExportModalTitle"
        />
        <div className={styles.smartReportName}>
          <Text
            text={selectedExportReport?.name}
            type="subtitle"
            className="autoExportTitle"
          />
        </div>
        <EmailHandler
          ref={autoExportInfoRef}
          setShouldEnableScheduleMail={setShouldEnableScheduleMail}
          selectedExportReport={selectedExportReport}
          setIsEditing={setIsEditingEmail}
          loading={unscheduleLoading || scheduleLoading}
          emailInputNotModifiedError={emailInputNotModifiedError}
          setEmailInputNotModifiedError={setEmailInputNotModifiedError}
        />
      </>
    ),
    [
      selectedExportReport,
      unscheduleLoading,
      scheduleLoading,
      emailInputNotModifiedError,
    ],
  );

  const memoizedFrequencyHandler = useMemo(
    () => (
      <FrequencyHandler
        setIsPopoverVisible={setIsPopoverVisible}
        isPopoverVisible={isPopoverVisible}
        ref={autoExportInfoRef}
        selectedExportReport={selectedExportReport}
        setShouldEnableScheduleFrequency={setShouldEnableScheduleFrequency}
        setIsEditing={setIsEditingFrequency}
        loading={unscheduleLoading || scheduleLoading}
        frequencyInputNotModifiedError={frequencyInputNotModifiedError}
        setFrequencyInputNotModifiedError={setFrequencyInputNotModifiedError}
      />
    ),
    [
      selectedExportReport,
      unscheduleLoading,
      scheduleLoading,
      isPopoverVisible,
      frequencyInputNotModifiedError,
    ],
  );

  /**
   * Handler to schedule a smart report
   * - checks for possible error, by detecting if input is changed for email
   *   and frequency input, if not, send signal by prop update to child to detect errors
   * - If changes are done on inputs, than schedule the report and log to mixpanel
   */
  const handleScheduleExports = () => {
    if (!shouldEnableScheduleMail) setEmailInputNotModifiedError(true);
    if (!shouldEnableScheduleFrequency) setFrequencyInputNotModifiedError(true);
    let actionStatus = 'failed';
    if (shouldEnableScheduleFrequency && shouldEnableScheduleMail) {
      scheduleAutoExports({
        requestParams: autoExportInfoRef.current,
        reportId: selectedExportReport._id,
      });
      actionStatus = 'success';
    }
    mixpanel('Scheduled Export', {
      [mixpanelKeys.smartReportId]: selectedExportReport._id,
      [mixpanelKeys.exportFrequency]: autoExportInfoRef.current?.frequency,
      [mixpanelKeys.dayNumber]: autoExportInfoRef.current?.dayNumber,
      [mixpanelKeys.scheduledExportEmails]:
        autoExportInfoRef.current?.recipientEmails,
      actionStatus,
    });
  };

  /**
   * handler to unschedule a smart report
   */
  const handleUnscheduleReport = () => {
    unscheduleAutoExports(selectedExportReport._id);
    mixpanel('Unscheduled Export', {
      [mixpanelKeys.smartReportId]: selectedExportReport._id,
    });
  };

  return (
    <Modal
      visible={isExportModalVisible}
      width="550px"
      bodyStyle={{
        maxHeight: 900,
        overflowY: 'auto',
        top: 20,
        padding: 40,
      }}
      wrapClassName="vertical-top-modal schedule-export-modal"
      style={{ top: 20 }}
      destroyOnClose
      closable
      onCancel={() => setIsExportModalVisible(false)}
      maskClosable={false}
      closeIcon={<Icon size={14} type="close" className="modalCloseIcon" />}
    >
      <div role="tab" tabIndex={0} onClick={() => setIsPopoverVisible(false)}>
        {memoizedEmailHandlerAndText}
        {memoizedFrequencyHandler}
        <div className={styles.nextExportText} data-testid="nextExportTime">
          <Text
            text={buildNextExportMessage()}
            className="frequencyHandlerMessage"
          />
        </div>

        <div className={styles.modalButtonsDiv}>
          <div>
            <Button
              text="Cancel"
              onClick={() => {
                setIsExportModalVisible(false);
              }}
              className={styles.scheduleButton}
            />
          </div>
          <div>
            {!selectedExportReport?.schedule?.isScheduled ||
            (isEditingEmail || isEditingFrequency) ? (
              <Button
                type="primary"
                data-testid="schedule-autoExport"
                onClick={handleScheduleExports}
                text="Schedule"
                loading={scheduleLoading}
                showLoadingIconOnly
              />
            ) : (
              <Button
                type="primary"
                data-testid="unSchedule-autoExport"
                onClick={handleUnscheduleReport}
                text="Unschedule"
                loading={unscheduleLoading}
                showLoadingIconOnly
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

AutoExportModal.propTypes = {
  isExportModalVisible: PropTypes.bool,
  setIsExportModalVisible: PropTypes.func,
  fetchSmartReports: PropTypes.func,
  selectedExportReport: PropTypes.object,
};

export default AutoExportModal;
