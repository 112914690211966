const SET_IS_POPOVER_VISIBLE = 'SET_IS_POPOVER_VISIBLE';
const SET_SHOULD_ENABLE_SCHEDULE_EMAIL = 'SET_SHOULD_ENABLE_SCHEDULE_EMAIL';
const SET_SHOULD_ENABLE_SCHEDULE_FREQUENCY =
  'SET_SHOULD_ENABLE_SCHEDULE_FREQUENCY';
const SET_IS_EDITING_EMAIL = 'SET_IS_EDITING_EMAIL';
const SET_IS_EDITING_FREQUENCY = 'SET_IS_EDITING_FREQUENCY';
const SET_FREQUENCY_INPUT_ERROR = 'SET_FREQUENCY_INPUT_ERROR';
const SET_EMAIL_INPUT_ERROR = 'SET_EMAIL_INPUT_ERROR';

const initAutoExportState = {
  isPopoverVisible: false,
  shouldEnableScheduleMail: false,
  shouldEnableScheduleFrequency: false,
  isEditingEmail: false,
  isEditingFrequency: false,
  emailInputNotModifiedError: false,
  frequencyInputNotModifiedError: false,
};

export {
  SET_IS_EDITING_FREQUENCY,
  SET_IS_EDITING_EMAIL,
  SET_IS_POPOVER_VISIBLE,
  SET_SHOULD_ENABLE_SCHEDULE_EMAIL,
  SET_SHOULD_ENABLE_SCHEDULE_FREQUENCY,
  SET_FREQUENCY_INPUT_ERROR,
  SET_EMAIL_INPUT_ERROR,
  initAutoExportState,
};
