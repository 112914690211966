import {
  FLOOR_TIME,
  SET_AUTO_SCROLL,
  SET_SEARCH_INDEX,
  ON_SEARCH_CLICK,
  ON_SEARCH_CLOSE,
  ON_SEARCH,
  ON_FIRST_SCROLL,
  SET_ALLOW_SEARCH_FROM_QUERY,
} from './constants';

function transcriptReducer(state, action) {
  switch (action.type) {
    case FLOOR_TIME: {
      const { floorCurrentTime } = action.payload;
      return { ...state, floorCurrentTime };
    }
    case SET_ALLOW_SEARCH_FROM_QUERY: {
      const { isSearchFromQueryAllowed } = action.payload;
      return { ...state, isSearchFromQueryAllowed };
    }
    case SET_AUTO_SCROLL: {
      const { autoScroll } = action.payload;
      return { ...state, autoScroll };
    }
    case SET_SEARCH_INDEX: {
      const { currentSearchIndex } = action.payload;
      return { ...state, currentSearchIndex };
    }
    case ON_SEARCH_CLOSE: {
      const { isSearchIconVisible, searchInput } = action.payload;
      return { ...state, isSearchIconVisible, searchInput };
    }
    case ON_SEARCH_CLICK: {
      const { isSearchIconVisible } = action.payload;
      return { ...state, isSearchIconVisible };
    }
    case ON_SEARCH: {
      const { searchInput } = action.payload;
      return { ...state, searchInput };
    }
    case ON_FIRST_SCROLL: {
      const { scrolled } = action.payload;
      return { ...state, scrolled };
    }
    default:
      throw new Error();
  }
}

export default transcriptReducer;
