// cspell: ignore stime
/**
 *
 * Component: CallTranscriptPrint
 * Date: 24/8/2021
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { PRODIGAL_LOGO_LIGHT } from 'utils/commonConstants';
import MetaView from 'components/MetaView';
import { Text } from 'components/common';
import { dateTime, StringConstants } from 'utils/commonFunctions';

import styles from './style.css';

/**
 * Call Transcript Printing React Component.
 * @param {Obj} props.call: information about the call like seconds, callId, etc.
 * @returns JSX for Call Transcript Component.
 */
function CallTranscriptPrint({ call }) {
  /**
   * @param {Object} t: Call Transcript object from array
   * @returns {String} for color string, i.e, orange, blue and black
   */
  const getColor = t => {
    if (call.speakers) {
      if (call.speakers.agent === t.speaker) {
        return 'orange';
      }
      return 'blue';
    }

    return 'black';
  };

  /**
   * @param {Obj} t: Call Transcript object from array
   * @returns {String} for title based on the agent.
   */
  const getTitle = t => {
    if (call.speakers) {
      if (call.speakers.agent === t.speaker) {
        return 'Agent ';
      }
      return 'Customer ';
    }

    return 'Segment ';
  };

  /**
   * @param {Number} time: Time in Seconds.
   * @param {Bool} showHours: Default false, else true to show hours
   * @returns {String} for a formatted time string.
   */
  const formatTimeAddPadding = (time, showHours = false) => {
    const hours = parseInt(time / 3600, 10);
    const minutes = parseInt(time / 60, 10);
    const seconds = parseInt(time - minutes * 60, 10);

    if (showHours) {
      return `${hours.toString().padStart(2, 0)}:${minutes
        .toString()
        .padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`;
    }
    return `${minutes.toString().padStart(2, 0)}:${seconds
      .toString()
      .padStart(2, 0)}`;
  };

  const primaryTimezone =
    localStorage.getItem('timezone') || StringConstants.defaultTimezone;
  return (
    <>
      <div className={styles.singleCallMediaHiddenMetaData}>
        <img
          src={PRODIGAL_LOGO_LIGHT}
          className={styles.prodigalLogoLight}
          alt="prodigal-logo"
        />
        <div className={styles.metaDataList}>
          <MetaView
            callIdToShowInPDF={call.callId}
            listOfFields={[
              ...(call?.metadataList?.filter(
                metaDataArg =>
                  !['id', 'display_filename'].includes(metaDataArg.field),
              ) || []),
            ]}
          />
          <div className={styles.detailsContainer}>
            <Text text="Call Date:" type="paragraphLight" />
            <Text
              type="paragraph"
              text={dateTime(
                call.calledAt || call.timestamp || 0,
                'dd MMM uuuu @ h:mma',
                primaryTimezone,
              )}
            />
          </div>
          <div className={styles.detailsContainer}>
            <Text text="Call Link:" type="paragraphLight" />
            <span className={styles.metadataFieldValue}>
              <a href={`${window.location.origin}/calls/${call.callId}`}>
                Prodigal URL
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.singleCallMediaTranscript}>
        {call.transcript
          ? call.transcript.map(t => (
              <div
                key={`${t.stime}-${t.etime}`}
                className={styles.transcript}
                data-stime={t.stime}
                data-etime={t.etime}
              >
                <div className={styles.agent}>
                  <Text
                    type="subtitle"
                    className={getColor(t)}
                    text={getTitle(t)}
                  />
                  <Text
                    type="subtitleSmall"
                    text={`${formatTimeAddPadding(
                      t.stime,
                    )} - ${formatTimeAddPadding(t.etime)}`}
                  />
                </div>
                <div className={styles.transcriptText}>{t.text}</div>
              </div>
            ))
          : null}
      </div>
    </>
  );
}

CallTranscriptPrint.propTypes = {
  call: PropTypes.object,
};

export default CallTranscriptPrint;
