import mixpanel from 'mixpanel-browser';
import axios from 'axios';

import { getPageName } from 'utils/common/pageName';

import { regionUrlMap } from './commonConstants';

const TOKEN = process.env.MIXPANEL_TOKEN;

mixpanel.init(TOKEN, {
  opt_out_tracking_by_default: true,
});

export { mixpanel };

const baseURLByRegion = () => {
  const region = localStorage.getItem('region');
  return regionUrlMap[region];
};

const mixpanelFunction = (eventName, object = {}) => {
  const path = getPageName();
  const properties = { ...object, ...path };
  mixpanel.track(eventName, { ...properties });
};
export default mixpanelFunction;

function mixpanelBackend(eventName, object = {}) {
  const accessToken = localStorage.getItem('access_token');
  const baseURL = baseURLByRegion();
  if (!baseURL) return;

  const fetch = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'Content-Encoding': 'gzip',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const email = localStorage.getItem('userEmail');
  const prodigal = localStorage.getItem('user_name');
  const domain = localStorage.getItem('domain');
  const region = localStorage.getItem('region');
  const tenant = localStorage.getItem('tenant');
  const accessGroup = localStorage.getItem('accessGroup');

  if (domain === 'prodigaltech.com') return;

  let ip;
  try {
    ip = localStorage.getItem('ipv4');
  } catch {
    ip = '';
  }

  const properties = {
    version: process.env.APPLICATION_VERSION,
    server: baseURL,
    ip,
    email,
    user_name: prodigal,
    region,
    tenant,
    accessGroup,
    domain: domain?.split('.')?.length > 0 ? domain.split('.')[0] : domain,
    ...object,
  };

  fetch.post('mixpanel/', {
    eventName,
    properties,
  });
}
export { mixpanelBackend };
