import { mixpanelPageMap, mixpanelSubpathMap } from 'utils/mixpanelKeys';

const getPageName = () => {
  const { pathname } = window.location;
  if (
    pathname.includes('smart-reports/') ||
    pathname.includes('inbox/') ||
    pathname.includes('calls')
  ) {
    const segments = pathname.replace(process.env.PUBLIC_URL, '/').split('/');
    if (segments.length > 2) {
      // There is a subpath
      return {
        page: mixpanelPageMap[`/${segments[1]}`],
        [mixpanelSubpathMap[`${segments[1]}`]]: segments[2],
      };
    }
  }
  return { page: pathname };
};

export { getPageName };
