// cspell: ignore NOSCORE
/**
 *
 * Component: OptionsBuilder
 * Date: 11/6/2020
 *
 */

import React, {
  useState,
  useCallback,
  useEffect,
  cloneElement,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import { Icon, Text, Input, Select, Radio } from 'components/common';

import styles from './style.css';

const option = {
  oText: '',
  oType: 'POSITIVE',
  oScore: 0,
};

const OptionsBuilder = ({ initState, showScoreInput, onOptionSetChanged }) => {
  const [optionList, setOptionList] = useState([]);

  const optionCount = useRef(2);
  const optionsRef = useRef([]);

  /**
   * Sets the Options on Options Change Event, ignoring NOSCORE and oScore's
   * */
  const onOptionChanged = useCallback(
    index => (field, value) => {
      // Ignore score value of NOSCORE type option
      if (optionsRef.current[index]?.oType === 'NOSCORE' && field === 'oScore')
        return;
      if (field === 'oType' && value === 'NOSCORE')
        optionsRef.current[index].oScore = 0;
      optionsRef.current[index][field] = value;
      onOptionSetChanged(optionsRef.current);
    },
    [optionList.length],
  );

  /**
   * Removes Options and updates setOptions, after removing options for the given index.
   * */
  const removeOption = useCallback(
    index => () => {
      const newList = [...optionList];
      if (newList.length > 2) {
        newList.splice(index, 1);
        setOptionList(newList);
      }
      optionsRef.current.splice(index, 1);
      onOptionSetChanged(optionsRef.current);
    },
    [optionList.length],
  );

  useEffect(() => {
    setOptionList(list =>
      list.map((eachOption, index) =>
        cloneElement(eachOption, {
          showScoreInput,
          onRemove: removeOption(index),
          onChange: onOptionChanged(index),
        }),
      ),
    );
  }, [removeOption, onOptionChanged, showScoreInput]);

  useEffect(() => {
    if (Array.isArray(initState) && initState.length) {
      optionsRef.current = [];
      const list = initState.map((eachOption, index) => {
        optionsRef.current.push({ ...eachOption });
        return (
          <Option
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            showScoreInput={showScoreInput}
            initData={{ ...eachOption }}
            onChange={onOptionChanged(index)}
            onRemove={removeOption(index)}
            showRemove={!(index < 2)}
          />
        );
      });
      setOptionList(list);
    } else {
      optionsRef.current = ({ ...option }, { ...option });
      const list = [
        <Option
          key={0}
          initData={{ ...option }}
          showRemove={false}
          showScoreInput
        />,
        <Option
          key={1}
          initData={{ ...option }}
          showRemove={false}
          showScoreInput
        />,
      ];
      setOptionList(list);
    }
  }, [initState]);

  /**
   * Update the Options, by adding an option.
   *  */
  const addOption = () => {
    optionsRef.current.push({ ...option });
    setOptionList(list => [
      ...list,
      <Option
        // eslint-disable-next-line no-plusplus
        key={optionCount.current++}
        showScoreInput
        onChange={onOptionChanged(list.length)}
        onRemove={removeOption(list.length)}
      />,
    ]);
  };

  return (
    <>
      <div className={styles.optionList}>{optionList}</div>
      <div>
        <span className={styles.optionInput}>
          <Radio value="" />
          <Text text="Add Option" onClick={addOption} />
        </span>
      </div>
    </>
  );
};

/**
 * A functional Component for each Option.
 *  */
const Option = ({
  initData,
  showScoreInput,
  onChange,
  onRemove,
  showRemove = true,
}) => (
  <div>
    <span className={styles.optionInput}>
      <Radio value="" />
      <Input
        defaultValue={initData?.oText}
        placeholder="Option Text"
        onChange={e => onChange('oText', e.target.value)}
      />
      <Select
        defaultValue={initData?.oType || 'POSITIVE'}
        onSelect={value => onChange('oType', value)}
      >
        <Option key="POSITIVE">Positive</Option>
        <Option key="NEGATIVE">Negative</Option>
        <Option key="NEUTRAL">Neutral</Option>
        <Option key="NOSCORE">No Score</Option>
      </Select>
      {showScoreInput && (
        <Input
          type="number"
          precision={0}
          defaultValue={initData?.oScore || 0}
          onChange={value => onChange('oScore', value)}
        />
      )}
      {showRemove && (
        <Icon
          size={12}
          className="cursor-pointer"
          type="close"
          onClick={onRemove}
        />
      )}
    </span>
  </div>
);

Option.propTypes = {
  initData: PropTypes.object,
  showScoreInput: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  showRemove: PropTypes.bool,
};

OptionsBuilder.propTypes = {
  initState: PropTypes.object,
  showScoreInput: PropTypes.bool,
  onOptionSetChanged: PropTypes.func,
};

export default OptionsBuilder;
