// cspell: ignore DDTHH
/* eslint-disable no-underscore-dangle */
/*
 *
 * AggregatedScorecard reducer
 *
 */
import produce from 'immer';
import moment from 'moment';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { IsJsonString, scorecardList } from 'utils/commonFunctions';
import { notifyUser } from 'utils/notification';
import mixpanel from 'utils/mixpanel';

import * as constant from './constants';
const {
  setLocalStorageValue,
  getLocalStorageValue,
  getTemplateTree,
  getOptionTree,
  updateOptionTree,
  generateKeyValueObjectFromArray,
  getColumn,
  modifyInitialFilter,
  generateBasicColumnState,
  getColumnFromDimension,
  getColumnTreeFromDimension,
  createDimensionTree,
} = scorecardList;
const locallyStoredTemplateId = localStorage.getItem('scorecardTemplateId');
let templateId = [];
if (locallyStoredTemplateId) {
  templateId = IsJsonString(locallyStoredTemplateId)
    ? JSON.parse(locallyStoredTemplateId)
    : [locallyStoredTemplateId] || [];
}

/**
 *
 * @returns non undefined value so that JSON.parse works, as if some user have undefined as value, this function will fail and undefined is not valid JSON
 */
const updateMixpanelForCorruptValue = () => {
  const cachedDimension = localStorage.getItem('dimension');
  if (cachedDimension === 'undefined') mixpanel('Dimension Undefined', {});

  return cachedDimension && cachedDimension !== 'undefined'
    ? cachedDimension
    : JSON.stringify('agentName');
};

// TODO: IsJsonString(locallyStoredTemplateId) === false case is for backward compatibility, after this release we can remove this case in the next one
const scorecardTemplateList = {};
const selectedDimensions = JSON.parse(updateMixpanelForCorruptValue());
// 220 is commutative pixels size other than table rows, 39 is per row height in pixel
// tablePageSize is number of rows we can have with current height of browser
const tablePageSize = Math.floor((window.innerHeight - 220) / 39);
export const initialState = {
  columns: [],
  originalColumns: [],
  dataSource: [],
  scorecardTemplate: typeof templateId === 'string' ? [templateId] : templateId,
  agentNameList: [],
  selectAll: false,
  totalCount: null,
  sortFieldList: [],
  loadingExport: false,
  filters: {},
  loadingList: true,
  limit: tablePageSize,
  scorecardTemplates: [],
  columnOptionTree: [],
  selectedColumnTree:
    typeof templateId === 'string'
      ? JSON.parse(
          getLocalStorageValue(
            `selectedAggregatedColumnTree_${_.sortBy([templateId])}`,
          ) || JSON.stringify([]),
        )
      : JSON.parse(
          getLocalStorageValue(
            `selectedAggregatedColumnTree_${_.sortBy(templateId)}`,
          ) || JSON.stringify([]),
        ),
  // TODO: templateId === 'string' case is for backward compatibility, after this release we can remove this case in the next one
  initialFixedColumn: [],
  initialFixedColumnTree: [],
  dimensionsTreeList: [],
  selectedDimensions,
  searchId: uuidv4(),
  selectedFilters: [
    {
      field: 'callTime',
      condition: 'between',
      value: [
        moment()
          .startOf('month')
          .format('YYYY-MM-DDTHH:mm:ss'),
        moment()
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
      ],
    },
  ],
  appliedFilters: [],
  scorecardList: [],
  selectedScorecard: [],
  skip: 0,
  columnsNameMap: {},
  filterChanged: false,
  selectedRows: [],
  initialColumn: [],
  basicInitialColumn: [],
  pageNo: 1,
  sortList: [],
  forceLoadByReviewScorecard: false,
};

function getPreprocessedTableData(data) {
  if (!_.isEmpty(data)) {
    const tableData = produce(data, scorecards => {
      scorecards.map(scorecard => {
        if (scorecard.sections)
          scorecard.sections.forEach(section => {
            // eslint-disable-next-line no-param-reassign
            scorecard[section.name] = {
              ...section,
              type: 'aggregatedSection',
            };
            section.questions.forEach(question => {
              // eslint-disable-next-line no-param-reassign
              scorecard[question.name] = {
                value: question.score,
                type: 'aggregatedQuestion',
              };
            });
          });
        if (scorecard.grade)
          scorecard.grade.forEach(grade => {
            // eslint-disable-next-line no-param-reassign
            scorecard[`grade${grade.id}`] = {
              ...grade,
              type: 'aggregatedGrade',
              value: [grade.value, scorecard[constant.NON_NA_GRADE_COUNT]],
            };
          });
        return scorecard;
      });
    });
    if (tableData.sections) delete tableData.sections;
    return tableData;
  }
  return [];
}

/* eslint-disable default-case, no-param-reassign */
const aggregatedScorecardReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constant.CANCEL_SEARCH:
        draft.searchId = '';
        draft.loadingList = false;
        draft.error = false;
        break;
      case constant.SET_SEARCH_ID:
        draft.searchId = action.payload;
        break;
      case constant.FETCHED_FILTERS: {
        const filters = modifyInitialFilter(action.payload);
        draft.filters = {
          ...filters,
          advancedFilters: action.payload.advancedFilters
            ? action.payload.advancedFilters
            : [],
        };
        if (action.payload?.displayFields) {
          const newBasicInitialColumn = getColumnFromDimension(
            generateBasicColumnState(
              action.payload?.displayFields,
              scorecardTemplateList,
            ),
            state,
          );
          draft.basicInitialColumn = newBasicInitialColumn;

          const newInitialFixedColumnTree = _.cloneDeep(
            newBasicInitialColumn.map(p =>
              getOptionTree(p.dataIndex, p.title, p),
            ),
          );
          draft.initialFixedColumnTree = getColumnTreeFromDimension(
            newInitialFixedColumnTree,
            state.selectedDimensions,
            'tree',
          );

          const newInitialColumn = newBasicInitialColumn.map(i => i.dataIndex);
          if (getLocalStorageValue('initialAggregatedColumn')) {
            let initialColumn = JSON.parse(
              getLocalStorageValue('initialAggregatedColumn'),
            );
            initialColumn = initialColumn.filter(initialColumnArg =>
              newInitialColumn.includes(initialColumnArg),
            );
            setLocalStorageValue(
              'initialAggregatedColumn',
              JSON.stringify(initialColumn),
            );
            draft.initialFixedColumn = initialColumn;
            const initialColumnState = newBasicInitialColumn.filter(
              newBasicArg => initialColumn.includes(newBasicArg.dataIndex),
            );
            draft.initialColumn = _.cloneDeep(initialColumnState);
          } else {
            draft.initialFixedColumn = newInitialColumn;
            draft.initialColumn = _.cloneDeep(newBasicInitialColumn);
          }
        }
        const sortList = action.payload.sortFields.map(sortField => ({
          ...sortField,
          dataIndex: sortField._id,
          key: sortField._id,
          title: sortField.name,
        }));

        draft.sortList = getColumn(
          sortList,
          sortList,
          undefined,
          undefined,
          scorecardTemplateList,
        );

        if (state.dataSource?.length) {
          draft.columns = getColumn(
            draft.initialColumn,
            draft.basicInitialColumn,
            generateKeyValueObjectFromArray(state.selectedColumnTree),
            state.dataSource,
            scorecardTemplateList,
          );
        }
        break;
      }
      case constant.FETCHING_SCORECARD_SEARCH:
        draft.loadingList = true;
        break;
      case constant.ERROR_FETCHING_SCORECARD_SEARCH:
        draft.loadingList = false;
        draft.totalCount = 0;
        break;
      case constant.FETCHED_SCORECARD_SEARCH: {
        if (action.payload.searchId === state.searchId) {
          if (!_.isEmpty(action.payload.responses)) {
            const dataSource = getPreprocessedTableData(
              action.payload.responses,
            );
            draft.dataSource = _.cloneDeep(dataSource);
            const processedColumn = getColumn(
              state.initialColumn,
              state.basicInitialColumn,
              generateKeyValueObjectFromArray(state.selectedColumnTree),
              dataSource,
              scorecardTemplateList,
            );
            const newColumns = processedColumn;
            draft.columns = newColumns;
            draft.originalColumns = _.cloneDeep(newColumns);
            const columnOptionTree = updateOptionTree(
              action.payload.responses[0],
            );
            draft.columnOptionTree = _.cloneDeep(columnOptionTree);
          } else {
            draft.dataSource = [];
          }
          draft.scorecardList = action.payload.responses;
          draft.totalCount = action.payload.totalCount;
          if (state.selectAll) {
            draft.selectedRows = draft.dataSource;
          }
          draft.loadingList = false;
          notifyUser(
            'Search complete!',
            {
              onclick: e => {
                e.preventDefault();
                window.focus();
              },
              body:
                'Aggregated Scorecards have been prepared. Click here to review.',
            },
            true,
          );
          draft.forceLoadByReviewScorecard = false;
        }
        break;
      }
      case constant.SET_COLUMN_NAME_MAP: {
        const columns = getColumn(
          state.initialColumn,
          state.basicInitialColumn,
          generateKeyValueObjectFromArray(state.selectedColumnTree),
          state.dataSource,
          scorecardTemplateList,
        );
        draft.columns = columns;
        break;
      }
      case constant.HANDLE_CHANGE_COLUMN_TREE: {
        const selectedColumnTree = setLocalStorageValue(
          `selectedAggregatedColumnTree_${_.sortBy(state.scorecardTemplate)}`,
          JSON.stringify(action.payload),
        );
        draft.selectedColumnTree = selectedColumnTree;
        const newColumns = getColumn(
          state.initialColumn,
          state.basicInitialColumn,
          generateKeyValueObjectFromArray(action.payload),
          state.dataSource,
          scorecardTemplateList,
        );
        draft.columns = newColumns;
        break;
      }
      case constant.HANDLE_SELECTED_FILTER_KEY:
        draft.showFilterKey = action.payload;
        break;
      case constant.HANDLE_NEW_FILTERS:
        draft.selectedFilters = action.payload;
        break;
      case constant.HANDLE_CHANGE_SORT_ORDER:
        draft.sortFieldList = [
          {
            field:
              action.payload.field === 'callDirection'
                ? 'isDirOutbound'
                : action.payload.field,
            order: action.payload.order,
          },
        ];
        draft.skip = 0;
        draft.pageNo = 1;
        break;
      case constant.SET_SCORECARDS:
        draft.dataSource = [];
        draft.skip = 0;
        break;
      case constant.SET_SELECT_ALL:
        draft.selectAll = action.payload;
        break;
      case constant.SET_SELECTED_ROWS:
        draft.selectedRows = action.payload;
        break;
      case constant.CLEAR_SEARCH:
        // TODO: not sure why we need to use cloneDeep look again
        draft.selectedFilters = _.cloneDeep(initialState.selectedFilters);
        break;
      case constant.FETCHED_SCORECARD_TEMPLATES:
        draft.scorecardTemplates = action.payload.map(template =>
          getTemplateTree(template),
        );
        action.payload.forEach(
          // eslint-disable-next-line no-return-assign
          template => (scorecardTemplateList[template._id] = template.name),
        );
        if (
          action.payload &&
          action.payload[0] &&
          !state.scorecardTemplate?.length
        ) {
          if (!localStorage.getItem(`scorecardTemplateId`)) {
            localStorage.setItem(
              `scorecardTemplateId`,
              JSON.stringify([action.payload[0]._id]),
            );
            draft.scorecardTemplate = [action.payload[0]._id];
          } else {
            draft.scorecardTemplate = JSON.parse(
              localStorage.getItem(`scorecardTemplateId`),
            );
          }
        }
        break;
      case constant.HANDLE_CHANGE_SCORECARD_TEMPLATE: {
        if (!_.isEmpty(action.payload)) {
          draft.scorecardTemplate = action.payload;
          const selectedColumnTree = getLocalStorageValue(
            `selectedAggregatedColumnTree_${_.sortBy(action.payload)}`,
          );
          if (selectedColumnTree) {
            draft.selectedColumnTree = JSON.parse(selectedColumnTree);
          } else {
            setLocalStorageValue(
              `selectedAggregatedColumnTree_${_.sortBy(action.payload)}`,
              JSON.stringify([]),
            );
            draft.selectedColumnTree = [];
          }
          localStorage.setItem(
            `scorecardTemplateId`,
            JSON.stringify(action.payload),
          );
        } else {
          draft.scorecardTemplate = [state.scorecardTemplates[0]._id];
        }
        draft.forceLoadByReviewScorecard = true;
        break;
      }
      case constant.HANDLE_CHANGE_FIXED_COLUMNS:
        draft.initialFixedColumn = action.payload;
        draft.initialColumn = state.basicInitialColumn.filter(i =>
          action.payload.includes(i.dataIndex),
        );
        localStorage.setItem(
          'initialAggregatedColumn',
          JSON.stringify(action.payload),
        );
        break;
      case constant.EXPORTING_SCORECARD:
        draft.loadingExport = true;
        break;
      case constant.EXPORTED_SCORECARD:
        draft.loadingExport = false;
        break;
      case constant.ERROR_EXPORTING_SCORECARD:
        draft.loadingExport = false;
        break;
      case constant.HANDLE_PAGE_CHANGE: {
        if (action.payload) {
          draft.skip = state.limit * (action.payload - 1);
        } else {
          draft.skip = 0;
        }
        draft.pageNo = action.payload || 1;
        break;
      }
      case constant.UPDATE_DATE_FILTER: {
        if (action.payload.value)
          draft.selectedFilters = draft.selectedFilters.map(filter => {
            if (filter.field === 'callTime') {
              filter.value = action.payload.value;
              filter.condition = action.payload.condition;
              return filter;
            }
            return filter;
          });
        else
          draft.selectedFilters = draft.selectedFilters.filter(
            filter => filter.field !== 'callTime',
          );
        draft.forceLoadByReviewScorecard = true;
        break;
      }
      case constant.UPDATE_AUDIT_DATE_FILTER: {
        const { condition, value } = action.payload;
        if (
          state.selectedFilters.find(filter => filter.field === 'createdAt')
        ) {
          if (action.payload.value)
            draft.selectedFilters = draft.selectedFilters.map(filter => {
              if (filter.field === 'createdAt') {
                filter.condition = condition;
                filter.value = value;
                filter.field = 'createdAt';
                return filter;
              }
              return filter;
            });
          else
            draft.selectedFilters = draft.selectedFilters.filter(
              filter => filter.field !== 'createdAt',
            );
        } else {
          const selectedFilters = _.cloneDeep(state.selectedFilters);
          selectedFilters.push({
            condition,
            value,
            field: 'createdAt',
          });
          draft.selectedFilters = selectedFilters;
        }
        draft.forceLoadByReviewScorecard = true;
        break;
      }
      case constant.UPDATE_TEMPLATE_WITHOUT_STORING_IN_LOCAL_STORAGE: {
        if (!_.isEmpty(action.payload)) {
          draft.scorecardTemplate = action.payload;
          const selectedColumnTree = getLocalStorageValue(
            `selectedAggregatedColumnTree_${_.sortBy(action.payload)}`,
          );
          if (selectedColumnTree) {
            draft.selectedColumnTree = JSON.parse(selectedColumnTree);
          } else {
            setLocalStorageValue(
              `selectedAggregatedColumnTree_${_.sortBy(action.payload)}`,
              JSON.stringify([]),
            );
            draft.selectedColumnTree = [];
          }
        } else {
          draft.scorecardTemplate = [state.scorecardTemplates[0]._id];
        }
        break;
      }
      case constant.CLEAR_NON_PRIMARY_FILTERS:
        draft.selectedFilters = state.selectedFilters.filter(
          selectedFilter =>
            selectedFilter.field === 'callTime' ||
            selectedFilter.field === 'createdAt',
        );
        break;
      case constant.HANDLE_AGGREGATION_DIMENSIONS_CHANGE: {
        const [dimension] = action.payload;
        draft.selectedDimensions = dimension;
        const newBasicInitialColumn = getColumnFromDimension(
          generateBasicColumnState(
            state.filters?.displayFields || [],
            scorecardTemplateList,
          ),
          { ...state, selectedDimensions: dimension },
        );
        draft.basicInitialColumn = newBasicInitialColumn;
        const newInitialFixedColumnTree = _.cloneDeep(
          getColumnTreeFromDimension(
            newBasicInitialColumn.map(p =>
              getOptionTree(p.dataIndex, p.title, p),
            ),
            dimension,
            'tree',
          ),
        );
        const newInitialColumn = newBasicInitialColumn.map(i => i.dataIndex);
        if (getLocalStorageValue('initialAggregatedColumn')) {
          let initialColumn = JSON.parse(
            getLocalStorageValue('initialAggregatedColumn'),
          );
          initialColumn = initialColumn.filter(initialColumnArg =>
            newInitialColumn.includes(initialColumnArg),
          );
          setLocalStorageValue(
            'initialAggregatedColumn',
            JSON.stringify(initialColumn),
          );
          draft.initialFixedColumn = initialColumn;
          const initialColumnState = newBasicInitialColumn.filter(newBasicArg =>
            initialColumn.includes(newBasicArg.dataIndex),
          );
          draft.initialColumn = _.cloneDeep(initialColumnState);
        } else {
          draft.initialFixedColumn = newInitialColumn;
          draft.initialColumn = _.cloneDeep(newBasicInitialColumn);
        }
        const processedColumn = getColumn(
          draft.initialColumn,
          draft.basicInitialColumn,
          generateKeyValueObjectFromArray(state.selectedColumnTree),
          state.dataSource,
          scorecardTemplateList,
        );
        const newColumns = processedColumn;
        draft.columns = newColumns;
        draft.originalColumns = _.cloneDeep(newColumns);
        draft.initialFixedColumnTree = newInitialFixedColumnTree;
        draft.sortFieldList = [];
        localStorage.setItem(
          'dimension',
          JSON.stringify(dimension || 'agentName'),
        );
        break;
      }
      case constant.UPDATE_DIMENSIONS_TREE_LIST:
        draft.dimensionsTreeList = createDimensionTree(action.payload);
        break;
    }
  });

export default aggregatedScorecardReducer;
